import React from 'react';
import PropTypes from 'prop-types';

const FactBlock = ({ title, text }) => (
  <div className="fact-block">
    <div className="col">
      <h4 data-epi-property-name="Heading">{title}</h4>
      <div
        className="editorial-text highlight-text"
        dangerouslySetInnerHTML={{ __html: text }}
        data-epi-property-name="Text"
      />
    </div>
  </div>
);

FactBlock.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired
};

FactBlock.propTypesMeta = 'exclude';

export default FactBlock;
