import PropTypes from 'prop-types';

export const Image = {
  propTypes: {
    url: PropTypes.string,
    altText: PropTypes.string
  }
};

export const Lang = {
  propTypes: {
    track: PropTypes.string.isRequired,
    collapse: PropTypes.string.isRequired,
    expand: PropTypes.string.isRequired,
    swap: PropTypes.string.isRequired,
    calendar: PropTypes.string.isRequired,
    today: PropTypes.string.isRequired,
    tomorrow: PropTypes.string.isRequired,
    monday: PropTypes.string.isRequired,
    tuesday: PropTypes.string.isRequired,
    wednesday: PropTypes.string.isRequired,
    thursday: PropTypes.string.isRequired,
    friday: PropTypes.string.isRequired,
    saturday: PropTypes.string.isRequired,
    sunday: PropTypes.string.isRequired,
    january: PropTypes.string.isRequired,
    february: PropTypes.string.isRequired,
    march: PropTypes.string.isRequired,
    april: PropTypes.string.isRequired,
    may: PropTypes.string.isRequired,
    june: PropTypes.string.isRequired,
    july: PropTypes.string.isRequired,
    august: PropTypes.string.isRequired,
    september: PropTypes.string.isRequired,
    october: PropTypes.string.isRequired,
    november: PropTypes.string.isRequired,
    december: PropTypes.string.isRequired,
    prevMonth: PropTypes.string.isRequired,
    nextMonth: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
    departure: PropTypes.string.isRequired,
    back: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
    minutes: PropTypes.string.isRequired,
    retry: PropTypes.string.isRequired,
    noMoreRoutes: PropTypes.string.isRequired,
    addToCalendar: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    cancelled: PropTypes.string.isRequired,
    walk: PropTypes.string.isRequired,
    minutesShort: PropTypes.string.isRequired,
    showInMap: PropTypes.string.isRequired,
    showStops: PropTypes.string.isRequired,
    hideStops: PropTypes.string.isRequired,
    changeTransport: PropTypes.string.isRequired,
    buyTicket: PropTypes.string.isRequired
  }
};
