export const doNotTrack = () =>
  (window.external &&
    window.external.msTrackingProtectionEnabled &&
    window.external.msTrackingProtectionEnabled()) ||
  (window.doNotTrack && window.doNotTrack == '1') ||
  (navigator.doNotTrack &&
    (navigator.doNotTrack == 'yes' || navigator.doNotTrack == '1')) ||
  (navigator.msDoNotTrack && navigator.msDoNotTrack == '1');

export const trackStateWithGoogle = (action, label, category, event) => {
  if (doNotTrack()) return;
  var data = {
    event_category: category || '',
    event_action: action || '',
    event_label: label || '',
    event: event || 'interaction'
  };
  // eslint-disable-next-line no-undef
  typeof dataLayer !== 'undefined' && dataLayer.push(data);
};
