/* eslint-disable @creuna/prop-types-csharp/all */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const RadioToggle = ({ items, active, name, onChange }) => (
  <div className="container-white radio-toggle">
    {items.map((item, index) => {
      return (
        <label key={index} className={cn({ active: active == item.value })}>
          <input
            type="radio"
            name={name}
            value={item.value}
            checked={active == item.value}
            onChange={onChange}
          />
          {item.name}
        </label>
      );
    })}
  </div>
);

const numberOrString = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

RadioToggle.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      value: numberOrString
    })
  ),
  active: numberOrString,
  name: PropTypes.string,
  onChange: PropTypes.func
};

RadioToggle.propTypesMeta = 'exclude';

export default RadioToggle;
