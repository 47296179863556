import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Link = ({ url, text, openInNewTab, className }) => {
  return (
    <a
      href={url}
      target={openInNewTab ? '_blank' : null}
      className={cn('link', className)}
    >
      {text}
    </a>
  );
};

Link.propTypes = {
  className: PropTypes.string,
  url: PropTypes.string,
  text: PropTypes.string,
  openInNewTab: PropTypes.bool
};

Link.propTypesMeta = {
  className: 'exclude'
};

export default Link;
