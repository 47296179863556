import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import useEvent from '../../hooks/use-event';
import Link from 'components/link/link';

import { getPageHash, getPageUrlWithoutQueryAndHash } from 'utils/url-utils.js';

const Breadcrumbs = ({
  lang = {
    dapatureDetails: 'Valgt avgang',
    breadcrumbsLabel: 'Du er her'
  },
  links,
  className,
  inContentContainer = false
}) => {
  const [breadcrumbsLinks, setBreadcrumbsLinks] = React.useState(links);

  const renderBreadcrumbs = breadcrumbsLinks && breadcrumbsLinks.length > 1;

  const getHashedBradcrumsMap = () => {
    var pageUrl = getPageUrlWithoutQueryAndHash();
    var hashedBreadcrumbsMap = new Map();
    hashedBreadcrumbsMap
      .set('list', [
        {
          text: lang.departures,
          url: pageUrl + '#list'
        }
      ])
      .set('details', [
        hashedBreadcrumbsMap.get('list')[0],
        { text: lang.dapatureDetails }
      ]);
    return hashedBreadcrumbsMap;
  };

  const updateBreadcrumbs = () => {
    var pageHash = getPageHash();

    if (pageHash) {
      const hashedBreadcrumsMap = getHashedBradcrumsMap();

      if (hashedBreadcrumsMap.has(pageHash)) {
        var hashedLinks = hashedBreadcrumsMap.get(pageHash);
        let breadcrumbsList = [...links, ...hashedLinks];
        setBreadcrumbsLinks(breadcrumbsList);
      } else {
        setBreadcrumbsLinks(links);
      }
    } else {
      setBreadcrumbsLinks(links);
    }
  };

  useEvent('hashchange', () => {
    updateBreadcrumbs();
  });

  React.useEffect(() => {
    updateBreadcrumbs();
  }, []);

  return renderBreadcrumbs ? (
    <div className="row">
      <div className="col-xs-12">
        <nav
          className={cn('breadcrumbs', className, {
            'breadcrumbs_in-content-container': inContentContainer
          })}
          role="navigation"
        >
          {lang.breadcrumbsLabel && (
            <h2 id="breadcrumbslabel" className="breadcrumbs__heading label">
              {lang.breadcrumbsLabel}:{' '}
            </h2>
          )}
          <ul className="breadcrumbs__items" aria-labelledby="breadcrumbslabel">
            {breadcrumbsLinks.map((breadcrumb, i) => (
              <li key={i} className="breadcrumbs__item">
                {breadcrumbsLinks.length === i + 1 ? (
                  <span className="breadcrumbs__current-item-text">
                    {breadcrumb.text}
                  </span>
                ) : (
                  <Link {...breadcrumb} className="breadcrumbs__link" />
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </div>
  ) : null;
};

Breadcrumbs.propTypes = {
  lang: PropTypes.shape({
    departures: PropTypes.string.isRequired,
    dapatureDetails: PropTypes.string.isRequired,
    breadcrumbsLabel: PropTypes.string
  }),
  links: PropTypes.arrayOf(PropTypes.exact(Link.propTypes)),
  className: PropTypes.string,
  inContentContainer: PropTypes.bool
};

Breadcrumbs.propTypesMeta = {
  className: 'exclude'
};

export default Breadcrumbs;
