import moment from 'moment';
import 'moment/locale/nb';

const getDateWithSuffix = date => {
  let j = date % 10,
    k = date % 100;
  if (j == 1 && k != 11) {
    return date + 'st';
  }
  if (j == 2 && k != 12) {
    return date + 'nd';
  }
  if (j == 3 && k != 13) {
    return date + 'rd';
  }
  return date + 'th';
};

export const computeDisplayDate = (date, lang, locale) => {
  const dateFormat = 'YYYY-MM-DD';
  let weekday = [
    lang.sunday,
    lang.monday,
    lang.tuesday,
    lang.wednesday,
    lang.thursday,
    lang.friday,
    lang.saturday
  ];
  let months = [
    lang.january,
    lang.february,
    lang.march,
    lang.april,
    lang.may,
    lang.june,
    lang.july,
    lang.august,
    lang.september,
    lang.october,
    lang.november,
    lang.december
  ];
  let displayDate = [];

  let isToday = day => day.format(dateFormat) === moment().format(dateFormat);
  let isTomorrow = day =>
    day.format(dateFormat) ===
    moment()
      .add(1, 'day')
      .format(dateFormat);
  let getWeekday = day => weekday[day.day()];

  if (isToday(date)) {
    displayDate.push(lang.today);
  } else if (isTomorrow(date)) {
    displayDate.push(lang.tomorrow);
  } else {
    displayDate.push(getWeekday(date));
  }

  if (locale === 'en-us' || locale === 'en') {
    displayDate.push(months[date.month()], getDateWithSuffix(date.date()));
  } else {
    displayDate.push(date.date() + '.', months[date.month()]);
  }

  return displayDate.join(' ');
};
