/* eslint-disable @creuna/prop-types-csharp/all */
import React from 'react';
import PropTypes from 'prop-types';

const FooterLinkList = ({ title, url, links }) => (
  <ul className="footer-link-list">
    <li>
      {url.length > 0 ? (
        <a href={url}>
          <h4>{title}</h4>
        </a>
      ) : (
        <h4>{title}</h4>
      )}
    </li>
    {links.map((obj, i) => {
      return (
        <li key={i.toString()}>
          <a href={obj.url}>{obj.title}</a>
        </li>
      );
    })}
  </ul>
);

FooterLinkList.defaultProps = {
  title: 'Hei, kan vi hjelpe deg?',
  url: '',
  links: []
};

FooterLinkList.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  links: PropTypes.array.isRequired
};

FooterLinkList.propTypesMeta = 'exclude';

export default FooterLinkList;
