import isRunningOnClient from '@creuna/utils/is-running-on-client';

if (isRunningOnClient) {
  const WOW = require('wowjs');

  new WOW.WOW({
    live: false
  }).init({
    mobile: false
  });
}
