/* eslint-disable @creuna/prop-types-csharp/all */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import moment from 'moment';
import timeHelper from 'components/travel-planner/timeHelper';

import IconCal from 'assets/icons/FT__kal_small.svg';
import IconPlane from 'assets/icons/FT_airplane.svg';
import IconWalk from 'assets/icons/FT_walk.svg';
import Expand from 'assets/icons/FT_arrow_small_up.svg';

// constants
import {
  Direction,
  fullDate,
  shortDateFormat,
  shortDateFormatEn
} from 'components/travel-planner/constants';

class TravelResultDetails extends Component {
  static propTypes = {
    dateTime: PropTypes.string,
    locale: PropTypes.string,
    lang: PropTypes.object,
    legs: PropTypes.array,
    stops: PropTypes.array,
    alternative: PropTypes.bool.isRequired,
    valid: PropTypes.bool.isRequired,
    description: PropTypes.string.isRequired,
    direction: PropTypes.number.isRequired
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    alternative: false,
    description: 'Innstilt avvik'
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {}

  onClick = () => {
    return;
  };

  onKeyDown = e => {
    var code = e.which;
    // 13 = Return, 32 = Space
    if (code === 13 || code === 32) {
      e.preventDefault();
      this.onClick();
    }
  };

  toggleStops = e => {
    let btn = e.target;
    if (!btn) {
      return;
    }
    if (!btn.classList.contains('toggle-list')) {
      btn = btn.parentNode;
    }
    const list = btn.parentNode.parentNode.nextSibling;
    if (!list) {
      return;
    }
    if (btn.classList.contains('visible')) {
      btn.classList.remove('visible');
      list.classList.remove('visible');
    } else {
      btn.classList.add('visible');
      list.classList.add('visible');
    }
  };

  getTimeType = isOSL => {
    const { direction } = this.props;
    if (isOSL) return direction === Direction.TO ? 'arrTime' : 'depTime';

    return direction === Direction.TO ? 'depTime' : 'arrTime';
  };

  // no deviations
  parseStops = inPast => {
    const { stops, legs, direction, lang } = this.props;
    if (!stops) {
      return [];
    }
    return stops.map((stop, i) => {
      let timeType = this.getTimeType(
        direction == Direction.TO ? i == stops.length - 1 : i == 0
      );

      let track;
      let time = stop[timeType] ? moment(stop[timeType], 'HH:mm:ss') : null;

      if (i === 0) {
        track = legs[0].origin.rtTrack;
      } else if (i === stops.length - 1) {
        track = legs[0].destination.rtTrack;
      }

      let svg = '';
      // inject plane icon
      if (i === (direction === Direction.TO ? stops.length - 1 : 0)) {
        svg = <IconPlane />;
      }

      let prevStopCancelled = i > 0 ? stops[i - 1].cancelled : false;
      let nextStopCancelled =
        i <= stops.length - 2 ? stops[i + 1].cancelled : false;
      let classNames = {
        first: i === 0,
        last: i === stops.length - 1,
        'to-last': i === stops.length - 2,
        cancelled: !!stop.cancelled,
        'to-cancelled': nextStopCancelled,
        'from-cancelled': prevStopCancelled,
        'in-past': inPast
      };
      return (
        <div key={i} className={cn('details-stop', classNames)}>
          <div className="details-time">{time ? time.format('HH:mm') : ''}</div>
          <div className="details-gfx">
            {svg}
            <span />
          </div>
          <div className="details-name">
            {stop.name}
            {typeof track !== 'undefined' && track > 0 && (
              <span>
                {lang.track} {track}
              </span>
            )}
          </div>
        </div>
      );
    });
  };

  // deviations - different dataset from api
  parseLegs = () => {
    const { lang, direction, legs } = this.props;

    const getCategory = leg =>
      leg ? (leg.type === 'WALK' ? 'WALK' : leg.category) : null;
    let result = [];

    const show = type => {
      switch (type) {
        case 'TOG': // flytog
        case 'WALK': // gange
        case 'Lt': // lokal tog
        case 'IC': // intercity tog
        case 'BUS': // buss
        case 'TAX': // taxi
        case 'U': // t-bane
          return false;
      }
      return true;
    };

    const templateStop = ({
      time,
      name,
      key,
      track,
      svg = null,
      cancelled = false,
      first = false,
      last = false
    }) => {
      let classNames = {
        cancelled: cancelled,
        first: first,
        last: last
      };
      return (
        <div key={key} className={cn('details-stop', classNames)}>
          <div className="details-time">{time ? time.format('HH:mm') : ''}</div>
          <div className="details-gfx">
            {svg}
            <span />
          </div>
          <div className="details-name">
            {name}
            {typeof track !== 'undefined' && track > 0 && (
              <span>
                {lang.track} {track}
              </span>
            )}
          </div>
        </div>
      );
    };

    let startIndex = 1;
    for (let i = startIndex; i < legs.length; i++) {
      let _svgStart = '';
      let _svgEnd = '';
      let prevLeg = legs[i - 1];
      let leg = legs[i];
      let nextLeg = legs[i + 1];

      // inject plane icon
      if (direction === Direction.TO && i === legs.length - 1) {
        _svgEnd = <IconPlane />;
      } else if (direction === Direction.FROM && i === startIndex) {
        _svgStart = <IconPlane />;
      }

      let type = getCategory(leg);
      let prevType = getCategory(prevLeg);
      let nextType = getCategory(nextLeg);

      // can switch from TOG to Lt or IC and all other variations, but not TOG to BUS etc.
      // on the other hand, BUS to BUS needs to be validated properly, as do TAXI to TAXI and U to U
      let types = [null, 'TOG', 'Lt', 'IC'];

      let switchTransport = false;
      if (!!leg && !!nextLeg) {
        const sameStation = leg.destination.name === nextLeg.origin.name;
        const differentTransport = leg.name !== nextLeg.name;
        const validTransports =
          (types.indexOf(type) > -1 && types.indexOf(nextType) > -1) ||
          type === nextType;
        switchTransport = validTransports && sameStation && differentTransport;
      }

      let originTime = timeHelper.parseMoment(leg.origin.date, leg.origin.time);

      let destinationTime = timeHelper.parseMoment(
        leg.destination.date,
        leg.destination.time
      );

      const template = (content, _showOrigin, _showDestination) => {
        const first = i === startIndex,
          last = i === legs.length - 1;
        const showOrigin =
            typeof _showOrigin === 'undefined' ? show(prevType) : _showOrigin,
          showDestination =
            switchTransport ||
            (typeof _showOrigin === 'undefined'
              ? show(nextType)
              : _showDestination);

        return (
          <div
            key={i}
            className={cn('details-deviation', {
              first: first,
              last: last,
              'deviation-other': type !== 'WALK' && type !== null,
              'deviation-walk': type === 'WALK',
              'to-other':
                !switchTransport && nextType !== 'WALK' && nextType !== null,
              'to-walk': !switchTransport && nextType === 'WALK',
              'to-last': !switchTransport && i === legs.length - 2,
              'to-switch': switchTransport
            })}
          >
            {showOrigin
              ? templateStop({
                  time: originTime,
                  name: leg.origin.name,
                  track: leg.origin.rtTrack,
                  first: first,
                  svg: _svgStart
                })
              : null}
            {/* output content, or run if it's a function */}
            {typeof content === 'function'
              ? content(showOrigin, showDestination)
              : content}
            {showDestination
              ? templateStop({
                  time: destinationTime,
                  name: leg.destination.name,
                  track: leg.destination.rtTrack,
                  last: last,
                  svg: _svgEnd
                })
              : null}
          </div>
        );
      };
      let section;
      const description = leg.text;
      const switchDescription = leg.switchText || lang.changeTransport;
      const mapUrl =
        'https://www.google.com/maps/?q=' +
        leg.destination.lat +
        ',' +
        leg.destination.lon;
      const stops = leg.stops ? leg.stops : [];

      switch (type) {
        case 'WALK': // gange
          section = template((showOrigin, showDestination) => {
            return (
              <div
                className={cn('details-walk', {
                  'from-normal': showOrigin,
                  'to-normal': showDestination
                })}
              >
                <div className="details-gfx">
                  <span />
                  <IconWalk />
                </div>
                <div className="details-content">
                  <div>{description}</div>
                  <div>
                    <a href={mapUrl} target="_blank" rel="noopener noreferrer">
                      {lang.showInMap}
                      <Expand />
                    </a>
                  </div>
                </div>
              </div>
            );
          });
          break;
        case 'TOG': // flytog (tog for tog)
        case 'Lt': // lokal tog
        case 'IC': // intercity tog
        case 'BUS': // buss
        case 'TAX': // taxi
        case 'U': // t-bane
          section = template(
            <div className="details-other-container">
              <div className="details-other">
                <div className="details-gfx" />
                <div className="details-content">
                  <div>{description}</div>
                  {stops.length > 2 ? (
                    <button className="toggle-list" onClick={this.toggleStops}>
                      <span className="hidden">{lang.showStops}</span>
                      <span className="visible">{lang.hideStops}</span>{' '}
                      <Expand />
                    </button>
                  ) : null}
                </div>
              </div>
              {stops.length > 2 ? (
                <div className="details-stop-list">
                  {stops
                    .filter(
                      (item, index) => index > 0 && index < stops.length - 1
                    )
                    .map((item, index) => {
                      const timeType = this.getTimeType(
                        direction == Direction.TO
                          ? index == stops.length - 1
                          : index == 0
                      );
                      return templateStop({
                        time: item[timeType]
                          ? moment(item[timeType], 'HH:mm:ss')
                          : null,
                        name: item.name,
                        key: i + '_' + index
                      });
                    })}
                </div>
              ) : null}
            </div>,
            true,
            true
          );
          break;
        default:
          section = template(
            stops.length > 2 ? (
              <div className="details-stop-list">
                {stops
                  .filter(
                    (item, index) => index > 0 && index < stops.length - 1
                  )
                  .map((item, index) => {
                    const timeType = this.getTimeType(
                      direction == Direction.TO
                        ? index == stops.length - 1
                        : index == 0
                    );
                    return templateStop({
                      time: item[timeType]
                        ? moment(item[timeType], 'HH:mm:ss')
                        : null,
                      name: item.name,
                      key: i + '_' + index
                    });
                  })}
              </div>
            ) : null
          );
          break;
      }

      result.push(section);

      if (switchTransport) {
        result.push(
          <div
            key={'switch_' + i}
            className={cn('details-deviation', 'deviation-switch', {
              'to-other': nextType !== 'WALK' && nextType !== null,
              'to-walk': nextType === 'WALK',
              'to-last': i === legs.length - 2
            })}
          >
            <div className={cn('details-walk')}>
              <div className="details-gfx">
                <span />
                <IconWalk />
              </div>
              <div className="details-content">
                <div>{switchDescription}</div>
              </div>
            </div>
          </div>
        );
      }
    }
    return result;
  };

  render() {
    const {
      dateTime,
      locale,
      valid,
      alternative,
      direction,
      description
    } = this.props;
    // TODO: think about implementaation of DateFormatsProvider like dateFormats.forLocale() {date, shortDate, time... }
    const dateFormat = locale.startsWith('en')
      ? shortDateFormatEn
      : shortDateFormat;
    const date = moment(dateTime, fullDate).format(dateFormat);

    const time = moment(dateTime, fullDate).format(fullDate);
    const currentTime = moment().format(fullDate);

    let result = <div />;
    if (!alternative) {
      result = this.parseStops(time < currentTime);
    } else if (alternative) {
      result = this.parseLegs();
    }
    return (
      <div className={cn('travel-results-details-outer')}>
        <div className={cn('date-label-container')}>
          <div className="date-label">
            <IconCal focusable="false" role="img" /> {date}
          </div>
        </div>
        {description ? (
          <div className={cn('details-header', { invalid: !valid })}>
            {description}
          </div>
        ) : null}
        <div
          className={cn('travel-results-details-container', {
            'to-airport': direction === Direction.TO
          })}
        >
          {result}
        </div>
      </div>
    );
  }
}
export default TravelResultDetails;
