import React from 'react';
import PropTypes from 'prop-types';

import { IMAGE_SIZE, IMAGE_QUERY, IMAGE_WIDTH } from 'constants/image-type';
import DownloadIcon from 'assets/icons/FT_download.svg';

const ImageListBlock = ({
  blocks,
  title,
  downloadImageText,
  photographerLabel
}) => {
  let imageListBlockEls = [];
  if (blocks && blocks.length > 0) {
    imageListBlockEls = blocks.map((imageListBlock, i) => {
      let animateDelay = i * 0.1;

      let fluidImageStyle = {
        backgroundPosition: `${imageListBlock.imageX}% ${imageListBlock.imageY}%`,
        backgroundImage: `url(${imageListBlock.imageUrl}?${
          IMAGE_QUERY[IMAGE_SIZE.SMALL]
        }&${IMAGE_QUERY.transform})`
      };

      const imageSrc = `${imageListBlock.imageUrl}?${
        IMAGE_QUERY[IMAGE_SIZE.SMALL]
      }&${IMAGE_QUERY.transform}`;

      let imageWidth = '';
      if (typeof window !== 'undefined') {
        imageWidth =
          IMAGE_WIDTH[IMAGE_SIZE.SMALL] / (window.devicePixelRatio || 1);
      }

      return (
        <div
          key={i}
          className="image-item-container col-xs-12 col-sm-4 col-md-4 col-lg-3"
        >
          <div className="wow fadeInDown" data-wow-delay={`${animateDelay}s`}>
            <div className="image-container">
              <div className="image-wrapper">
                <div style={fluidImageStyle} className="fluid-image">
                  <img
                    src={imageSrc}
                    alt={imageListBlock.imageAlt}
                    data-image-width={imageWidth}
                    data-small={imageListBlock.imageUrl}
                  />
                </div>
              </div>
              <a href={imageListBlock.imageUrl}>
                <div className="image-download-container">
                  <span className="image-download-text">
                    {downloadImageText}
                  </span>
                  <DownloadIcon className="icon-download" />
                </div>
              </a>
            </div>
            <h4 className="image-item-title">{imageListBlock.imageTitle}</h4>
            {imageListBlock.imagePhotographer && (
              <p>
                {photographerLabel}: {imageListBlock.imagePhotographer}
              </p>
            )}
            <a
              href={imageListBlock.imageUrl}
              className="cta image-mobile-download-link"
            >
              <span className="image-download-text">{downloadImageText}</span>
            </a>
          </div>
        </div>
      );
    });
  }

  return (
    <div className="image-list-block-container">
      <div className="row">
        <div className="col-xs-12">
          <h2 className="image-list-block-title">{title}</h2>
        </div>
      </div>
      <div className="row">{imageListBlockEls}</div>
    </div>
  );
};

ImageListBlock.defaultProps = {
  downloadImageText: 'Last ned bildet'
};

ImageListBlock.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.exact({
      imageAlt: PropTypes.string,
      imagePhotographer: PropTypes.string,
      imageTitle: PropTypes.string,
      imageUrl: PropTypes.string,
      imageX: PropTypes.number,
      imageY: PropTypes.number
    })
  ),
  title: PropTypes.string,
  downloadImageText: PropTypes.string,
  photographerLabel: PropTypes.string
};

ImageListBlock.propTypesMeta = 'exclude';

export default ImageListBlock;
