import { windowIsDefined } from 'components/travel-planner/constants';
import pageQueryHelper from './page-query-helper';

function getData({ defaultLocation, defaultDirection }) {
  var storedLocation = undefined;
  var storedDirection = undefined;

  if (windowIsDefined) {
    storedLocation =
      pageQueryHelper.getParameter('location') ||
      localStorage.getItem('location');

    var queryDirection = pageQueryHelper.getParameter('direction');
    var sessionDirection = sessionStorage.getItem('direction');

    if (
      queryDirection == 'from' ||
      (!queryDirection && sessionDirection === 'toAirport')
    ) {
      storedDirection = true;
    }

    if (
      queryDirection == 'to' ||
      (!queryDirection && sessionDirection === 'fromAirport')
    ) {
      storedDirection = false;
    }
  }

  return {
    location: storedLocation || defaultLocation,
    direction:
      storedDirection !== undefined ? storedDirection : defaultDirection
  };
}

function setData({ location, direction, skipQueryUpdate = false }) {
  if (windowIsDefined) {
    var sessionDirection = direction ? 'toAirport' : 'fromAirport';
    var queryDirection = direction ? 'from' : 'to';

    if (location) {
      localStorage.setItem('location', location);
    } else {
      localStorage.removeItem('location');
    }

    if (sessionDirection) {
      sessionStorage.setItem('direction', sessionDirection);
    } else {
      sessionStorage.removeItem('direction');
    }

    if (!skipQueryUpdate) {
      pageQueryHelper.setParameters({
        location: location,
        direction: queryDirection
      });
    }
  }
}

export default { getData, setData };
