/* eslint-disable react/prop-types */
'use strict';

import React from 'react';

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _react = require('react');

var _reactDom = require('react-dom');

var _reactDom2 = _interopRequireDefault(_reactDom);

var _tether = require('tether');

var _tether2 = _interopRequireDefault(_tether);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

function childrenPropType(_ref, componentName) {
  var children = _ref.children;

  var childCount = _react.Children.count(children);
  if (childCount <= 0) {
    return new Error(
      componentName +
        ' expects at least one child to use as the target element.'
    );
  } else if (childCount > 2) {
    return new Error(
      'Only a max of two children allowed in ' + componentName + '.'
    );
  }
}

class TetherComponent extends React.Component {
  static propTypes = {
    children: childrenPropType
  };

  static defaultProps = {
    renderElementTag: 'div',
    renderElementTo: null
  };

  componentDidMount() {
    this._targetNode = _reactDom2.default.findDOMNode(this);
    this._update();
  }

  componentDidUpdate() {
    this._update();
  }

  componentWillUnmount() {
    this._destroy();
  }

  disable() {
    this._tether.disable();
  }

  enable() {
    this._tether.enable();
  }

  position() {
    this._tether.position();
  }

  _destroy() {
    if (this._elementParentNode) {
      _reactDom2.default.unmountComponentAtNode(this._elementParentNode);
      this._elementParentNode.parentNode.removeChild(this._elementParentNode);
    }

    if (this._tether) {
      this._tether.destroy();
    }

    this._elementParentNode = null;
    this._tether = null;
  }

  _update() {
    var _this = this;

    var _props = this.props,
      children = _props.children,
      renderElementTag = _props.renderElementTag,
      renderElementTo = _props.renderElementTo;

    var elementComponent = children[1];

    // if no element component provided, bail out
    if (!elementComponent) {
      // destroy Tether elements if they have been created
      if (this._tether) {
        this._destroy();
      }
      return;
    }

    // create element node container if it hasn't been yet
    if (!this._elementParentNode) {
      // create a node that we can stick our content Component in
      this._elementParentNode = document.createElement(renderElementTag);

      // append node to the end of the body
      var renderTo = renderElementTo || document.body;
      renderTo.appendChild(this._elementParentNode);
    }

    // render element component into the DOM
    _reactDom2.default.unstable_renderSubtreeIntoContainer(
      this,
      elementComponent,
      this._elementParentNode,
      function() {
        // don't update Tether until the subtree has finished rendering
        _this._updateTether();
      }
    );
  }

  _updateTether() {
    var _props2 = this.props,
      options = _objectWithoutProperties(_props2, [
        'renderElementTag',
        'renderElementTo'
      ]); // eslint-disable-line no-unused-vars

    var tetherOptions = _extends(
      {
        target: this._targetNode,
        element: this._elementParentNode
      },
      options
    );

    if (!this._tether) {
      this._tether = new _tether2.default(tetherOptions);
    } else {
      this._tether.setOptions(tetherOptions);
    }

    this._tether.position();
  }

  render() {
    var children = this.props.children;

    var firstChild = null;

    // we use forEach because the second child could be null
    // causing children to not be an array
    _react.Children.forEach(children, function(child, index) {
      if (index === 0) {
        firstChild = child;
        return false;
      }
    });

    return firstChild;
  }
}

export default TetherComponent;
