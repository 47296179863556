import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ReactPaginate from 'react-paginate';

import ListBlock from 'components/list-block/list-block';
import { BLOCK_TYPE } from 'constants/block-type';
import { IMAGE_SIZE } from 'constants/image-type';
import imageLoader from 'utils/image-loader';

import IconArrow from 'assets/icons/FT_arrow_pag_left.svg';

const DEFAULT_PAGE_SIZE = 10;

class ListBlockContainer extends Component {
  static propTypes = {
    listBlocks: PropTypes.arrayOf(PropTypes.exact(ListBlock.propTypes)),
    pageSize: PropTypes.number,
    previousAriaLabel: PropTypes.string,
    nextAriaLabel: PropTypes.string
  };

  static defaultProps = {
    pageSize: DEFAULT_PAGE_SIZE
  };

  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
      imageSize: IMAGE_SIZE.SMALL
    };
    this.handlePaginationChange = this.handlePaginationChange.bind(this);
  }

  handlePaginationChange(data) {
    let selected = data.selected;

    this.setState({
      currentPage: selected
    });

    window.scrollTo(
      0,
      this.containerEl.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top
    );
    window.setTimeout(() => {
      imageLoader.load(this.containerEl);
    }, 100);
  }

  render() {
    const { listBlocks, previousAriaLabel, nextAriaLabel } = this.props;
    const { currentPage, imageSize } = this.state;
    let { pageSize } = this.props;
    if (pageSize === null || pageSize <= 0) {
      pageSize = DEFAULT_PAGE_SIZE;
    }
    const pages = Math.ceil(listBlocks.length / pageSize);
    let listBlockEls = [];

    if (listBlocks && listBlocks.length > 0) {
      let tmpListBlocks = listBlocks.slice(
        currentPage * pageSize,
        (currentPage + 1) * pageSize
      );
      listBlockEls = tmpListBlocks.map((listBlock, i) => (
        <div key={i} className="">
          <ListBlock
            type={BLOCK_TYPE.LIGHT_GREY}
            blockType="block-list"
            position={1}
            setImage={true}
            useImageSize={imageSize}
            {...listBlock}
          />
        </div>
      ));
    }

    return (
      <div
        className="list-block-container"
        ref={containerEl => {
          this.containerEl = containerEl;
        }}
      >
        <div className="list-blocks">{listBlockEls}</div>

        {pages > 1 && (
          <div className="pagination type-grey">
            <div className="row">
              <div className="col-xs-12">
                <ReactPaginate
                  previousLabel={
                    <IconArrow
                      aria-label={previousAriaLabel}
                      className={`active-image has-next has-previous `}
                    />
                  }
                  nextLabel={
                    <IconArrow
                      aria-label={nextAriaLabel}
                      className={`active-image has-next has-previous rotated-image`}
                    />
                  }
                  breakLabel={<span>...</span>}
                  breakClassName={'break-me'}
                  pageCount={pages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={1}
                  pageLinkClassName={'page-number'}
                  onPageChange={this.handlePaginationChange}
                  containerClassName={'pagination-container'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

ListBlockContainer.propTypesMeta = 'exclude';

export default ListBlockContainer;
