export const IMAGE_SIZE = {
  SMALL: 'small',
  LARGE: 'large'
};

export const IMAGE_QUERY = {
  small: 'width=512',
  large: 'width=1920',
  transform: 'transform=DownFit'
};

export const IMAGE_WIDTH = {
  small: '512',
  large: '1920'
};
