/* eslint-disable @creuna/prop-types-csharp/all */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';

import CartTotalAmount from 'components/cart-total-amount/cart-total-amount';
import TravelInput from 'components/travel-input/travel-input';
import ShoppingProductList from 'components/shopping-product-list/shopping-product-list';
import ShoppingCartForm from 'components/shopping-cart-form/shopping-cart-form';
import RadioToggle from 'components/radio-toggle/radio-toggle';
import Modal from 'components/modal/modal';
import { entryModal, currencyModal } from 'components/modal/modals';
import ErrorBlock from 'components/error-block';

import { trackStateWithGoogle } from 'utils/tracking.js';

import { TravelInputChangeType } from 'components/travel-planner/constants';
import travelPlannerStore from 'components/travel-planner/travel-planner-store';

const stationPropType = PropTypes.exact({
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string
});

class ShoppingCart extends Component {
  static propTypes = {
    antiForgeryToken: PropTypes.string,
    changeCurrencyModal: PropTypes.object,
    checkoutPostUrl: PropTypes.string,
    countryCodes: PropTypes.array,
    customerId: PropTypes.string,
    defaultFromStation: stationPropType,
    defaultToStation: stationPropType,
    discountActive: PropTypes.bool,
    discountText: PropTypes.string,
    entryModal: PropTypes.object,
    expandProducts: PropTypes.bool,
    fareTypesUrl: PropTypes.string,
    fromStation: stationPropType,
    language: PropTypes.string,
    maxNoOfTickets: PropTypes.number,
    privacyPolicyUrl: PropTypes.string,
    products: PropTypes.exact({
      currencyCode: PropTypes.string,
      discount: PropTypes.string,
      estimatedTotalPriceText: PropTypes.string,
      fromStationCode: PropTypes.string,
      includeReturn: PropTypes.bool,
      products: PropTypes.array,
      selectedCurrencyCode: PropTypes.string,
      totalPrice: PropTypes.string,
      toStationCode: PropTypes.string
    }),
    returnError: PropTypes.exact({
      active: PropTypes.bool,
      message: PropTypes.string,
      title: PropTypes.string
    }),
    rootUrl: PropTypes.string,
    showMoneySaved: PropTypes.bool,
    stations: PropTypes.arrayOf(stationPropType),
    termsAndConditionUrl: PropTypes.string,
    to: PropTypes.bool,
    toStation: stationPropType,
    translations: PropTypes.shape({
      return: PropTypes.string,
      single: PropTypes.string,
      validTicketsDuration: PropTypes.string,
      validTicketsDurationForCampaign: PropTypes.string
    }),
    updateCartUrl: PropTypes.string
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    discountText: null,
    to: false
  };

  constructor(props) {
    super(props);
    // handle mock url for development
    const rootUrl =
      typeof window !== 'undefined' &&
      window.location.host.indexOf('localhost:3001') > -1
        ? 'http://epi-dev.flytoget.no'
        : props.rootUrl;
    const { selectedCurrencyCode, includeReturn } = props.products;
    let modalOpen = false;
    if (props.entryModal.active) {
      // check referrer
      if (
        typeof document !== 'undefined' &&
        !document.referrer.match(/^https?:\/\/([^/]+\.)?flytoget\.no(\/|$)/i)
      ) {
        modalOpen = true;
        const modalValue = localStorage.getItem('flytoget-entry-modal');
        if (modalValue) {
          // read value from store
          modalOpen = !JSON.parse(modalValue).hide;
        } else {
          // set initial value
          localStorage.setItem(
            'flytoget-entry-modal',
            JSON.stringify({ hide: false })
          );
        }
      }
    }

    this.state = {
      fromStationId: props.defaultFromStation.value,
      toStationId: props.defaultToStation.value,
      fromStationValue: props.defaultFromStation.id,
      toStationValue: props.defaultToStation.id,
      type: includeReturn ? 2 : 1,
      products: props.products,
      root: rootUrl,
      waitingForApiResponse: false,
      dropdownCurrency: selectedCurrencyCode,
      currentModal: modalOpen ? 'entry' : '',
      modalOpen: modalOpen,
      countryCode: '-',
      mobilePhone: '',
      email: '',
      expandProducts: props.expandProducts,
      totalPrice: '0',
      estimatedTotalPriceText: '',
      to: props.to,
      discountActive: props.discountActive
    };
    this.updateCartApiDebounced = debounce(this.updateCart, 300);
  }

  componentDidMount() {
    if (this.state.modalOpen) document.body.classList.add('no-scroll-modal');
    const loadFromLocalstorage =
      this.props.returnError && this.props.returnError.active;
    if (loadFromLocalstorage) this.loadAfterError();
  }

  getClientSideCart = () => {
    const toStation = this.props.stations.find(
      station => station.value === this.travelInput.state.to
    ).id;
    const fromStation = this.props.stations.find(
      station => station.value === this.travelInput.state.from
    ).id;

    return {
      fromStationCode: fromStation,
      toStationCode: toStation,
      includeReturn: this.state.type == 2,
      selectedCurrencyCode: this.state.dropdownCurrency,
      products: this.state.products.products
    };
  };

  updateQueryStringParameter(uri, key, value) {
    var re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
    var separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) return uri.replace(re, '$1' + key + '=' + value + '$2');
    return uri + separator + key + '=' + value;
  }

  updateCart = () => this.updateCartAndCallback();

  updateCartAndCallback = callback => {
    var cart = this.getClientSideCart();
    const { language, customerId, updateCartUrl } = this.props;
    var component = this;
    this.setLoading(() =>
      fetch(updateCartUrl + customerId + '/' + language.toLowerCase(), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(cart)
      })
        .then(res => res.json())
        .then(data => {
          component.updateClientCart(data);
          let newUrl = component.updateQueryStringParameter(
            location.href,
            'from',
            data.fromStationId
          );
          newUrl = component.updateQueryStringParameter(
            newUrl,
            'to',
            data.toStationId
          );
          history.replaceState(history.state, document.title, newUrl);
        })
        .then(() => {
          if (typeof callback !== 'undefined') callback();
        })
        .then(this.stopLoading)
        .catch(() => {
          this.stopLoading();
        })
    );
  };

  updateClientCart = data =>
    this.setState(
      {
        fromStationId: data.fromStationCode,
        toStationId: data.toStationCode,
        to: data.toStationCode == 'GAR',
        type: data.includeReturn ? 2 : 1,
        products: {
          selectedCurrencyCode: data.selectedCurrencyCode,
          includeReturn: data.includeReturn,
          fromStationCode: data.fromStationCode,
          fromStationId: data.fromStationId,
          toStationCode: data.toStationCode,
          toStationId: data.toStationId,
          currencyCode: data.currencyCode,
          discount: data.discount,
          totalPrice: data.totalPrice,
          products: data.products,
          estimatedTotalPriceText: data.estimatedTotalPriceText,
          discountActive: data.discountActive
        }
      },
      this.saveStateToLocalStorage()
    );

  updateForm = data => this.setState(data);

  saveStateToLocalStorage = () => {
    let saveableState = {
      ...this.state,
      modalOpen: false,
      modalClosing: false
    };
    localStorage.setItem('flytoget-client-cart', JSON.stringify(saveableState));
  };

  loadStateFromLocalStorage = () =>
    JSON.parse(localStorage.getItem('flytoget-client-cart'));

  loadAfterError() {
    const data = this.loadStateFromLocalStorage();
    if (typeof data === 'undefined') return;
    let expandProducts = data.products.products.some(
      product => product.isHidden && product.quantity > 0
    );
    data &&
      this.setState({
        ...data,
        waitingForApiResponse: false,
        expandProducts: expandProducts
      });
  }

  onInputChange = e => {
    switch (e.changeType) {
      case TravelInputChangeType.FromUpdated:
        trackStateWithGoogle('fra', e.station.id, 'endre stasjon');
        break;
      case TravelInputChangeType.ToUpdated:
        trackStateWithGoogle('til', e.station.id, 'endre stasjon');
        break;
      case TravelInputChangeType.DirectionChanged:
        trackStateWithGoogle('reiseretning', '', 'endre stasjon');
        break;
    }

    this.updateCart();
    travelPlannerStore.setData({
      location: e.station.value,
      direction: e.direction,
      skipQueryUpdate: true
    });
  };

  onChangeType = e => {
    trackStateWithGoogle(
      e.currentTarget.value == 2 ? 'RETUR' : 'ENKEL',
      '',
      'turtype'
    );
    this.setState({ type: e.currentTarget.value }, this.updateCart);
  };

  onUpdateAmount = index => {
    return value => {
      const products = this.state.products;
      let newProducts = [...products.products];
      trackStateWithGoogle(
        newProducts[index].productName,
        value > 0 ? '+' : '-',
        'billettype'
      );
      // update the product quantity
      newProducts[index].quantity = Math.max(
        0,
        newProducts[index].quantity + value
      );
      this.setState(
        {
          products: {
            ...products,
            products: newProducts // overwrite the list with the new list
          },
          waitingForApiResponse: true
        },
        () => this.updateCartApiDebounced(products.products[index])
      );
    };
  };

  openModal = type => () => {
    this.setState({ modalOpen: true, currentModal: type });
    document.body.classList.add('no-scroll-modal');
  };

  closeModal = e => {
    e && e.preventDefault();
    e && e.stopPropagation();
    if (this.state.currentModal === 'entry')
      localStorage.setItem(
        'flytoget-entry-modal',
        JSON.stringify({ hide: true })
      );
    // delay closing by 300ms - same as css animation
    this.setState({ modalClosing: true }, () =>
      setTimeout(() => {
        this.setState({ modalOpen: false, modalClosing: false });
      }, 300)
    );
    document.body.classList.remove('no-scroll-modal');
  };

  updateCurrency = () => {
    trackStateWithGoogle('fra', this.state.dropdownCurrency, 'endre valuta');
    this.updateCart();
    this.closeModal();
  };

  setCurrencyDropdownValue = e =>
    this.setState({ dropdownCurrency: e.currentTarget.value });

  setLoading = callback =>
    this.setState({ waitingForApiResponse: true }, callback);

  stopLoading = () => this.setState({ waitingForApiResponse: false });

  render() {
    const {
      customerId,
      translations,
      stations,
      language,
      checkoutPostUrl,
      maxNoOfTickets,
      privacyPolicyUrl,
      termsAndConditionUrl,
      changeCurrencyModal,
      entryModal: entryModalContent,
      countryCodes,
      discountText,
      fareTypesUrl,
      returnError,
      antiForgeryToken,
      showMoneySaved
    } = this.props;

    const {
      root,
      waitingForApiResponse,
      type,
      products,
      countryCode,
      mobilePhone,
      email,
      expandProducts
    } = this.state;
    const {
      estimatedTotalPriceText,
      currencyCode,
      discount,
      totalPrice,
      discountActive
    } = products;
    const {
      return: returnText,
      single,
      validTicketsDuration,
      validTicketsDurationForCampaign
    } = translations;

    const checkoutUrl = root + checkoutPostUrl;

    let modal =
      this.state.currentModal === 'entry'
        ? entryModal(entryModalContent, this.closeModal)
        : currencyModal(
            changeCurrencyModal,
            this.updateCurrency,
            this.setCurrencyDropdownValue,
            this.state.dropdownCurrency
          );

    const sumTickets = products.products.reduce(
      (accumulator, current) =>
        (isNaN(accumulator) ? accumulator.quantity : accumulator) +
        current.quantity
    );

    return (
      <div>
        <div className="shopping-cart-container">
          <div className="row shopping-cart">
            {returnError && returnError.active ? (
              <ErrorBlock
                type="error"
                title={returnError.title}
                message={returnError.message}
              />
            ) : null}
            <div className="shopping-cart-inner">
              <TravelInput
                ref={travelInput => (this.travelInput = travelInput)}
                station={
                  this.state.to
                    ? (products && products.fromStationId) ||
                      (this.props.fromStation && this.props.fromStation.id) ||
                      (this.props.defaultFromStation &&
                        this.props.defaultFromStation.id)
                    : (products && products.toStationId) ||
                      (this.props.toStation && this.props.toStation.id) ||
                      (this.props.defaultToStation &&
                        this.props.defaultToStation.id)
                }
                stops={stations}
                towardsAirport={this.state.to}
                airportStation={this.props.defaultToStation.id}
                isLoading={false}
                onChange={this.onInputChange}
                lang={translations}
              />

              <div className="shopping-row centered">
                {(discountActive && validTicketsDurationForCampaign) ||
                  validTicketsDuration}
              </div>

              <RadioToggle
                active={type}
                items={[
                  {
                    name: single,
                    value: 1
                  },
                  {
                    name: returnText,
                    value: 2
                  }
                ]}
                name="tripType"
                onChange={this.onChangeType}
              />

              <ShoppingProductList
                waitingForApiResponse={waitingForApiResponse}
                maxTickets={maxNoOfTickets}
                discountText={discountText}
                onUpdateAmount={this.onUpdateAmount}
                currencyCode={currencyCode}
                products={products.products}
                translations={translations}
                fareTypesUrl={fareTypesUrl}
                expandProducts={expandProducts}
                sumTickets={sumTickets}
                discountActive={discountActive}
              />

              <CartTotalAmount
                waitingForApiResponse={waitingForApiResponse}
                openModal={this.openModal('')}
                amount={totalPrice}
                discount={parseInt(discount, 10)}
                currencyCode={currencyCode}
                estimatedTotalPriceText={estimatedTotalPriceText}
                translations={translations}
                showMoneySaved={showMoneySaved}
              />

              <ShoppingCartForm
                url={checkoutUrl}
                updateCartAndCallback={this.updateCartAndCallback}
                customerId={customerId}
                translations={translations}
                countryCodes={countryCodes}
                language={language.toUpperCase()}
                updateForm={this.updateForm}
                countryCode={countryCode}
                mobilePhone={mobilePhone}
                email={email}
                sumTickets={sumTickets}
                storableState={products}
                antiForgeryToken={antiForgeryToken}
                termsAndConditionUrl={termsAndConditionUrl}
                privacyPolicyUrl={privacyPolicyUrl}
              />
            </div>
          </div>
        </div>
        <Modal
          onClose={this.closeModal}
          closing={this.state.modalClosing}
          noAnim={this.state.currentModal === 'entry'}
          show={this.state.modalOpen}
          modalRef={ref => ref && ref.focus()}
        >
          {modal}
        </Modal>
      </div>
    );
  }
}

export default ShoppingCart;
