import React from 'react';
import PropTypes from 'prop-types';

const SuperMegaHeadingBlock = ({ title, content, isInEditMode }) => (
  <div className="super-mega-heading-block">
    <div className="col centered-horizontal text-centered">
      {(isInEditMode || title) && (
        <h3
          className="col-xs-12 contextual-text"
          data-epi-property-name="Heading"
        >
          {title}
        </h3>
      )}
      {(isInEditMode || content) && (
        <div className="col-xs-12 quote" data-epi-property-name="Text">
          {content}
        </div>
      )}
    </div>
  </div>
);

SuperMegaHeadingBlock.defaultProps = {
  isInEditMode: false
};

SuperMegaHeadingBlock.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  isInEditMode: PropTypes.bool
};

SuperMegaHeadingBlock.propTypesMeta = 'exclude';

export default SuperMegaHeadingBlock;
