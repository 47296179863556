import React from 'react';
import PropTypes from 'prop-types';

const FooterTextBlock = ({ title, text }) => (
  <div className="footer-text-block">
    {title && <strong className="footer-text-block__heading">{title}</strong>}
    {text && <p>{text}</p>}
  </div>
);

FooterTextBlock.defaultProps = {
  title: '',
  text: ''
};

FooterTextBlock.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};

FooterTextBlock.propTypesMeta = 'exclude';

export default FooterTextBlock;
