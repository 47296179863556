import React from 'react';
import PropTypes from 'prop-types';

import IconQuote from 'assets/icons/FT_quotemark.svg';

const QUOTE_BLOCK_TYPE = {
  WHITE: 0,
  BLUE: 1
};

const QUOTE_BLOCK_TYPE_VALUE = ['type-white', 'type-blue'];

const QuoteBlock = ({ quote, name, type }) => {
  let extraClass = '';
  if (type === QUOTE_BLOCK_TYPE.BLUE) {
    extraClass = ' inverted';
  }

  return (
    <div className={'quote-block ' + QUOTE_BLOCK_TYPE_VALUE[type]}>
      <div className="col centered-horizontal">
        <IconQuote className="icon icon-quote" aria-label="sitat ikon" />
        <p
          className={'col-sm-8 quote' + extraClass}
          data-epi-property-name="QuoteText"
        >
          {quote}
        </p>
        <p
          className={'col-sm-3 contextual-text' + extraClass}
          data-epi-property-name="Name"
        >
          {name}
        </p>
      </div>
    </div>
  );
};

QuoteBlock.defaultProps = {
  type: QUOTE_BLOCK_TYPE.BLUE
};

QuoteBlock.propTypes = {
  name: PropTypes.string,
  quote: PropTypes.string,
  type: PropTypes.oneOf([QUOTE_BLOCK_TYPE.WHITE, QUOTE_BLOCK_TYPE.BLUE])
};

QuoteBlock.propTypesMeta = 'exclude';

export default QuoteBlock;
