import React from 'react';
import PropTypes from 'prop-types';

const FileListBlock = ({ blocks, title }) => {
  let fileListBlockEls = [];
  if (blocks && blocks.length > 0) {
    fileListBlockEls = blocks.map(fileListBlock => (
      <a
        aria-role="row"
        className="table-row file-item-container"
        href={fileListBlock.fileUrl}
      >
        <div aria-role="grid-cell" className="table-cell">
          <span className="file-name">{fileListBlock.name}</span>
        </div>
        <div aria-role="grid-cell" className="table-cell file-type">
          {fileListBlock.fileType}
        </div>
        <div aria-role="grid-cell" className="table-cell file-size">
          {fileListBlock.size}
        </div>
      </a>
    ));
  }

  return (
    <div className="file-list-block-container">
      <div className="row">
        <div className="col-xs-12">
          <h2 className="file-list-block-title">{title}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <div aria-role="group" className="table table-responsive table-hover">
            {fileListBlockEls}
          </div>
        </div>
      </div>
    </div>
  );
};

FileListBlock.propTypes = {
  blocks: PropTypes.arrayOf(
    PropTypes.exact({
      fileUrl: PropTypes.string,
      fileType: PropTypes.string,
      name: PropTypes.string,
      size: PropTypes.string
    })
  ),
  title: PropTypes.string
};

FileListBlock.defaultProps = {
  blocks: []
};

FileListBlock.propTypesMeta = 'exclude';

export default FileListBlock;
