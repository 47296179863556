/* eslint-disable @creuna/prop-types-csharp/all */
/* eslint-disable react/no-multi-comp */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { doNotTrack } from 'utils/tracking.js';

if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-unused-vars
  const FormData = require('formdata-polyfill');
}

class ErrorMessage extends Component {
  static propTypes = {
    error: PropTypes.bool,
    message: PropTypes.string
  };

  static defaultProps = {
    countryCodes: [{ name: 'NO', code: '47' }]
  };

  render() {
    const { message } = this.props;
    const expandedStyle = {
      height: this.props.error ? this.error.scrollHeight : 0
    };

    return (
      <span
        className="form-error-message"
        ref={error => (this.error = error)}
        style={expandedStyle}
      >
        <span>{message}</span>
      </span>
    );
  }
}

class ShoppingCartForm extends Component {
  static propTypes = {
    antiForgeryToken: PropTypes.string,
    countryCode: PropTypes.string,
    countryCodes: PropTypes.arrayOf(
      PropTypes.exact({
        fullName: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string
      })
    ),
    customerId: PropTypes.string,
    email: PropTypes.string,
    language: PropTypes.string,
    mobilePhone: PropTypes.string,
    privacyPolicyUrl: PropTypes.string,
    storableState: PropTypes.object,
    sumTickets: PropTypes.number,
    translations: PropTypes.object,
    termsAndConditionUrl: PropTypes.string,
    updateCartAndCallback: PropTypes.func,
    updateForm: PropTypes.func,
    url: PropTypes.string
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    updateCartAndCallback: () => {},
    updateForm: () => {}
  };

  state = {
    errorStates: {}
  };

  componentWillReceiveProps(newProps) {
    if (this.props.sumTickets !== newProps.sumTickets) {
      let errorStates = { ...this.state.errorStates };
      if (newProps.sumTickets === 0) errorStates['sumTickets'] = true;
      else delete errorStates['sumTickets'];
      this.setState({ errorStates });
    }
  }

  noSubmit = e => {
    e.preventDefault();
    return false;
  };

  purchaseTickets = e => {
    var component = this;
    this.props.updateCartAndCallback(() => component.submitForm(e));
  };

  submitForm = e => {
    const { storableState } = this.props;
    const formData = new FormData(this.form);
    let errorStates = {};

    if (formData.get('countryCode') === '-') errorStates['countryCode'] = true;
    if (!this.validateMobile(formData.get('mobilePhone'))) {
      this.trackState('mobilePhone');
      errorStates['mobilePhone'] = true;
    }
    if (!this.validateEmail(formData.get('email'))) {
      this.trackState('email');
      errorStates['email'] = true;
    }
    if (formData.get('checkbox') === null) {
      this.trackState('checkbox');
      errorStates['checkbox'] = true;
    }
    if (this.props.sumTickets === 0) errorStates['sumTickets'] = true;
    if (Object.keys(errorStates).length > 0) {
      e.preventDefault();
      this.setState({ errorStates });
      return false;
    }
    // store form data
    var formJson = {};
    formData.forEach(function(value, key) {
      formJson[key] = value;
    });

    // tracking
    if (
      !doNotTrack() &&
      typeof dataLayer !== 'undefined' &&
      typeof ga !== 'undefined'
    ) {
      const productList = storableState.products.filter(
        product => product.quantity > 0
      );

      // create data to send to GTM
      let products = productList.map(product => {
        const id =
          storableState.fromStationCode +
          '-' +
          storableState.toStationCode +
          '-' +
          (storableState.includeReturn ? 'RETUR' : 'ENKEL');
        return {
          id: id.toUpperCase(),
          name: product.productName,
          brand: 'Flytoget',
          price: product.productPrice,
          quantity: product.quantity,
          dimension1: storableState.fromStationCode,
          dimension2: storableState.toStationCode
        };
      });

      // eslint-disable-next-line no-undef
      dataLayer.push({
        event: 'checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 2
            },
            products: products
          }
        },
        eventCallback: () => {
          this.form.submit();
        }
      });
    } else {
      this.form.submit();
    }
  };

  validateMobile = value =>
    value && value.length > 5 && !isNaN(parseFloat(value)) && isFinite(value);

  validateEmail = value =>
    // regex taken from http://emailregex.com/ (JS version)
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value
    );

  validateSelect = value => value !== '-';

  handleError = (validate, e) => {
    const { errorStates } = this.state;
    let newErrorStates = { ...errorStates };

    const valid = validate(e.target.value);
    if (valid && newErrorStates[e.target.name]) {
      delete newErrorStates[e.target.name];
    } else if (!valid) {
      this.trackState(e.target.name);
      newErrorStates[e.target.name] = true;
    }
    this.setState({
      errorStates: newErrorStates
    });
  };

  trackState = name => {
    let action =
      {
        email: 'epost',
        mobilePhone: 'mobil',
        checkbox: 'vilkår'
      }[name] || '';
    var event = {
      event_category: 'skjemafeil',
      event_action: action,
      event_label: '-',
      event: 'errormessage'
    };
    // eslint-disable-next-line no-undef
    typeof dataLayer !== 'undefined' && dataLayer.push(event);
  };

  handleChange = e =>
    this.props.updateForm({
      [e.target.name]: e.target.value
    });

  onChangeCheckbox = e => {
    const { errorStates } = this.state;
    let newErrorStates = { ...errorStates };

    if (e.target.checked && newErrorStates[e.target.name]) {
      delete newErrorStates[e.target.name];
      this.setState({
        errorStates: newErrorStates
      });
    } else if (!e.target.checked) {
      this.trackState(e.target.name);
      newErrorStates[e.target.name] = true;
    }

    this.setState({
      errorStates: newErrorStates
    });
    this.props.updateForm({
      [e.target.name]: e.target.checked
    });
  };

  render() {
    const {
      url,
      language,
      customerId,
      translations,
      countryCodes,
      termsAndConditionUrl,
      privacyPolicyUrl,
      antiForgeryToken,
      countryCode,
      mobilePhone,
      email
    } = this.props;
    const { errorStates } = this.state;
    const {
      acceptTermsLinkPrefixText,
      acceptTermsLinkText,
      privacyPolicyLinkPrefixText,
      privacyPolicyLinkText,
      privacyPolicyLinkSuffixText,
      placeholderMobile,
      placeholderEmail,
      cardOptions,
      validateMobilePhone,
      validateEmail,
      validateCheckbox,
      noTicketsMessage,
      mobileRegisterText,
      pickCountryCodeMessage,
      emailRegisterText
    } = translations;

    const noTicketsError = (
      <ErrorMessage
        message={noTicketsMessage}
        error={errorStates['sumTickets']}
      />
    );
    const countryCodeError = (
      <ErrorMessage
        message={pickCountryCodeMessage}
        error={errorStates['countryCode']}
      />
    );
    const mobileError = (
      <ErrorMessage
        message={validateMobilePhone}
        error={errorStates['mobilePhone']}
      />
    );
    const emailError = (
      <ErrorMessage message={validateEmail} error={errorStates['email']} />
    );
    const checkboxError = (
      <ErrorMessage
        message={validateCheckbox}
        error={errorStates['checkbox']}
      />
    );

    // Determine selected code
    let selectedCode = countryCode;
    const codes = [{ name: '-', code: '-', label: '-' }].concat(
      countryCodes.map(item => {
        // set new selected language
        if (
          selectedCode === '-' &&
          'no' === language.toLowerCase() &&
          item.code === '47'
        )
          selectedCode = item.code;
        return { ...item, label: '+' + item.code };
      })
    );

    return (
      <div className="form-wrapper shopping-cart-form">
        <form
          ref={node => (this.form = node)}
          onSubmit={this.noSubmit}
          action={url}
          method="POST"
        >
          <div dangerouslySetInnerHTML={{ __html: antiForgeryToken }} />
          <input name="customerId" type="hidden" value={customerId} />
          <div className="error-container">{noTicketsError}</div>
          <div className="mobile-phone">
            <div className="container">
              <div className="select-wrapper">
                <label className="custom-select">
                  <select
                    className={cn({ error: errorStates['countryCode'] })}
                    name="countryCode"
                    value={selectedCode}
                    onChange={this.handleChange}
                    onBlur={e => this.handleError(this.validateSelect, e)}
                  >
                    {codes.map(({ code, label }, index) => (
                      <option key={index} value={code}>
                        {label}
                      </option>
                    ))}
                  </select>
                </label>
              </div>
              <input
                className={cn({ error: errorStates['mobilePhone'] })}
                name="mobilePhone"
                type="text"
                value={mobilePhone}
                placeholder={placeholderMobile}
                onChange={this.handleChange}
                onBlur={e => this.handleError(this.validateMobile, e)}
              />
            </div>
            <label>{mobileRegisterText}</label>
            {mobileError}
            {countryCodeError}
          </div>
          <div className="email">
            <input
              className={cn({ error: errorStates['email'] })}
              name="email"
              type="email"
              value={email}
              placeholder={placeholderEmail}
              onChange={this.handleChange}
              onBlur={e => this.handleError(this.validateEmail, e)}
            />
            <label>{emailRegisterText}</label>
            {emailError}
          </div>
          <div className="checkbox-container">
            <label className="checkbox">
              <input
                className={cn({ error: errorStates['checkbox'] })}
                type="checkbox"
                name="checkbox"
                value="1"
                onChange={this.onChangeCheckbox}
              />
              <span className="checkmark" />
            </label>
            <span>
              {acceptTermsLinkPrefixText}{' '}
              <a
                className="form-checkbox-link"
                href={termsAndConditionUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {acceptTermsLinkText}
              </a>
              {'.'}
              <br />
              {privacyPolicyLinkPrefixText}{' '}
              <a
                className="form-checkbox-link"
                href={privacyPolicyUrl}
                rel="noopener noreferrer"
                target="_blank"
              >
                {privacyPolicyLinkText}
              </a>{' '}
              {privacyPolicyLinkSuffixText}
            </span>
            {checkboxError}
          </div>
          <div className="submit-wrapper">
            <button
              className={cn('btn', 'btn-squared')}
              onClick={this.purchaseTickets}
            >
              {translations.continueToCheckout}
            </button>
          </div>
          <div className="payment-options">
            <div>{cardOptions}</div>
            <div>
              <img
                src="/Public/images/static/payment-options.png"
                alt={cardOptions}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default ShoppingCartForm;
