import React from 'react';

const currencyModal = (
  changeCurrencyModal,
  action,
  dropdownAction,
  currentValue
) => (
  <div>
    <h2>{changeCurrencyModal.title}</h2>
    <p className="modal-content-left-aligned">{changeCurrencyModal.text}</p>
    <div className="select-wrapper">
      <label className="custom-select">
        <select onChange={dropdownAction} value={currentValue}>
          {changeCurrencyModal.currencies.map(currency => {
            return <option key={currency}>{currency}</option>;
          })}
        </select>
      </label>
    </div>
    <button className="btn btn-squared" onClick={action}>
      {changeCurrencyModal.buttonText}
    </button>
  </div>
);

currencyModal.propTypesMeta = 'exclude';

export default currencyModal;
