import React from 'react';
import PropTypes from 'prop-types';

import { IMAGE_SIZE, IMAGE_QUERY, IMAGE_WIDTH } from 'constants/image-type';

const Image = ({
  imageSmall,
  imageX,
  imageY,
  imageAlt,
  useImageSize,
  epiPropertyName
}) => {
  imageX = imageX === null ? 50 : imageX;
  imageY = imageY === null ? 50 : imageY;

  let fluidImageStyle = {
    backgroundPosition: `${imageX}% ${imageY}%`,
    backgroundImage: `url(${imageSmall}?${IMAGE_QUERY[useImageSize]}&${IMAGE_QUERY.transform})`
  };

  const imageSrc = `${imageSmall}?${IMAGE_QUERY[useImageSize]}&${IMAGE_QUERY.transform}`;

  let imageWidth = '';
  if (typeof window !== 'undefined') {
    if (useImageSize) {
      imageWidth =
        IMAGE_WIDTH[IMAGE_SIZE.SMALL] / (window.devicePixelRatio || 1);
    }
  }

  return (
    <div className="image-container">
      <div className="image-wrapper">
        <div
          data-epi-property-name={epiPropertyName}
          style={fluidImageStyle}
          className="fluid-image"
        >
          <img
            src={imageSrc}
            alt={imageAlt}
            data-image-width={imageWidth}
            data-small={imageSmall}
          />
        </div>
      </div>
    </div>
  );
};

Image.propTypes = {
  imageSmall: PropTypes.string,
  imageX: PropTypes.number,
  imageY: PropTypes.number,
  imageAlt: PropTypes.string,
  useImageSize: PropTypes.string,
  epiPropertyName: PropTypes.string
};

Image.propTypesMeta = 'exclude';

export default Image;
