export function generateUniqueId() {
  return (Math.random() + 1).toString(36).substring(2, 7);
}

// recursively gets the offsetTop of an element
export const getOffsetTop = el => {
  let top = el.offsetTop || 0;
  // console.log(el, top);
  if (el.parentNode && top === 0) {
    top += this.getOffsetTop(el.parentNode);
  }
  return top;
};

export function debounce(func, wait, immediate) {
  var timeout;

  return function() {
    var context = this,
      args = arguments;

    var later = function() {
      timeout = null;

      if (!immediate) {
        func.apply(context, args);
      }
    };

    var callNow = immediate && !timeout;

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);

    if (callNow) {
      func.apply(context, args);
    }
  };
}

export const isRunningOnClient =
  typeof window !== 'undefined' && window.document ? true : false;
