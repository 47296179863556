import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconClose from 'assets/icons/FT_close_mob.svg';

const Modal = ({ closing, modalRef, noAnim, onClose, show, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={cn('modal-backdrop', {
        closing: closing,
        'no-anim': noAnim
      })}
      tabIndex="0"
      ref={modalRef}
      onClick={onClose}
      onKeyDown={e => {
        if (e.keyCode === 27 || e.keyCode === 13) {
          onClose();
        }
      }}
    >
      <div
        className="modal-content"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {children}
        <button onClick={onClose} className="modal-close">
          <IconClose />
        </button>
      </div>
    </div>
  );
};

Modal.propTypes = {
  closing: PropTypes.bool,
  modalRef: PropTypes.func.isRequired,
  noAnim: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
  children: PropTypes.node
};

Modal.propTypesMeta = 'exclude';

Modal.defaultProps = {
  onClose: () => {}
};

export default Modal;
