import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image/image';
import { IMAGE_SIZE } from 'constants/image-type';

const HighlightBlock = ({
  title,
  imageUrl,
  imageAlt,
  imageX,
  imageY,
  text,
  blockClassName,
  animateDelay,
  isInEditMode
}) => {
  return (
    <div className={blockClassName} data-wow-delay={animateDelay}>
      {(isInEditMode || (title && !imageUrl)) && (
        <h2 data-epi-property-name="LargeText">{title}</h2>
      )}
      {(isInEditMode || imageUrl) && (
        <Image
          imageSmall={imageUrl}
          imageX={imageX}
          imageY={imageY}
          imageAlt={imageAlt}
          useImage={IMAGE_SIZE.SMALL}
          epiPropertyName="ImageLink"
        />
      )}
      <div
        className="highlight-text editorial-text"
        data-epi-property-name="SmallText"
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </div>
  );
};

HighlightBlock.propTypes = {
  animateDelay: PropTypes.string,
  blockClassName: PropTypes.string,
  title: PropTypes.string,
  imageUrl: PropTypes.string,
  imageX: PropTypes.number,
  imageY: PropTypes.number,
  imageAlt: PropTypes.string,
  isInEditMode: PropTypes.bool,
  text: PropTypes.string
};

HighlightBlock.propTypesMeta = 'exclude';

export default HighlightBlock;
