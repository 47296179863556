import React from 'react';

import Checkmark from 'assets/icons/FT_Checkmark.svg';

/////////////////////////
// THIS IS ONLY A MOCK //
/////////////////////////

const ShopInfo = () => (
  <div className="shop-info-container">
    <div className="row shop-info">
      <div className="shop-info-inner">
        <div className="centered-horizontal text-centered">
          <img
            width="200px"
            src="/static-site/assets/RGB_FTG-Primary-Logo_Col_Pos.png"
          />
        </div>
        <div className="centered-horizontal text-centered">
          <p>
            Flytoget Airport express takes you from Oslo Airport Gardermoen to
            the Oslo city centre in only 19 minutes
          </p>
          <h2>Our promise to you</h2>
        </div>
        <div className="editorial-text">
          <ul>
            <li>
              <Checkmark />
              Departure every 10 minutes to and from Oslo Aiport
            </li>
            <li>
              <Checkmark />
              Only 19 minutes to or from the city centre
            </li>
            <li>
              <Checkmark />
              Most comfortable transport
            </li>
            <li>
              <Checkmark />
              Unique travel guarantee
            </li>
            <li>
              <Checkmark />
              Service Excellence
            </li>
          </ul>
        </div>
        <div className="centered-horizontal text-centered">
          <button className="btn btn-squared">Buy tickets now</button>
        </div>
      </div>
    </div>
  </div>
);

ShopInfo.propTypesMeta = 'exclude';

export default ShopInfo;
