import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/payment-spinner-animation/payment-spinner-animation';

const PaymentSpinner = ({ text }) => {
  return (
    <div className="payment-spinner" role="status" aria-live="polite">
      <div className="payment-spinner__content">
        <LoadingIndicator className="payment-spinner__icon" />
        <div className="payment-spinner__text">{text}</div>
      </div>
    </div>
  );
};

PaymentSpinner.propTypes = {
  text: PropTypes.string
};

export default PaymentSpinner;
