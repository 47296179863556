'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _day = require('./day');

var _day2 = _interopRequireDefault(_day);

var _week_number = require('./week_number');

var _week_number2 = _interopRequireDefault(_week_number);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

class Week extends React.Component {
  static propTypes = {
    day: PropTypes.object.isRequired,
    onDayClick: PropTypes.func,
    onDayMouseEnter: PropTypes.func,
    showWeekNumber: PropTypes.bool
  };

  state = {
    _isDisabled: true
  };

  setDisabledState(props) {
    var startOfWeek = props.day.clone().startOf('week');
    var endOfWeek = startOfWeek.clone().add(6, 'days');

    this.setState({
      _isDisabled:
        endOfWeek.isBefore(props.minDate, 'day') ||
        startOfWeek.isAfter(props.maxDate, 'day')
    });
  }

  componentWillMount() {
    this.setDisabledState(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.setDisabledState(nextProps);
  }

  handleDayClick(day, event) {
    if (this.props.onDayClick) {
      this.props.onDayClick(day, event);
    }
  }

  handleDayMouseEnter(day) {
    if (this.props.onDayMouseEnter) {
      this.props.onDayMouseEnter(day);
    }
  }

  days = [];
  renderDays() {
    var _this = this;

    var startOfWeek = this.props.day.clone().startOf('week');
    // console.log('Start of week: ', startOfWeek.format());
    var days = [];
    if (this.props.showWeekNumber) {
      days.push(
        _react2.default.createElement(_week_number2.default, {
          key: 'W',
          weekNumber: parseInt(startOfWeek.format('w'), 10)
        })
      );
    }

    this.days = [];

    return days.concat(
      [0, 1, 2, 3, 4, 5, 6].map(function(offset) {
        var day = startOfWeek.clone().add(offset, 'days');
        return _react2.default.createElement(_day2.default, {
          ref: child => {
            _this.days.push(child);
          },
          key: offset,
          day: day,
          month: _this.props.month,
          onClick: _this.handleDayClick.bind(_this, day),
          onMouseEnter: _this.handleDayMouseEnter.bind(_this, day),
          minDate: _this.props.minDate,
          maxDate: _this.props.maxDate,
          excludeDates: _this.props.excludeDates,
          includeDates: _this.props.includeDates,
          highlightDates: _this.props.highlightDates,
          selectingDate: _this.props.selectingDate,
          filterDate: _this.props.filterDate,
          selected: _this.props.selected,
          selectsStart: _this.props.selectsStart,
          selectsEnd: _this.props.selectsEnd,
          startDate: _this.props.startDate,
          endDate: _this.props.endDate,
          utcOffset: _this.props.utcOffset
        });
      })
    );
  }

  render() {
    return _react2.default.createElement(
      'div',
      {
        className: this.state._isDisabled
          ? 'react-datepicker__week react-datepicker__week--disabled'
          : 'react-datepicker__week'
      },
      this.renderDays()
    );
  }
}

export default Week;
