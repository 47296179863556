import React from 'react';
import PropTypes from 'prop-types';

const RichTextBlock = ({ content, isInEditMode }) => (
  <div className="rich-text-block">
    <div className="col centered-horizontal text-centered">
      <div className="col-xs-12 col-sm-10">
        {(isInEditMode || content) && (
          <div
            className="editorial-text text-centered"
            dangerouslySetInnerHTML={{ __html: content }}
            data-epi-property-name="TextArea"
          />
        )}
      </div>
    </div>
  </div>
);

RichTextBlock.defaultProps = {
  isInEditMode: false
};

RichTextBlock.propTypes = {
  content: PropTypes.string,
  isInEditMode: PropTypes.bool
};

RichTextBlock.propTypesMeta = 'exclude';

export default RichTextBlock;
