import React from 'react';
import PropTypes from 'prop-types';

const PriceOverviewBlock = ({
  title,
  priceItems,
  content,
  linkUrl,
  linkText,
  firstColumnHeading,
  secondColumnHeading,
  thirdColumnHeading
}) => {
  let priceItemsEl = [];

  if (priceItems && priceItems.length > 0) {
    priceItemsEl = priceItems.map((priceItem, index) => {
      return (
        <li key={`${priceItem.station}-${index}`} className="price-item">
          <div className="price-station">{priceItem.station}</div>
          <div className="price-adults">{priceItem.adultPrice}</div>
          <div className="price-other">{priceItem.otherPrice}</div>
        </li>
      );
    });
  }

  return (
    <div className="price-overview-block">
      <div className="row">
        <div className="col-xs-12">
          <h3 data-epi-property-name="Heading">{title}</h3>
        </div>
      </div>

      <div className="row price-overview-content">
        <div className="col-xs-12 col-sm-6">
          <ul className="price-overview-list">
            <li className="price-item price-item-header">
              <div
                className="price-station"
                data-epi-property-name="FirstColumnHeading"
              >
                {firstColumnHeading}
              </div>
              <div
                className="price-adults"
                data-epi-property-name="SecondColumnHeading"
              >
                {secondColumnHeading}
              </div>
              <div
                className="price-other"
                data-epi-property-name="ThirdColumnHeading"
              >
                {thirdColumnHeading}
              </div>
            </li>
            {priceItemsEl}
            {linkText && (
              <li>
                <a className="cta price-item price-item-link" href={linkUrl}>
                  {linkText}
                </a>
              </li>
            )}
          </ul>
        </div>
        <div className="col-xs-12 col-sm-6 col-md-5 off-md-1 off-lg-1">
          <div
            className="editorial-text"
            data-epi-property-name="Information"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      </div>
    </div>
  );
};

PriceOverviewBlock.propTypes = {
  content: PropTypes.string,
  firstColumnHeading: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  priceItems: PropTypes.arrayOf(
    PropTypes.exact({
      adultPrice: PropTypes.string,
      otherPrice: PropTypes.string,
      station: PropTypes.string
    })
  ),
  secondColumnHeading: PropTypes.string,
  thirdColumnHeading: PropTypes.string,
  title: PropTypes.string
};

PriceOverviewBlock.defaultProps = {
  isInEditMode: false
};

PriceOverviewBlock.propTypesMeta = 'exclude';

export default PriceOverviewBlock;
