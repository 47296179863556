import React from 'react';
import PropTypes from 'prop-types';

import FooterLinkList from 'components/footer-link-list/footer-link-list';
import FooterTextBlock from 'components/footer-text-block/footer-text-block';

import IconFacebook from 'assets/icons/FT_calendar.svg';
import IconTwitter from 'assets/icons/FT_twitter.svg';

const FooterBlock = ({ title }) => (
  <div className="footer-block">
    <div className="top">
      <div className="row centered-horizontal desktop-only">
        <div className="off-md-8 col-xs-12 col-md-4">
          <div>
            <img
              className="logo"
              src="static-site/assets/logo-dark.png"
              width="200"
              alt="Flytoget sitt logo"
            />
          </div>
        </div>
      </div>
      <div className="row centered-horizontal">
        <div className="col-xs-12 col-md-8 inverted">
          <div className="footer-heading">
            <h3 data-epi-property-name="Heading">{title}</h3>
          </div>
        </div>
        <div className="col-xs-12 col-md-4 inverted desktop-only">
          <div className="footer-some">
            <span>Besøk oss på</span>
            <a href="#">
              <IconFacebook role="img" aria-label="Facebook" />
            </a>
            <a href="#">
              <IconTwitter role="img" aria-label="Youtube" />
            </a>
          </div>
        </div>
      </div>
      <div className="row centered-horizontal">
        <div className="col-xs-12 col-md-3 inverted order-mob-2">
          <FooterLinkList
            title="Kundeservice"
            links={[
              { title: 'Hittegods', url: 'http://flytoget.no' },
              { title: 'Transportvilkår', url: 'http://flytoget.no' },
              { title: 'Transportklagenemda', url: 'http://flytoget.no' }
            ]}
          />
        </div>
        <div className="col-xs-12 col-md-9 inverted order-mob-1">
          <div className="footer-featured">
            Telefon: <a href="tel:23159000">23 15 90 00</a>
            <br />
            Åpningstider for kundeservice er mandag-fredag 08.00-16.00
            <br />
            E-post:{' '}
            <a href="mailto:flytoget@flytoget.no">flytoget@flytoget.no</a>
          </div>
        </div>
      </div>
      <div className="row centered-horizontal">
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterLinkList title="Reiseplanlegger" url="travel" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterLinkList
            title="Reiseinformasjon"
            links={[
              { title: 'Billetter og priser', url: 'http://flytoget.no' },
              {
                title: 'Flytogets unike reisegaranti',
                url: 'http://flytoget.no'
              },
              { title: 'Tilgjengelighet', url: 'http://flytoget.no' },
              { title: 'Reise med barn', url: 'http://flytoget.no' },
              { title: 'Reise med dyr', url: 'http://flytoget.no' },
              { title: 'Bagasje', url: 'http://flytoget.no' }
            ]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterLinkList
            title="Nyheter og presse"
            links={[
              { title: 'Arkiv', url: 'http://flytoget.no' },
              { title: 'Flytogfakta', url: 'http://flytoget.no' }
            ]}
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterLinkList
            title="Om Flytoget"
            links={[
              { title: 'Historie', url: 'http://flytoget.no' },
              { title: 'Service excellence', url: 'http://flytoget.no' },
              {
                title: 'Flytogets 2015 resultat',
                url: 'http://flytoget.no'
              },
              { title: 'Jobb i Flytoget', url: 'http://flytoget.no' },
              {
                title: 'Bærekraft og samfunnsansvar',
                url: 'http://flytoget.no'
              },
              {
                title: 'Styre, ledelse og rapporter',
                url: 'http://flytoget.no'
              },
              { title: 'Etiske retningslinjer', url: 'http://flytoget.no' },
              {
                title: 'Eierstyring og selskapsledelse',
                url: 'http://flytoget.no'
              }
            ]}
          />
        </div>
      </div>
      <div className="row mobile-only">
        <div className="col-xs-12">
          <div className="footer-some">
            <span>Besøk oss på</span>
            <a href="#">
              <IconFacebook role="img" aria-label="Facebook" />
            </a>
            <a href="#">
              <IconTwitter role="img" aria-label="Youtube" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="bottom">
      <div className="row centered-horizontal">
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterTextBlock title="Organisasjonsnummer" text="965 694 404" />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterTextBlock
            title="Besøksadresse"
            text="Biskop Gunnerus' gate 14 Posthuset, 0185 Oslo"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterTextBlock
            title="Postadresse"
            text="Flytoget AS Postboks 19 Sentrum, 0101 Oslo"
          />
        </div>
        <div className="col-xs-12 col-sm-6 col-md-3">
          <FooterTextBlock
            title="Fakturaadresse"
            text="Flytoget AS, c/o Fakturamottak, At Work Systems AS, Neptunveien 6, 7652 Verdal"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <a href="#">Les mer om Flytogets bruk av informasjonskapsler her</a>
        </div>
      </div>
    </div>
  </div>
);

FooterBlock.defaultProps = {
  title: 'Hei, kan vi hjelpe deg?'
};

FooterBlock.propTypes = {
  title: PropTypes.string
};

FooterBlock.propTypesMeta = 'exclude';

export default FooterBlock;
