/* eslint-disable @creuna/prop-types-csharp/all */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import StepperRow from 'components/stepper-row/stepper-row';
import ErrorBlock from 'components/error-block';

import Expand from 'assets/icons/FT_arrow_small_up.svg';

class ShoppingProductList extends Component {
  static propTypes = {
    currencyCode: PropTypes.string,
    discountActive: PropTypes.bool,
    discountText: PropTypes.string,
    expandProducts: PropTypes.bool,
    fareTypesUrl: PropTypes.string,
    maxTickets: PropTypes.number,
    onUpdateAmount: PropTypes.func,
    products: PropTypes.array,
    sumTickets: PropTypes.number,
    translations: PropTypes.object,
    waitingForApiResponse: PropTypes.bool
  };

  static propTypesMeta = 'exclude';

  constructor(props) {
    super(props);
    this.state = {
      changingSize: false,
      showAllFares: props.expandProducts
    };
  }

  getProducts = (sumTickets, filter, setTabIndex) => {
    const {
      translations,
      maxTickets,
      currencyCode,
      waitingForApiResponse,
      onUpdateAmount,
      discountActive
    } = this.props;
    return this.props.products
      .map((product, i) => {
        const tab = setTabIndex
          ? {
              tabIndex: this.state.showAllFares ? 0 : -1
            }
          : {};
        if (filter(product)) {
          return (
            <StepperRow
              key={i}
              {...tab}
              {...product}
              waitingForApiResponse={waitingForApiResponse}
              onUpdateAmount={onUpdateAmount(i)}
              currencyCode={currencyCode}
              maxTickets={maxTickets}
              sumTickets={sumTickets}
              translations={translations}
              discountActive={discountActive}
            />
          );
        }
      })
      .filter(p => p != null);
  };

  toggleList = () => {
    this.setState({ changingSize: true }, () => {
      setTimeout(() => {
        // need this to handle contraction properly
        this.setState({ showAllFares: !this.state.showAllFares }, () => {
          setTimeout(() => {
            this.setState({ changingSize: false });
          }, 300); // css animation duration
        });
      }, 1);
    });
  };

  getHeight = () => {
    if (this.state.changingSize)
      return this.state.showAllFares ? this.listElement.scrollHeight : 0;
    return this.state.showAllFares ? 'auto' : 0;
  };

  componentDidUpdate(prevProps) {
    if (
      !prevProps.expandProducts &&
      this.props.expandProducts &&
      !this.state.showAllFares
    )
      this.toggleList();
  }

  render() {
    const {
      translations,
      discountText,
      maxTickets,
      fareTypesUrl,
      sumTickets
    } = this.props;
    const { showAllFares } = this.state;
    const {
      lessTickets,
      moreTickets,
      readMoreAboutOurFareTypes,
      maxTicketsWarning
    } = translations;
    const errorMessage =
      sumTickets >= maxTickets ? (
        <ErrorBlock type="warning" title={maxTicketsWarning} />
      ) : null;
    const discountMessage = discountText ? (
      <ErrorBlock type="ok" title={discountText} />
    ) : null;
    const productList = this.getProducts(sumTickets, p => !p.isHidden);
    const moreProducts = this.getProducts(sumTickets, p => p.isHidden, true);
    return (
      <div className="stepper-row-wrapper">
        {errorMessage}
        {discountMessage}
        {productList}
        <div
          className="stepper-row-more-fares"
          ref={node => (this.listElement = node)}
          style={{ height: this.getHeight() }}
        >
          {moreProducts}
        </div>

        {moreProducts.length > 0 ? (
          <a
            className={cn('stepper-row-show-more', {
              open: showAllFares
            })}
            onKeyDown={e => {
              var code = e.which;
              if (code === 32 || code === 13) {
                e.preventDefault();
                this.toggleList();
              }
            }}
            tabIndex="0"
            onClick={this.toggleList}
          >
            {showAllFares ? lessTickets : moreTickets}
            <span>
              <Expand />
            </span>
          </a>
        ) : null}
        <div>
          <a
            className="stepper-row-link"
            href={fareTypesUrl}
            rel="noopener noreferrer"
            tabIndex="0"
            target="_blank"
          >
            {readMoreAboutOurFareTypes}
          </a>
        </div>
      </div>
    );
  }
}

export default ShoppingProductList;
