import React from 'react';
import cn from 'classnames';

import Checkmark from 'assets/icons/checkmark_orange.svg';

const entryModal = (entryModal, action) => (
  <div className="no-anim">
    <div className="shop-info-inner">
      <div className={cn('logo', 'centered-horizontal', 'text-centered')}>
        <img src="/Public/images/static/RGB_FTG-Primary-Logo_Col_Pos.png" />
      </div>
      <div
        className={cn('centered-horizontal', 'text-centered')}
        dangerouslySetInnerHTML={{ __html: entryModal.text }}
      />
      {entryModal.image && <img src={entryModal.image} />}
      <div className={cn('editorial-text')}>
        {entryModal.listTitle && <h4>{entryModal.listTitle}</h4>}
        <div>
          <ul>
            {entryModal.list &&
              entryModal.list.map((item, index) => (
                <li key={index}>
                  <Checkmark />
                  <span>{item}</span>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </div>
    <button className="btn btn-squared" onClick={action}>
      {entryModal.buttonText}
    </button>
  </div>
);

entryModal.propTypesMeta = 'exclude';

export default entryModal;
