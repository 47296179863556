import React from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import imageResizer from 'js/image-resizer';
import cn from 'classnames';
import { createHtmlImage } from 'js/dom-image-utils';

const FirstLoadSizeMultiplier = 0.05; // First time we preload smaller image in order to reduce wait time.

/**
 * Image
 *
 * Key notes:
 * - automatically responsive by default (size is automatically calculated from container size)
 * - default transform is 'fill'
 * - width or height can be fixed (useful for fluid images), in this case default transform is 'fit'
 * - when both width and height are defined it becomes unresponsive.
 * - can be rendered as fluid or ordinary image
 * - resolution it is kind of steps for automatic size calculation, for example if resolution is 100, horizontal image width could be 100,200,300...1200... and height is calculated according to aspect ratio and vise versa for vertical image
 * - default resolutions for images with size > 100 is 100 for < 100 is 10
 * - device pixel ratio is taken into account for calculating image size
 *
 * Transforms:
 * 'fill' - image is cropped according to container proportions to fill it completely.
 * 'fit' - image is resized with saving original proportions to fit container by width or height.
 */
class ResponsiveImage extends React.Component {
  static transforms = {
    fill: 'fill',
    fit: 'fit',
  };

  static propTypes = {
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    transform: PropTypes.oneOf(Object.values(ResponsiveImage.transforms)),
    fluid: PropTypes.bool,
    focusPoint: PropTypes.shape({
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    width: PropTypes.number,
    height: PropTypes.number,
    resolution: PropTypes.number,
  };

  static propTypesMeta = {
    className: 'exclude',
    transform: 'exclude',
    fluid: 'exclude',
    width: 'exclude',
    height: 'exclude',
    resolution: 'exclude'
  };

  canBeResized = imageResizer.checkImageCanBeResized(this.props.src);
  autoTransform = this.constructor.transforms.fill;
  autoCalcWidthOnly = false;
  autoCalcHeightOnly = false;

  defaultSrc = !this.canBeResized
    ? this.props.src
    : this.props.width > 0 && this.props.height > 0
    ? imageResizer.buildResizeUrl(
        this.props.src,
        this.props.width,
        this.props.height,
        this.props.transform ||
          this.autoTransform === this.constructor.transforms.fill
          ? imageResizer.Transforms.downFill
          : imageResizer.Transforms.downFit
      )
    : undefined;

  state = {
    src: this.defaultSrc,
    width: 0,
    height: 0,
    ratio: 0,
    containerOffsetWidth: 0,
    containerOffsetHeight: 0,
  };

  preloadImage = createHtmlImage();
  firstPreloadImage = createHtmlImage();

  handleResize = () => {
    if (
      !this.container ||
      (this.container.offsetWidth === 0 && this.container.offsetHeight === 0)
    ) {
      return;
    }

    if (this.container.offsetWidth === 0 || this.container.offsetHeight === 0) {
      this.autoTransform = ResponsiveImage.transforms.fit;
      this.autoCalcWidthOnly = this.container.offsetWidth > 0;
      this.autoCalcHeightOnly = this.container.offsetHeight > 0;
    }

    const skipByWidth =
      this.props.width > 0
        ? this.state.width === this.props.width
        : this.container.offsetWidth === this.state.containerOffsetWidth;

    const skipByHeight =
      this.props.height > 0
        ? this.state.height === this.props.height
        : this.container.offsetHeight === this.state.containerOffsetHeight;

    if (skipByWidth && skipByHeight) {
      return;
    }

    const resolutionByWidth =
      !(this.props.width > 0) &&
      this.container.offsetWidth > this.container.offsetHeight;

    let resolution = this.props.resolution;
    if (!(resolution > 0)) {
      var resolutionSize = resolutionByWidth
        ? this.container.offsetWidth
        : this.container.offsetHeight;

      resolution = resolutionSize > 100 ? 100 : 10;
    }

    const widthResolution = resolutionByWidth ? resolution : 1;
    const heightResolution = resolutionByWidth ? 1 : resolution;

    let newWidth =
      this.props.width > 0
        ? this.props.width
        : this.autoCalcHeightOnly
        ? 0
        : imageResizer.calcImageWidth(
            this.container.offsetWidth,
            widthResolution
          );

    let newHeight =
      this.props.height > 0
        ? this.props.height
        : this.autoCalcWidthOnly
        ? 0
        : imageResizer.calcImageHeight(
            this.container.offsetHeight,
            heightResolution
          );

    if (resolutionByWidth) {
      if (!this.autoCalcWidthOnly && !this.props.height > 0) {
        newHeight = Math.ceil(
          newWidth / (this.container.offsetWidth / this.container.offsetHeight)
        );
      }
    } else {
      if (!this.autoCalcHeightOnly && !this.props.width > 0) {
        newWidth = Math.ceil(
          newHeight / (this.container.offsetHeight / this.container.offsetWidth)
        );
      }
    }

    const newRatio =
      this.container.offsetHeight > 0
        ? Math.ceil(this.container.offsetWidth / this.container.offsetHeight)
        : 1;

    this.setState(previousState => {
      if (
        previousState.width >= newWidth &&
        previousState.height >= newHeight &&
        previousState.ratio === newRatio
      ) {
        return {
          containerOffsetWidth: this.container.offsetWidth,
          containerOffsetHeight: this.container.offsetHeight,
        };
      }

      const newSrc = imageResizer.buildResizeUrl(
        this.props.src,
        newWidth,
        newHeight,
        this.props.transform || this.autoTransform === ResponsiveImage.transforms.fill
          ? imageResizer.Transforms.downFill
          : imageResizer.Transforms.downFit,
        this.props.focusPoint ? this.props.focusPoint.x : null,
        this.props.focusPoint ? this.props.focusPoint.y : null
      );

      if (!this.firstPreloadImage.src && !this.state.src) {
        var firstLoadWidth = Math.ceil(newWidth * FirstLoadSizeMultiplier);
        var firstLoadHeight = Math.ceil(newHeight * FirstLoadSizeMultiplier);
        if (firstLoadWidth > 0 && firstLoadHeight > 0) {
          const firstLoadSrc = imageResizer.buildResizeUrl(
            this.props.src,
            firstLoadWidth,
            firstLoadHeight,
            this.props.transform || this.autoTransform === ResponsiveImage.transforms.fill
              ? imageResizer.Transforms.downFill
              : imageResizer.Transforms.downFit,
            this.props.focusPoint ? this.props.focusPoint.x : null,
            this.props.focusPoint ? this.props.focusPoint.y : null
          );
          this.firstPreloadImage.src = firstLoadSrc;
        }
      }

      if (newSrc !== this.state.src) {
        this.preloadImage.src = newSrc;
      }

      return {
        width: newWidth,
        height: newHeight,
        ratio: newRatio,
        containerOffsetWidth: this.container.offsetWidth,
        containerOffsetHeight: this.container.offsetHeight,
      };
    });
  };

  debouncedHandleResize = debounce(this.handleResize, 300);

  onFirstSrcPreloaded() {
    if (!this.state.src) {
      this.setState({ src: this.firstPreloadImage.src });
    }
  }

  onSrcPreloaded() {
    this.setState({ src: this.preloadImage.src });
  }

  checkElementIsHidden(element) {
    return !element.offsetParent;
  }

  isHidden() {
    return this.checkElementIsHidden(this.container);
  }

  getHiddenRoot() {
    if (!this.checkElementIsHidden(this.container)) {
      return null;
    }

    var parent = this.container.parentElement;
    while (parent && this.checkElementIsHidden(parent.parentElement)) {
      parent = parent.parentElement;
    }

    return parent;
  }

  onObservedChange() {
    if (!this.isHidden()) {
      this.debouncedHandleResize();
    }
  }

  componentDidMount() {
    const self = this;
    this.firstPreloadImage.onload = () => {
      self.onFirstSrcPreloaded();
    };
    this.preloadImage.onload = () => {
      self.onSrcPreloaded();
    };
    if (this.canBeResized) {
      this.handleResize();
      this.debouncedHandleResize();
      window.addEventListener('resize', this.debouncedHandleResize);
    }

    this.observer = this.canBeResized
      ? new MutationObserver(() => this.onObservedChange.call(this))
      : null;

    if (this.observer && this.isHidden()) {
      this.observer.observe(this.getHiddenRoot(), {
        attributes: true,
      });
    }
    this.setState({ componentDidMount: true });
  }

  getSnapshotBeforeUpdate(prevProps) {
    if (
      this.props.width !== prevProps.width ||
      this.props.height !== prevProps.height
    ) {
      this.handleResize();
    }
    return null;
  }

  componentWillUnmount() {
    if (this.canBeResized) {
      window.removeEventListener('resize', this.debouncedHandleResize);
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  componentDidUpdate() {
    if (this.canBeResized) this.debouncedHandleResize();
  }

  render() {
    return (
      <React.Fragment>
        {!this.props.fluid ? (
          <span
            className={cn('responsive-image', this.props.className)}
            ref={c => (this.container = c)}
          >
            {this.state.src && (
              <img
                className="responsive-image__img"
                src={this.state.src}
                alt={this.props.alt ? this.props.alt : ''}
              />
            )}
          </span>
        ) : (
          <span
            className={cn(
              'responsive-image responsive-image_fluid',
              this.props.className
            )}
            style={{
              backgroundImage: this.state.src
                ? `url(${this.state.src})`
                : undefined,
              backgroundPosition: this.props.focusPoint
                ? `${this.props.focusPoint.x}% ${this.props.focusPoint.y}%`
                : null,
            }}
            ref={c => (this.container = c)}
          >
            {this.state.src && (
              <img
                className="responsive-image__img"
                src={this.state.src}
                alt={this.props.alt ? this.props.alt : ''}
              />
            )}
          </span>
        )}
      </React.Fragment>
    );
  }
}

export default ResponsiveImage;
