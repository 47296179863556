import React from 'react';
import PropTypes from 'prop-types';
import MenuLink from 'components/menu-link';
import { getPageHash, getPageUrlWithoutQueryAndHash } from 'utils/url-utils.js';

var SupportedHashesMap = new Map();
SupportedHashesMap.set('list', ['list']).set('details', ['list']);

class MenuItemActive extends React.Component {
  static propTypes = {
    links: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
    onActiveItemChanged: PropTypes.func
  };
  static propTypesMeta = 'exclude';

  constructor(props) {
    super(props);
    this.state = {
      defaultActiveItem: null,
      currntActiveItem: null
    };
  }

  findItem(url, hash) {
    var linksList = this.props.links;

    for (let i = 0; i < linksList.length; i++) {
      var linkItemUrl = linksList[i].url;

      if (linkItemUrl === url + '#' + hash) {
        return linksList[i];
      }
    }

    return null;
  }

  findActiveItem(links) {
    for (let i = 0; i < links.length; i++) {
      if (links[i].isActive === true) {
        return links[i];
      }
    }
  }

  setActiveItem() {
    var pageUrl = getPageUrlWithoutQueryAndHash();
    var pageHash = getPageHash();
    var defaultActiveItem = this.state.defaultActiveItem;
    var currentActiveItem = this.state.currentActiveItem;

    var hasChanges = false;

    if (defaultActiveItem) {
      defaultActiveItem.isActive = false;
      hasChanges = true;
    }

    if (currentActiveItem) {
      currentActiveItem.isActive = false;
      this.setState({ currentActiveItem: null });
      hasChanges = true;
    }

    if (pageHash && SupportedHashesMap.has(pageHash)) {
      var mappedHashes = SupportedHashesMap.get(pageHash);

      for (let i = 0; i < mappedHashes.length; i++) {
        var newActiveItem = this.findItem(pageUrl, mappedHashes[i]);

        if (newActiveItem) {
          newActiveItem.isActive = true;
          this.setState({ currentActiveItem: newActiveItem });
          hasChanges = true;
          break;
        }
      }
    } else {
      if (defaultActiveItem && !defaultActiveItem.isActive) {
        defaultActiveItem.isActive = true;
        hasChanges = true;
      }
    }

    if (hasChanges) {
      this.props.onActiveItemChanged();
    }
  }

  componentWillMount() {
    var activeItem = this.findActiveItem(this.props.links);

    if (activeItem) {
      this.setState({ defaultActiveItem: activeItem });
    }
  }

  componentDidMount() {
    this.setActiveItem();
    const onHashChange = () => this.setActiveItem();

    window.addEventListener('hashchange', onHashChange);
    return () => window.removeEventListener('hashchange', onHashChange);
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

export default MenuItemActive;
