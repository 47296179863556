import { fullDate } from 'components/travel-planner/constants';

import NoDataError from 'utils/no-data-error';
import parseTrip from './parse-trip';
import moment from 'moment-timezone';

export default (
  url,
  originId,
  destId,
  date,
  time,
  lang,
  from,
  to,
  currentTrips,
  shouldMerge
) => {
  const fetchUrl = `${url}?originId=${originId}&destId=${destId}&date=${date}&time=${time}&lang=${lang}`;

  return fetch(fetchUrl)
    .then(response => {
      if (
        response.headers.get('content-type').indexOf('application/json') < 0
      ) {
        throw new TypeError('not json');
      }

      return response.json();
    })
    .then(json => {
      if (json.log) {
        // eslint-disable-next-line no-console
        console.log('Fetch trips log:', json.log);
      }
      if (json.error) {
        throw new NoDataError(json.error);
      }

      let newTrips = json.trips.map(parseTrip(fullDate, from, to));

      const tripExists = item => {
        for (var i = 0; i < currentTrips.length; i++) {
          if (currentTrips[i].id == item.id) {
            return true;
          }
        }
        return false;
      };

      return {
        newTrips: shouldMerge
          ? [
              ...currentTrips,
              ...newTrips.filter(t => !tripExists(t))
            ].sort((a, b) =>
              moment(a.dateTime, fullDate).diff(
                moment(b.dateTime, fullDate),
                'minutes'
              )
            )
          : newTrips,
        json
      };
    });
};
