import React from 'react';
import PropTypes from 'prop-types';

import { IMAGE_SIZE, IMAGE_QUERY } from 'constants/image-type';

const PictureBlock = ({
  title,
  context,
  hideContext,
  imageUrl,
  imageX,
  imageY,
  imageAlt,
  linkUrl,
  linkText,
  isInEditMode
}) => {
  let imageSmall = `${imageUrl}?${IMAGE_QUERY[IMAGE_SIZE.SMALL]}&${
    IMAGE_QUERY.transform
  }`;

  return (
    <div className="picture-block">
      <div className="picture-background">
        <div
          data-epi-property-name="ImageLink"
          className="fluid-image"
          style={{
            backgroundImage: `url(${imageSmall})`,
            backgroundPosition: `${imageX}% ${imageY}%`
          }}
        >
          <img
            src={`${imageSmall}`}
            alt={imageAlt}
            data-image-width=""
            data-small={imageUrl}
            data-large={imageUrl}
          />
        </div>
      </div>

      <div className="picture-container">
        <div className="picture-skew-container wow fadeInLeft">
          <div className="picture-skew" />
        </div>

        <div className="picture-content wow fadeInDown" data-wow-delay="0.3s">
          <div className="row">
            <div className="col-xs-12">
              <div className="mobile-pointer" />
              {(isInEditMode || context) && !hideContext && (
                <p
                  data-epi-property-name="PagePublishedDate"
                  className="contextual-text"
                >
                  {context}
                </p>
              )}
              <h4 data-epi-property-name="Title">{title}</h4>
              <a data-epi-property-name="Link" className="cta" href={linkUrl}>
                {linkText}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PictureBlock.propTypes = {
  title: PropTypes.string.isRequired,
  context: PropTypes.string,
  hideContext: PropTypes.bool,
  imageUrl: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageX: PropTypes.number,
  imageY: PropTypes.number,
  linkUrl: PropTypes.string.isRequired,
  linkText: PropTypes.string.isRequired,
  isInEditMode: PropTypes.bool
};

PictureBlock.propTypesMeta = 'exclude';

PictureBlock.defaultProps = {
  isInEditMode: false
};

export default PictureBlock;
