import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import IconSearch from 'assets/icons/search.svg';

const QuickSearch = ({
  searchInputLabel,
  placeholder,
  searchPageUrl,
  searchButtonText
}) => {
  return (
    <div className={cn('quick-search')}>
      <form className="quick-search__form" action={searchPageUrl} method="GET">
        <div className="quick-search__container">
          <input
            className="quick-search__input"
            type="text"
            name="q"
            placeholder={placeholder}
            aria-label={searchInputLabel}
          />
          <button
            className="quick-search__search-button"
            aria-label={searchButtonText}
          >
            <IconSearch className="quick-search__search-button-icon" />
          </button>
        </div>
      </form>
    </div>
  );
};

QuickSearch.propTypes = {
  searchInputLabel: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  searchPageUrl: PropTypes.string.isRequired,
  searchButtonText: PropTypes.string.isRequired
};

QuickSearch.propTypesMeta = {};

export default QuickSearch;
