import React from 'react';
import PropTypes from 'prop-types';

import IconChat from 'assets/icons/chat-icon.svg';

const ChatButton = ({ label }) => (
  <>
    <button
      className="btn btn-stripped chatbutton chatstarter"
      name="startChat"
    >
      <span>{label}</span>
      <IconChat className="icon icon-chat" />
    </button>
  </>
);

ChatButton.propTypes = {
  label: PropTypes.string
};

export default ChatButton;
