'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _month_dropdown_options = require('./month_dropdown_options');

var _month_dropdown_options2 = _interopRequireDefault(_month_dropdown_options);

var _reactOnclickoutside = require('react-onclickoutside');

var _reactOnclickoutside2 = _interopRequireDefault(_reactOnclickoutside);

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _range = require('lodash/range');

var _range2 = _interopRequireDefault(_range);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var WrappedMonthDropdownOptions = (0, _reactOnclickoutside2.default)(
  _month_dropdown_options2.default
);

class MonthDropdown extends React.Component {
  static propTypes = {
    dropdownMode: PropTypes.oneOf(['scroll', 'select']).isRequired,
    locale: PropTypes.string,
    month: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
  };

  state = {
    dropdownVisible: false
  };

  renderSelectOptions(monthNames) {
    return monthNames.map(function(M, i) {
      return _react2.default.createElement('option', { key: i, value: i }, M);
    });
  }

  renderSelectMode(monthNames) {
    var _this = this;

    return _react2.default.createElement(
      'select',
      {
        value: this.props.month,
        className: 'react-datepicker__month-select',
        onChange: function onChange(e) {
          return _this.onChange(e.target.value);
        }
      },
      this.renderSelectOptions(monthNames)
    );
  }

  renderReadView(visible, monthNames) {
    return _react2.default.createElement(
      'div',
      {
        key: 'read',
        style: { visibility: visible ? 'visible' : 'hidden' },
        className: 'react-datepicker__month-read-view',
        onClick: this.toggleDropdown
      },
      _react2.default.createElement(
        'span',
        { className: 'react-datepicker__month-read-view--selected-month' },
        monthNames[this.props.month]
      ),
      _react2.default.createElement('span', {
        className: 'react-datepicker__month-read-view--down-arrow'
      })
    );
  }

  renderDropdown(monthNames) {
    return _react2.default.createElement(WrappedMonthDropdownOptions, {
      key: 'dropdown',
      ref: 'options',
      month: this.props.month,
      monthNames: monthNames,
      onChange: this.onChange,
      onCancel: this.toggleDropdown
    });
  }

  renderScrollMode(monthNames) {
    var dropdownVisible = this.state.dropdownVisible;

    var result = [this.renderReadView(!dropdownVisible, monthNames)];
    if (dropdownVisible) {
      result.unshift(this.renderDropdown(monthNames));
    }
    return result;
  }

  onChange(month) {
    this.toggleDropdown();
    if (month !== this.props.month) {
      this.props.onChange(month);
    }
  }

  toggleDropdown() {
    this.setState({
      dropdownVisible: !this.state.dropdownVisible
    });
  }

  render() {
    var localeData = _moment2.default.localeData(this.props.locale);
    var monthNames = (0, _range2.default)(0, 12).map(function(M) {
      return localeData.months((0, _moment2.default)({ M: M }));
    });

    var renderedDropdown = void 0;
    switch (this.props.dropdownMode) {
      case 'scroll':
        renderedDropdown = this.renderScrollMode(monthNames);
        break;
      case 'select':
        renderedDropdown = this.renderSelectMode(monthNames);
        break;
    }

    return _react2.default.createElement(
      'div',
      {
        className:
          'react-datepicker__month-dropdown-container react-datepicker__month-dropdown-container--' +
          this.props.dropdownMode
      },
      renderedDropdown
    );
  }
}

export default MonthDropdown;
