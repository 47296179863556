/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/link';
import cn from 'classnames';

const MenuLink = ({
  url,
  text,
  openInNewTab,
  isActive,
  isSecondary = false,
  isSecondLevel = false,
  className
}) => {
  return (
    <Link
      url={url}
      text={text}
      openInNewTab={openInNewTab}
      className={cn('menu-link', className, {
        'menu-link_active': isActive,
        'menu-link_secondary': isSecondary,
        'menu-link_second-level': isSecondLevel
      })}
    />
  );
};

MenuLink.propTypes = Object.assign(Link.propTypes, {
  isActive: PropTypes.bool,
  isSecondLevel: PropTypes.bool,
  isSecondary: PropTypes.bool
});

MenuLink.propTypesMeta = {
  isSecondLevel: 'exclude',
  isSecondary: 'exclude',
  className: 'exclude'
};

export default MenuLink;
