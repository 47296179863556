const modifyStopLocationData = o => {
  return { ...o, value: o.extId, label: o.name };
};

const splitLocationObject = json => {
  const osl = [json.to].map(modifyStopLocationData)[0];
  const options = json.from.map(modifyStopLocationData);

  return { options: [...options, osl], OSL: osl };
};

const setStopLocations = (stops, currentLocationId) => {
  let locationResult = stops.options[0];

  const osloAirportId = stops.OSL.extId;

  if (currentLocationId) {
    // find stops.options with matching value
    locationResult = stops.options.filter(o => {
      return (
        o.value === currentLocationId && currentLocationId !== osloAirportId
      );
    });
    locationResult = locationResult.length
      ? locationResult[0]
      : stops.options[0];
  }

  const locationState = {
    location: locationResult
  };

  return {
    ...locationState,
    stops: stops.options,
    OSL: stops.OSL,
    isLoadingStops: false
  };
};

export { splitLocationObject, setStopLocations };
