/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import Collapse from 'react-tiny-collapse';
import MenuLink from 'components/menu-link';
import cn from 'classnames';
import IconArrow from 'assets/icons/arrow-icon.svg';

const MenuItem = ({
  url,
  text,
  openInNewTab,
  className,
  isActive,
  childItems,
  isSecondary = false,
  forceUpdateIndex
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [hasActiveChild, setHasActiveChild] = React.useState(false);
  const toggle = e => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };
  const showChildIfActive = () => {
    if (childItems) {
      const activeItems = childItems.filter(item => item.isActive);
      setIsOpen(activeItems.length > 0);
      setHasActiveChild(activeItems.length > 0);
    }
  };

  React.useEffect(() => {
    showChildIfActive();
  }, [forceUpdateIndex]);

  return (
    <div className={cn('menu-item', className)}>
      {childItems && childItems.length > 0 ? (
        <React.Fragment>
          <button
            onClick={e => toggle(e)}
            className={cn('menu-item__toggle-button', {
              'menu-item__toggle-button_opened': isOpen,
              'menu-item__toggle-button_active': hasActiveChild
            })}
            aria-expanded={childItems ? isOpen : null}
          >
            <span className="menu-item__toggle-button-text">{text}</span>
            <IconArrow className="menu-item__toggle-button-icon" />
          </button>
          <Collapse isOpen={isOpen}>
            <ul className="menu-item__second-level-items">
              {childItems.map((item, i) => (
                <li key={i} className="menu-item__second-level-item">
                  <MenuLink
                    {...item}
                    isSecondLevel={true}
                    isSecondary={isSecondary}
                  />
                </li>
              ))}
            </ul>
          </Collapse>
        </React.Fragment>
      ) : (
        <MenuLink
          url={url}
          text={text}
          openInNewTab={openInNewTab}
          isActive={isActive}
          isSecondary={isSecondary}
        />
      )}
    </div>
  );
};

MenuItem.propTypes = Object.assign(MenuLink.propTypes, {
  className: PropTypes.string,
  childItems: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes)),
  isSecondary: PropTypes.bool,
  forceUpdateIndex: PropTypes.number
});

MenuItem.propTypesMeta = {
  className: 'exclude',
  isSecondary: 'exclude',
  forceUpdateIndex: 'exclude'
};

export default MenuItem;
