export const BLOCK_TYPE = {
  WHITE: 0,
  DARK_GREY: 1,
  LIGHT_GREY: 2,
  BROWN: 3
};

export const BLOCK_TYPE_VALUES = [
  'type-white',
  'type-limestone',
  'type-grey',
  'type-beige'
];
