import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Select from 'react-select';

import Switch from 'assets/icons/FT_arrows_switch.svg';
import Expand from 'assets/icons/FT_arrow_small_up.svg';
import IconCheck from 'assets/icons/FT_Checkmark.svg';

import {
  documentIsDefined,
  TravelInputChangeType
} from 'components/travel-planner/constants';
import { Lang } from 'components/travel-planner/models';

class TravelInput extends Component {
  static propTypes = {
    airportStation: PropTypes.string.isRequired,
    station: PropTypes.string,
    isLoading: PropTypes.bool,
    lang: PropTypes.shape(Lang.propTypes).isRequired,
    onChange: PropTypes.func.isRequired,
    stops: PropTypes.arrayOf(PropTypes.shape({})),
    towardsAirport: PropTypes.bool.isRequired
  };

  static propTypesMeta = 'exclude';

  checkStatePropsChanged = prevProps => {
    return (
      prevProps.station != this.props.station ||
      prevProps.airportStation != this.props.airportStation ||
      prevProps.towardsAirport != this.props.towardsAirport
    );
  };

  buildStateFromProps = () => {
    return {
      from: this.props.towardsAirport
        ? this.props.station
        : this.props.airportStation,
      to: this.props.towardsAirport
        ? this.props.airportStation
        : this.props.station,
      towardsAirport: this.props.towardsAirport
    };
  };

  state = this.buildStateFromProps();

  componentDidUpdate(prevProps, prevState) {
    if (this.checkStatePropsChanged(prevProps)) {
      this.setState(this.buildStateFromProps());
    }

    if (prevState.from != this.state.from || prevState.to != this.state.to) {
      const changeType =
        prevState.from != this.state.from && prevState.to != this.state.to
          ? TravelInputChangeType.DirectionChanged
          : prevState.from != this.state.from
          ? TravelInputChangeType.FromUpdated
          : TravelInputChangeType.ToUpdated;
      const stationId = this.state.towardsAirport
        ? this.state.from
        : this.state.to;
      this.props.onChange({
        changeType: changeType,
        station: this.props.stops.find(x => x.value === stationId) || {},
        direction: this.state.towardsAirport
      });
    }
  }

  arrowRenderer = () => {
    return (
      <Expand
        focusable="false"
        role="img"
        aria-label={this.props.lang && this.props.lang.expand}
      />
    );
  };

  optionRenderer = direction => {
    return item => {
      return item.value == this.state[direction] ? (
        <span>
          <IconCheck focusable="false" role="img" />
          {item.name}
        </span>
      ) : (
        item.name
      );
    };
  };

  onOpenDropdown = () => {
    documentIsDefined && document.body.classList.add('no-scroll-mobile');
  };

  onCloseDropdown = () => {
    documentIsDefined && document.body.classList.remove('no-scroll-mobile');
  };

  onChangeLocationFrom = e => {
    let fromLocation = e.value;

    if (fromLocation === this.state.from) return;

    if (fromLocation === this.state.to) {
      this.changeDirection();
    } else if (this.state.from === this.props.airportStation) {
      this.setState({
        to: this.props.airportStation,
        from: fromLocation,
        towardsAirport: true
      });
    } else {
      this.setState({
        from: fromLocation
      });
    }
  };

  onChangeLocationTo = e => {
    let toLocation = e.value;

    if (toLocation === this.state.to) return;

    if (toLocation === this.state.from) {
      this.changeDirection();
    } else if (this.state.to === this.props.airportStation) {
      this.setState({
        to: toLocation,
        from: this.props.airportStation,
        towardsAirport: false
      });
    } else {
      this.setState({
        to: toLocation
      });
    }
  };

  onChangeDirection = () => {
    this.changeDirection();
  };

  onKeyDownDirection = e => {
    let code = e.which;
    if (code === 13 || code === 32) {
      e.preventDefault();
      this.changeDirection();
    }
  };

  changeDirection = () => {
    this.toField.focusOption(this.props.stops[this.state.from]);
    this.fromField.focusOption(this.props.stops[this.state.to]);

    this.setState(state => ({
      from: state.to,
      to: state.from,
      towardsAirport: !this.state.towardsAirport
    }));
  };

  render() {
    const { lang, stops, isLoading } = this.props;

    return (
      <div className="row travel-inputs">
        <div
          className="travel-input travel-input-from"
          aria-label={this.props.lang && this.props.lang.from}
          role="listbox"
        >
          <Select
            ref={node => (this.fromField = node)}
            name="form-field-from"
            autosize={false}
            clearable={false}
            searchable={false}
            scrollMenuIntoView={false}
            value={this.state.from}
            valueRenderer={value => lang.from + ' ' + value.name}
            options={stops}
            isLoading={isLoading}
            onOpen={this.onOpenDropdown}
            onClose={this.onCloseDropdown}
            onChange={this.onChangeLocationFrom}
            arrowRenderer={this.arrowRenderer}
            optionRenderer={this.optionRenderer('from')}
            aria-label={this.props.lang && this.props.lang.from} //added aria-label to fix WCAG issue https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-label
          />
        </div>
        <div className="travel-input travel-input-center">
          <button
            className="button-switch"
            onClick={this.onChangeDirection}
            onKeyDown={this.onKeyDownDirection}
            title={lang.swap}
            tabIndex="0"
            aria-label={lang.swap}
          >
            <Switch focusable="false" />
          </button>
        </div>
        <div
          className="travel-input travel-input-to"
          aria-label={this.props.lang && this.props.lang.to}
          role="listbox"
        >
          <Select
            ref={node => (this.toField = node)}
            name="form-field-to"
            autosize={false}
            clearable={false}
            searchable={false}
            scrollMenuIntoView={false}
            value={this.state.to}
            valueRenderer={value => lang.to + ' ' + value.name}
            options={stops}
            isLoading={isLoading}
            onOpen={this.onOpenDropdown}
            onClose={this.onCloseDropdown}
            onChange={this.onChangeLocationTo}
            arrowRenderer={this.arrowRenderer}
            optionRenderer={this.optionRenderer('to')}
            aria-label={this.props.lang && this.props.lang.to}
          />
        </div>
      </div>
    );
  }
}

export default TravelInput;
