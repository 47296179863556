import moment from 'moment';

/**
 * Converts api data
 * Generated an id for each trip if none are specified
 */
export default (fullDate, from, to) => trip => {
  const format = date => date.format(fullDate);

  const [firstLeg] = trip.legs;
  const { origin, destination } = firstLeg;

  const time = moment(origin.time, 'H:m:s');
  const dateTime = moment(origin.date)
    .hour(time.hour())
    .minute(time.minute());

  const arrivalTime = moment(destination.time, 'H:m:s');
  const arrivalDateTime = moment(destination.date)
    .hour(arrivalTime.hour())
    .minute(arrivalTime.minute());

  const id =
    firstLeg.tripId ||
    `${format(dateTime)}_${from}_${to}_${format(arrivalDateTime)}`;

  // get validity (deviations / cancelled)
  const valid = typeof trip.valid === 'undefined' ? true : trip.valid;
  const alternative =
    typeof trip.alternative === 'undefined' ? false : trip.alternative;

  return {
    id: id,
    key: 'k' + id,
    dateTime: format(dateTime),
    date: origin.date,
    departure: origin.time,
    track: origin.rtTrack,
    arrival: destination.time,
    trackArrival: destination.rtTrack,
    valid,
    alternative,
    description: trip.description || '',
    legs: trip.legs,
    stops: firstLeg.stops
  };
};
