export const documentIsDefined = typeof document !== 'undefined';
export const windowIsDefined = typeof window !== 'undefined';

export const isLocalHost =
  windowIsDefined &&
  window.location.host.indexOf('localhost') >= 0 &&
  window.location.host.indexOf('localhost:52130') < 0; // BE localhost.

export const fullDate = 'YYYY-MM-DD_HH-mm';
export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mm';
export const parseTimeFormat = 'H:m:s';
export const shortDateFormat = 'Do MMMM';
export const shortDateFormatEn = 'MMMM Do';
export const timeZone = 'Europe/Oslo';

export const Direction = { TO: 1, FROM: 2 };
export const TravelInputChangeType = {
  FromUpdated: 1,
  ToUpdated: 2,
  DirectionChanged: 3
};
export const View = { Single: 0, List: 1, Details: 2 };
