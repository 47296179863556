import React from 'react';
import PropTypes from 'prop-types';

const ContactBlock = ({
  title,
  name,
  position,
  department,
  phoneNumber,
  email,
  comment,
  isInEditMode
}) => {
  const isDepartmentAndPositionSet =
    position !== null &&
    department !== null &&
    position.length > 0 &&
    department.length > 0;
  return (
    <div className="contact-block">
      <div className="col">
        <h4 data-epi-property-name="Title">{title}</h4>
        <div>
          {(isInEditMode || name !== '') && (
            <div className="highlight-text">
              <span data-epi-property-name="Name">{name}</span>
            </div>
          )}
          <div className="highlight-text">
            {(isInEditMode || position !== '') && (
              <span data-epi-property-name="Position">{position}</span>
            )}
            {isDepartmentAndPositionSet && <span> / </span>}
            {(isInEditMode || department !== '') && (
              <span data-epi-property-name="Department">{department}</span>
            )}
          </div>
          {(isInEditMode || phoneNumber !== '') && (
            <div className="highlight-text">
              <a
                href={`tel:${phoneNumber}`}
                className="cta contact-phone"
                data-epi-property-name="PhoneNumber"
              >
                {phoneNumber}
              </a>
            </div>
          )}
          {(isInEditMode || email !== '') && (
            <div className="highlight-text">
              <a
                href={`mailto:${email}`}
                className="cta contact-email"
                data-epi-property-name="Email"
              >
                {email}
              </a>
            </div>
          )}
          {(isInEditMode || comment !== '') && (
            <div className="highlight-text">
              <span
                className="contact-comment"
                data-epi-property-name="Comment"
              >
                {comment}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ContactBlock.defaultProps = {
  title: '',
  name: '',
  position: '',
  department: '',
  contactType: '',
  phoneNumber: '',
  email: '',
  comment: '',
  isInEditMode: false
};

ContactBlock.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  department: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  comment: PropTypes.string,
  isInEditMode: PropTypes.bool
};

ContactBlock.propTypesMeta = 'exclude';

export default ContactBlock;
