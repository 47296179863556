/* eslint-disable react/prop-types */
'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _extends =
  Object.assign ||
  function(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];
      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }
    return target;
  };

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _date_utils = require('./date_utils');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _objectWithoutProperties(obj, keys) {
  var target = {};
  for (var i in obj) {
    if (keys.indexOf(i) >= 0) continue;
    if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
    target[i] = obj[i];
  }
  return target;
}

class DateInput extends React.Component {
  static propTypes = {
    date: PropTypes.object,
    dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    locale: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onChangeDate: PropTypes.func
  };

  static defaultProps = {
    dateFormat: 'L'
  };

  state = { value: this.safeDateFormat(this.props) };

  componentWillReceiveProps(newProps) {
    if (
      !(0, _date_utils.isSameDay)(newProps.date, this.props.date) ||
      newProps.locale !== this.props.locale ||
      newProps.dateFormat !== this.props.dateFormat
    ) {
      this.setState({
        value: this.safeDateFormat(newProps)
      });
    }
  }

  handleChange(event) {
    if (this.props.onChange) {
      this.props.onChange(event);
    }
    if (!event.defaultPrevented) {
      this.handleChangeDate(event.target.value);
    }
  }

  handleChangeDate(value) {
    if (this.props.onChangeDate) {
      var date = (0, _moment2.default)(
        value,
        this.props.dateFormat,
        this.props.locale || _moment2.default.locale(),
        true
      );
      if (date.isValid() && !(0, _date_utils.isDayDisabled)(date, this.props)) {
        this.props.onChangeDate(date);
      } else if (value === '') {
        this.props.onChangeDate(null);
      }
    }
    this.setState({ value: value });
  }

  safeDateFormat(props) {
    return (
      (props.date &&
        props.date
          .clone()
          .locale(props.locale || _moment2.default.locale())
          .format(
            Array.isArray(props.dateFormat)
              ? props.dateFormat[0]
              : props.dateFormat
          )) ||
      ''
    );
  }

  handleBlur(event) {
    this.setState({
      value: this.safeDateFormat(this.props)
    });
    if (this.props.onBlur) {
      this.props.onBlur(event);
    }
  }

  focus() {
    this.refs.input.focus();
  }

  render() {
    var _props = this.props,
      customInput = _props.customInput,
      rest = _objectWithoutProperties(_props, [
        'customInput',
        'date',
        'locale',
        'minDate',
        'maxDate',
        'excludeDates',
        'includeDates',
        'filterDate',
        'dateFormat',
        'onChangeDate'
      ]); // eslint-disable-line no-unused-vars

    if (customInput) {
      return _react2.default.cloneElement(
        customInput,
        _extends({}, rest, {
          ref: 'input',
          value: this.state.value,
          onBlur: this.handleBlur,
          onChange: this.handleChange
        })
      );
    }

    return _react2.default.createElement(
      'input',
      _extends(
        {
          ref: 'input',
          type: 'text'
        },
        rest,
        {
          value: this.state.value,
          onBlur: this.handleBlur,
          onChange: this.handleChange
        }
      )
    );
  }
}

export default DateInput;
