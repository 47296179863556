import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';
import Spinner from 'components/spinner';

const onEnterKeyDown = callback => e => {
  if (e.which === 13) {
    e.preventDefault();
    callback();
  }
};

const BottomBar = ({
  disabled,
  disabledText,
  isLoading,
  onClick,
  onEnterPress,
  text
}) => (
  <div
    className={cn('travel-results-button-container', { loading: isLoading })}
  >
    <Spinner className="travel-results-loader" />
    <div className="buttons">
      {disabled ? (
        <span className="travel-results-link">{disabledText}</span>
      ) : (
        <button
          className="travel-results-link"
          onKeyDown={onEnterKeyDown(onEnterPress)}
          onClick={onClick}
        >
          {text}
        </button>
      )}
    </div>
  </div>
);

BottomBar.propTypes = {
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
  onEnterPress: PropTypes.func,
  text: PropTypes.string
};

BottomBar.propTypesMeta = 'exclude';

export default BottomBar;
