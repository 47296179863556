import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import ResponsiveImage from 'components/responsive-image';

const Logo = ({ className, url, image, imageClass }) => {
  return (
    <a className={cn('logo', className)} href={url}>
      {image && (
        <ResponsiveImage {...image} className={cn('logo__image', imageClass)} />
      )}
    </a>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  imageClass: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.exact(ResponsiveImage.propTypes)
};

Logo.propTypesMeta = {
  className: 'exclude',
  imageClass: 'exclude'
};

export default Logo;
