/* eslint-disable @creuna/prop-types-csharp/all */
'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _date_input = require('./date_input');

var _date_input2 = _interopRequireDefault(_date_input);

var _calendar = require('./calendar');

var _calendar2 = _interopRequireDefault(_calendar);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _defer = require('lodash/defer');

var _defer2 = _interopRequireDefault(_defer);

var _tether_component = require('./tether_component');

var _tether_component2 = _interopRequireDefault(_tether_component);

var _classnames2 = require('classnames');

var _classnames3 = _interopRequireDefault(_classnames2);

var _date_utils = require('./date_utils');

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _reactOnclickoutside = require('react-onclickoutside');

var _reactOnclickoutside2 = _interopRequireDefault(_reactOnclickoutside);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}

var outsideClickIgnoreClass = 'react-datepicker-ignore-onclickoutside';
var WrappedCalendar = (0, _reactOnclickoutside2.default)(_calendar2.default);

/**
 * General datepicker component.
 */

class DatePicker extends React.Component {
  static propTypes = {
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    className: PropTypes.string,
    customInput: PropTypes.element,
    dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    dateFormatCalendar: PropTypes.string,
    disabled: PropTypes.bool,
    dropdownMode: PropTypes.oneOf(['scroll', 'select']).isRequired,
    endDate: PropTypes.object,
    excludeDates: PropTypes.array,
    filterDate: PropTypes.func,
    fixedHeight: PropTypes.bool,
    highlightDates: PropTypes.array,
    id: PropTypes.string,
    includeDates: PropTypes.array,
    inline: PropTypes.bool,
    isClearable: PropTypes.bool,
    locale: PropTypes.string,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    monthsShown: PropTypes.number,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    onSelect: PropTypes.func,
    onClickOutside: PropTypes.func,
    onMonthChange: PropTypes.func,
    openToDate: PropTypes.object,
    peekNextMonth: PropTypes.bool,
    placeholderText: PropTypes.string,
    popoverAttachment: PropTypes.string,
    popoverTargetAttachment: PropTypes.string,
    popoverTargetOffset: PropTypes.string,
    readOnly: PropTypes.bool,
    renderCalendarTo: PropTypes.any,
    required: PropTypes.bool,
    scrollableYearDropdown: PropTypes.bool,
    selected: PropTypes.object,
    selectsEnd: PropTypes.bool,
    selectsStart: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    showWeekNumbers: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    forceShowMonthNavigation: PropTypes.bool,
    startDate: PropTypes.object,
    tabIndex: PropTypes.number,
    tetherConstraints: PropTypes.array,
    title: PropTypes.string,
    todayButton: PropTypes.string,
    utcOffset: PropTypes.number,
    navigationNextMonthContent: PropTypes.element,
    navigationPrevMonthContent: PropTypes.element
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {
    dateFormatCalendar: 'MMMM YYYY',
    onChange: function onChange() {},

    disabled: false,
    dropdownMode: 'scroll',
    onFocus: function onFocus() {},
    onBlur: function onBlur() {},
    onMonthChange: function onMonthChange() {},

    popoverAttachment: 'top left',
    popoverTargetAttachment: 'bottom left',
    popoverTargetOffset: '10px 0',
    tetherConstraints: [
      {
        to: 'window',
        attachment: 'together'
      }
    ],
    utcOffset: _moment2.default.utc().utcOffset(),
    monthsShown: 1
  };

  state = {
    open: false,
    preventFocus: false
  };

  setFocus = () => {
    this.refs.input.focus();
  };

  setOpen = open => {
    this.setState({ open: open });
  };

  handleFocus = event => {
    if (!this.state.preventFocus) {
      this.props.onFocus(event);
      this.setOpen(true);
    }
  };

  cancelFocusInput = () => {
    clearTimeout(this.inputFocusTimeout);
    this.inputFocusTimeout = null;
  };

  deferFocusInput = () => {
    var _this = this;

    this.cancelFocusInput();
    this.inputFocusTimeout = (0, _defer2.default)(() => {
      return _this.setFocus();
    });
  };

  handleDropdownFocus = () => {
    this.cancelFocusInput();
  };

  handleBlur = event => {
    if (this.state.open) {
      this.deferFocusInput();
    } else {
      this.props.onBlur(event);
    }
  };

  handleCalendarClickOutside = event => {
    this.setOpen(false);
    if (typeof this.props.onClickOutside === 'function')
      this.props.onClickOutside(event);
  };

  handleSelect = (date, event) => {
    var _this2 = this;

    // Preventing onFocus event to fix issue
    // https://github.com/Hacker0x01/react-datepicker/issues/628
    this.setState({ preventFocus: true }, () => {
      return setTimeout(() => {
        return _this2.setState({ preventFocus: false });
      }, 50);
    });
    this.setSelected(date, event);
    this.setOpen(false);
    if (typeof this.props.onSelect === 'function')
      this.props.onSelect(date, event);
  };

  setSelected = (date, event) => {
    var changedDate = date;

    if (!(0, _date_utils.isSameDay)(this.props.selected, changedDate)) {
      if (this.props.selected && changedDate != null) {
        changedDate = (0, _moment2.default)(changedDate).set({
          hour: this.props.selected.hour(),
          minute: this.props.selected.minute(),
          second: this.props.selected.second()
        });
      }

      this.props.onChange(changedDate, event);
    }
  };

  onInputClick = () => {
    if (!this.props.disabled) {
      this.setOpen(true);
    }
  };

  onInputKeyDown = event => {
    var copy = this.props.selected
      ? (0, _moment2.default)(this.props.selected)
      : (0, _moment2.default)();
    if (event.key === 'Enter' || event.key === 'Escape') {
      event.preventDefault();
      this.setOpen(false);
    } else if (event.key === 'Tab') {
      this.setOpen(false);
    } else if (event.key === 'ArrowLeft') {
      event.preventDefault();
      this.setSelected(copy.subtract(1, 'days'));
    } else if (event.key === 'ArrowRight') {
      event.preventDefault();
      this.setSelected(copy.add(1, 'days'));
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.setSelected(copy.subtract(1, 'weeks'));
    } else if (event.key === 'ArrowDown') {
      event.preventDefault();
      this.setSelected(copy.add(1, 'weeks'));
    } else if (event.key === 'PageUp') {
      event.preventDefault();
      this.setSelected(copy.subtract(1, 'months'));
    } else if (event.key === 'PageDown') {
      event.preventDefault();
      this.setSelected(copy.add(1, 'months'));
    } else if (event.key === 'Home') {
      event.preventDefault();
      this.setSelected(copy.subtract(1, 'years'));
    } else if (event.key === 'End') {
      event.preventDefault();
      this.setSelected(copy.add(1, 'years'));
    }
  };

  onClearClick = event => {
    event.preventDefault();
    this.props.onChange(null, event);
  };

  renderCalendar = () => {
    if (!this.props.inline && (!this.state.open || this.props.disabled)) {
      return null;
    }
    return _react2.default.createElement(WrappedCalendar, {
      ref: 'calendar',
      locale: this.props.locale,
      dateFormat: this.props.dateFormatCalendar,
      dropdownMode: this.props.dropdownMode,
      selected: this.props.selected,
      onSelect: this.handleSelect,
      openToDate: this.props.openToDate,
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
      selectsStart: this.props.selectsStart,
      selectsEnd: this.props.selectsEnd,
      startDate: this.props.startDate,
      endDate: this.props.endDate,
      excludeDates: this.props.excludeDates,
      filterDate: this.props.filterDate,
      onClickOutside: this.handleCalendarClickOutside,
      highlightDates: this.props.highlightDates,
      includeDates: this.props.includeDates,
      peekNextMonth: this.props.peekNextMonth,
      showMonthDropdown: this.props.showMonthDropdown,
      showWeekNumbers: this.props.showWeekNumbers,
      showYearDropdown: this.props.showYearDropdown,
      forceShowMonthNavigation: this.props.forceShowMonthNavigation,
      scrollableYearDropdown: this.props.scrollableYearDropdown,
      todayButton: this.props.todayButton,
      utcOffset: this.props.utcOffset,
      outsideClickIgnoreClass: outsideClickIgnoreClass,
      fixedHeight: this.props.fixedHeight,
      monthsShown: this.props.monthsShown,
      onDropdownFocus: this.handleDropdownFocus,
      onMonthChange: this.props.onMonthChange,
      navigationNextMonthContent: this.props.navigationNextMonthContent,
      navigationPrevMonthContent: this.props.navigationPrevMonthContent
    });
  };

  renderDateInput = () => {
    var className = (0, _classnames3.default)(
      this.props.className,
      _defineProperty({}, outsideClickIgnoreClass, this.state.open)
    );
    return _react2.default.createElement(_date_input2.default, {
      ref: 'input',
      id: this.props.id,
      name: this.props.name,
      autoFocus: this.props.autoFocus,
      date: this.props.selected,
      locale: this.props.locale,
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
      excludeDates: this.props.excludeDates,
      includeDates: this.props.includeDates,
      filterDate: this.props.filterDate,
      dateFormat: this.props.dateFormat,
      onFocus: this.handleFocus,
      onBlur: this.handleBlur,
      onClick: this.onInputClick,
      onKeyDown: this.onInputKeyDown,
      onChangeDate: this.setSelected,
      placeholder: this.props.placeholderText,
      disabled: this.props.disabled,
      autoComplete: this.props.autoComplete,
      className: className,
      title: this.props.title,
      readOnly: this.props.readOnly,
      required: this.props.required,
      tabIndex: this.props.tabIndex,
      customInput: this.props.customInput
    });
  };

  renderClearButton = () => {
    if (this.props.isClearable && this.props.selected != null) {
      return _react2.default.createElement('a', {
        className: 'react-datepicker__close-icon',
        href: '#',
        onClick: this.onClearClick
      });
    } else {
      return null;
    }
  };

  render() {
    var calendar = this.renderCalendar();

    if (this.props.inline) {
      return calendar;
    } else {
      return _react2.default.createElement(
        _tether_component2.default,
        {
          classPrefix: 'react-datepicker__tether',
          attachment: this.props.popoverAttachment,
          targetAttachment: this.props.popoverTargetAttachment,
          targetOffset: this.props.popoverTargetOffset,
          renderElementTo: this.props.renderCalendarTo,
          constraints: this.props.tetherConstraints
        },
        _react2.default.createElement(
          'div',
          { className: 'react-datepicker__input-container' },
          this.renderDateInput(),
          this.renderClearButton()
        ),
        calendar
      );
    }
  }
}

export default DatePicker;
