/* eslint-disable @creuna/prop-types-csharp/all */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';

import IconCal from 'assets/icons/FT__kal_small.svg';
import BottomBar from './bottom-bar';
import TravelResult from 'components/travel-result/travel-result';
import TravelResultDetails from 'components/travel-result-details/travel-result-details';

import { computeDisplayDate } from './compute-display-date';
import fetchTrips from './fetch-trips';
import NoDataError from 'utils/no-data-error';
import parseMouseWheelEvent from 'utils/parse-mouse-wheel-event';
import { windowIsDefined } from 'components/travel-planner/constants';
import Spinner from 'components/spinner';

import {
  documentIsDefined,
  isLocalHost,
  fullDate,
  dateFormat,
  timeFormat,
  View
} from 'components/travel-planner/constants';

class TravelResults extends Component {
  static propTypes = {
    setViewingMode: PropTypes.func.isRequired,
    lang: PropTypes.object.isRequired,
    locale: PropTypes.string,
    date: PropTypes.object.isRequired,
    viewMode: PropTypes.number.isRequired,
    handleError: PropTypes.func.isRequired,
    exception: PropTypes.bool,
    errorMessage: PropTypes.string,
    maxCountdown: PropTypes.number,
    from: PropTypes.string,
    showDatePicker: PropTypes.func.isRequired,
    pastTripsAllowed: PropTypes.bool.isRequired,
    to: PropTypes.string,
    trip: PropTypes.object,
    direction: PropTypes.number
  };

  static propTypesMeta = 'exclude';

  static defaultProps = {};

  constructor(props) {
    super(props);

    this.dom = null;
    this.resultsView = null;
    this.resultsViewContainer = null;

    let now = moment();
    this.time = props.date.hour(now.hour()).minute(now.minute());

    this.state = {
      diffTime: 0,
      selected: -1,
      items: [],
      tick: 0,
      isLoading: false,
      isLoadingPastResults: false,
      error: props.exception ? true : false,
      fetchTripsAsyncMarker: null,
      lastSearch: {
        from: null,
        to: null,
        date: null
      }
    };
  }

  componentWillMount() {
    this.getTrips();
  }

  componentDidMount() {
    document.addEventListener('scroll', this.onScroll, false);
    window.addEventListener('resize', this.onResize, false);

    this.scrollTo(this.props);

    this.timer = setInterval(() => {
      this.setState(state => ({ tick: state.tick + 1 }));
    }, 1000);
  }

  componentDidUpdate(prevProps) {
    if (
      (prevProps.viewMode !== this.props.viewMode &&
        (this.props.viewMode === View.List ||
          this.props.viewMode === View.Details)) ||
      this.props.date.format(dateFormat) !== prevProps.date.format(dateFormat)
    ) {
      this.scrollTo(this.props);
    } else if (
      this.props.viewMode === View.Single &&
      prevProps.viewMode !== View.Single
    ) {
      this.onResize();
    }
    this.onScroll();
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
    clearInterval(this.timer);
  }

  getLanguage() {
    if (typeof window === 'undefined')
      return this.props.locale.startsWith('en') ? 'en' : 'no';
    var path = window.location.pathname;
    return path === '/en' || path.startsWith('/en/') ? 'en' : 'no';
  }

  componentWillReceiveProps(nextProps) {
    // if there's an exception - reset time
    if (nextProps.exception) {
      let now = moment();
      this.time = nextProps.date.hour(now.hour()).minute(now.minute());
      return;
    }

    // get trips if the relevant props differ
    if (
      this.props.from !== nextProps.from ||
      this.props.to !== nextProps.to ||
      this.props.date.format(dateFormat) !== nextProps.date.format(dateFormat)
    ) {
      // new props differ
      this.fetchTrips(nextProps.from, nextProps.to, nextProps.date, true);
    }

    if (
      nextProps.viewMode === View.List ||
      nextProps.viewMode === View.Details
    ) {
      if (
        nextProps.date.format(dateFormat) !==
          this.props.date.format(dateFormat) ||
        nextProps.viewMode !== this.props.viewMode
      ) {
        this.scrollTo(nextProps);
      }
    }
  }

  scrollTo(props) {
    if (this.state.selected === -1) {
      this.scrollToX(props, '.travel-result-current');
    } else {
      this.scrollToX(props, '.travel-result-selected');
    }
  }

  scrollToX(props, className) {
    const list = this.resultsView;
    const current = documentIsDefined && list && list.querySelector(className);

    if (!current) return;

    // focus first - if not it will overshoot the scroll
    if (!props.datePickerOpen && document.activeElement !== current) {
      current.focus();
    }

    // Magic number to account for date label
    list.scrollTop = current.offsetTop ? current.offsetTop - 42 : 0;
  }

  getTrips = (prev = false) => {
    if (this.state.isLoading) {
      return;
    }

    if (prev) {
      const prevTripsLoaded = () =>
        !!this.state.items.find(x =>
          moment()
            .add(-1, 'hours')
            .isAfter(moment(x.dateTime, fullDate))
        );
      const todaysTripsPresented = () =>
        !!this.state.items.find(x =>
          moment().isSame(moment(x.dateTime, fullDate), 'day')
        );
      if (
        !this.props.pastTripsAllowed ||
        prevTripsLoaded() ||
        !todaysTripsPresented()
      ) {
        return; // skip loading previous trips
      }
    }

    this.fetchTrips(
      this.props.from,
      this.props.to,
      !prev ? this.time : moment().add(-1, 'days'),
      false,
      prev
    );
  };

  // Calculates difference between client and server time to display the correct countdown
  parseTime = json => {
    if (json.serverTime) {
      let serverTime = moment(json.serverTime);
      let now = moment();
      let diff = serverTime.diff(now, 'seconds');
      return { serverTime: serverTime, clientTime: now, diffTime: diff };
    }
    return {};
  };

  // 'set' is set to true from TravelResult's onClick function
  selectTrip = (id, items, set) => {
    const _items = items || this.state.items;
    let trip = _items.filter(o => {
      if (o.id === id) {
        return true;
      }
    });
    if (trip.length > 0) {
      trip = trip[0];
    } else {
      return;
    }

    let selected = { selected: id, error: false };
    if (set) {
      this.props.setViewingMode(View.Details);
      this.setState(selected);
    }
    return selected;
  };

  fetchTrips = (originId, destId, date, clear, prev) => {
    if (originId === null || destId === null) {
      return;
    }

    let today = moment(),
      sameDate = !clear,
      newState = { error: false, isLoading: true, isLoadingPastResults: prev };

    let language = this.getLanguage();

    // override data
    if (clear) {
      // reset time to prop
      date = moment(date)
        .hour(0)
        .minute(0);

      // is today?
      if (date.format(dateFormat) === today.format(dateFormat)) {
        date.hour(today.hour()).minute(today.minute());
      }

      this.time = date;
      // reset trip selection
      newState = { ...newState, selected: -1, items: [] };
    }

    this.setState(newState);

    const url = isLocalHost
      ? 'http://localhost:3000/getTrip'
      : '/api/travelplanner/trip';

    if (!originId || !destId || !windowIsDefined) {
      return;
    }

    const fetchTripsAsyncMarker = new Object();
    this.setState({ fetchTripsAsyncMarker: fetchTripsAsyncMarker });

    fetchTrips(
      url,
      originId,
      destId,
      date.format(dateFormat),
      date.format(timeFormat),
      language,
      this.props.from,
      this.props.to,
      this.state.items,
      sameDate
    )
      .then(({ newTrips, json }) => {
        if (this.state.fetchTripsAsyncMarker !== fetchTripsAsyncMarker) {
          return;
        }
        this.setState(
          {
            ...this.getNewTripsState(originId, destId, newTrips),
            ...this.parseTime(json)
          },
          () => {
            if (today > date) {
              this.scrollTo(this.props);
            }
          }
        );
      })
      .catch(e => {
        if (this.state.fetchTripsAsyncMarker !== fetchTripsAsyncMarker) {
          return;
        }
        this.setState({
          error: true,
          isLoading: false,
          isLoadingPastResults: false
        });
        if (e instanceof NoDataError) {
          this.props.handleError(e.message);
        }
        if (
          this.state.items.length === 0 &&
          this.props.viewMode === View.List
        ) {
          this.props.setViewingMode(View.Single);
        }
      });
  };

  getNewTripsState = (originId, destId, trips) => {
    let time = moment(this.time);

    // set the updated time to search from
    this.time = moment(trips[trips.length - 1].dateTime, fullDate).add(
      1,
      'minutes'
    );

    return {
      error: false,
      items: trips,
      isLoading: false,
      isLoadingPastResults: false,
      lastSearch: { date: time, from: originId, to: destId }
    };
  };

  // Calculates which trip to display as next departure
  getNextTrip = () => {
    const { items, diffTime, selected } = this.state;
    const time = Date.now() / 1000 - diffTime / 1000;

    // return selected trip if any
    if (selected !== -1) {
      let trips = items.filter(o => o.id === selected);
      if (trips.length === 1) {
        return trips[0];
      }
    }

    // extract first trip that has a positive time left
    let trip = (() => {
      let result = null;
      items.some(o =>
        time - moment(o.dateTime, fullDate).unix() <= 0
          ? ((result = o), true)
          : false
      );
      return result;
    })();

    // last ditch attempt to get the first valid trip
    if (trip === null) {
      trip = items.filter(o => o.valid)[0];
    }
    return trip;
  };

  getDateLabel = trips => {
    const date = !trips.length
      ? this.props.date
      : moment(trips[0].dateTime, fullDate);

    return computeDisplayDate(date, this.props.lang, this.props.locale);
  };

  goToView = view => () => {
    this.props.setViewingMode(view);
  };

  onResultsScroll = () => {
    this.onListScroll({ target: this.resultsView });

    var lastScrollTop = 0;
    var dom = this.resultsView;
    var st = dom.scrollTop;

    if (st <= lastScrollTop && st <= 41) {
      this.getTrips(true);
    }

    lastScrollTop = st <= 0 ? 0 : st;
  };

  onListScroll = e => {
    if (this.props.viewMode === View.Details) {
      return;
    }

    if (
      !e.target ||
      e.target.className !== 'travel-results' ||
      this.dom === null ||
      this.resultsViewContainer === null
    ) {
      return false;
    }
    // lazy load if on bottom in listview
    if (!this.state.error) {
      if (this.props.viewMode === View.List) {
        if (
          e.target.scrollTop + e.target.offsetHeight >=
          e.target.scrollHeight - 200
        ) {
          this.getTrips();
        }
      }
    }
    return false;
  };

  handleResultsWheel = e => {
    let dom = this.resultsView;
    let normalized = parseMouseWheelEvent(e.nativeEvent);
    if (normalized.spinY < 0 && dom.scrollTop == 0) {
      this.getTrips(true);
    }

    dom.scrollTop = Math.min(
      dom.scrollHeight - dom.offsetHeight,
      Math.max(0, dom.scrollTop + normalized.pixelY)
    );

    return false;
  };

  onScroll = () => {
    // pass along fake event target
    this.onListScroll({ target: this.resultsView });
  };

  // resize handler - resizes sticky labels when window is resized
  onResize = () => {
    if (this.resultsView) {
      this.onScroll({ target: this.resultsView.parentNode });
    }
  };

  onBottomBarInteract = () => {
    const { viewMode } = this.props;
    if (this.state.error) {
      this.getTrips();
    } else {
      this.goToView(viewMode === View.List ? View.Single : View.List)();
    }
  };

  onKeyDownDatepicker = e => {
    if (e.which === 13) {
      this.props.showDatePicker();
    }
  };

  render() {
    const {
      lang,
      locale,
      direction,
      from,
      to,
      errorMessage,
      exception,
      maxCountdown,
      viewMode
    } = this.props;

    const {
      error,
      isLoading,
      isLoadingPastResults,
      items,
      diffTime,
      selected
    } = this.state;

    const isLoadingForReal =
      (isLoading && !isLoadingPastResults) || from === null || to === null;

    const bottomBar = (
      <BottomBar
        disabled={viewMode === View.Single}
        disabledText={lang.loadMore}
        isLoading={isLoadingForReal}
        onClick={this.onBottomBarInteract}
        onEnterPress={this.onBottomBarInteract}
        text={error ? lang.retry : lang.back}
      />
    );

    let trips = [...items];

    // filter out selected trip - if any
    const showTrip = this.getNextTrip();
    if (
      showTrip &&
      (viewMode === View.Single || viewMode === View.Details) &&
      items.length
    ) {
      trips = [showTrip];
    }

    // NOTE: Creates object with date strings as keys and lists of trips as arrays.
    const tripsGroupedByDay = trips.reduce((accum, trip) => {
      const day = accum[trip.date] || [];
      return Object.assign(accum, { [trip.date]: day.concat(trip) });
    }, {});

    return (
      <div
        ref={node => (this.dom = node)}
        className={cn('travel-results-block-container', {
          'view-list': viewMode === View.List,
          'view-single': viewMode === View.Single,
          'view-details': viewMode === View.Details
        })}
      >
        {// hide list if there are no trips or an error
        (!error || (error && trips.length > 0)) && (
          <div
            className="travel-results-view-container"
            ref={i => {
              this.resultsViewContainer = i;
            }}
          >
            {viewMode !== View.Details ? (
              <div
                tabIndex="-1" /* prevent firefox from focusing */
                className={cn('travel-results', {
                  ['empty-list']: trips.length === 0
                })}
                ref={i => {
                  this.resultsView = i;
                }}
                selected={selected}
                onScroll={this.onResultsScroll}
                onWheel={this.handleResultsWheel}
              >
                {Object.entries(tripsGroupedByDay).map(([date, trips]) => (
                  <div className="travel-results-group" key={date}>
                    <div
                      onKeyDown={this.onKeyDownDatepicker}
                      onClick={this.props.showDatePicker}
                      onWheel={this.handleResultsWheel}
                      className="date-label-container"
                    >
                      <div
                        tabIndex="0"
                        className={cn('date-label', {
                          'date-label--loading': isLoadingPastResults
                        })}
                      >
                        <div className="date-label-loading-container">
                          <Spinner theme="light" />
                        </div>
                        <div className="date-label-text-container">
                          <IconCal focusable="false" />{' '}
                          {this.getDateLabel(trips)}
                        </div>
                      </div>
                    </div>
                    <ul role="list">
                      {trips.map(item => (
                        <TravelResult
                          {...item}
                          diffTime={diffTime}
                          maxCountdown={maxCountdown}
                          lang={lang}
                          viewMode={viewMode}
                          current={showTrip ? item.id === showTrip.id : false}
                          selected={item.id === selected}
                          onClickList={this.selectTrip}
                          onClickSingle={this.goToView(View.List)}
                        >
                          {viewMode === View.Single ? bottomBar : ''}
                        </TravelResult>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            ) : null}

            {showTrip && viewMode === View.Details && (
              <TravelResultDetails
                {...showTrip}
                direction={direction}
                lang={lang}
                locale={locale}
              />
            )}
          </div>
        )}

        {(error || exception) && (
          <div className="travel-results server-error" onClick={this.getTrips}>
            {errorMessage}
          </div>
        )}

        {isLoadingForReal || viewMode !== View.Single ? bottomBar : []}
      </div>
    );
  }
}

export default TravelResults;
