import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({ path }) =>
  React.createElement(path, {
    focusable: 'false'
  });

Icon.propTypes = {
  path: PropTypes.string
};

Icon.propTypesMeta = 'exclude';

export default Icon;
