function getParameter(name) {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.has(name)) {
    return decodeURIComponent(urlParams.get(name));
  }
  return undefined;
}

function setParameters(params) {
  const urlParams = new URLSearchParams(window.location.search);
  for (var name in params) {
    const value = params[name];
    if (value) {
      urlParams.set(name, value);
    } else {
      urlParams.delete(name, value);
    }
  }

  const queryString = '?' + urlParams.toString() + window.location.hash;
  window.history.replaceState({}, document.title, queryString);
}

function setParameter(name, value) {
  var params = {};
  params[name] = value;
  setParameters(params);
}

export default {
  getParameter,
  setParameter,
  setParameters
};
