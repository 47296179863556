import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image/image';
import {
  BLOCK_TYPE as FEATURE_TYPE,
  BLOCK_TYPE_VALUES as FEATURE_TYPE_VALUES
} from 'constants/block-type';
import { IMAGE_SIZE } from 'constants/image-type';

const FEATURE_BLOCK_TYPE = {
  PRIMARY: 'block-primary',
  SECONDARY: 'block-secondary',
  LIST: 'block-list'
};

const FEATURE_BLOCK_POSITION = {
  RIGHT: 0,
  LEFT: 1
};

const FeatureBlock = ({
  type,
  blockType,
  title,
  context,
  date,
  text,
  linkUrl,
  linkText,
  imageUrl,
  imageX,
  imageY,
  imageAlt,
  setImage,
  useImageSize,
  isInEditMode,
  isHighlighted,
  position
}) => {
  if (blockType === FEATURE_BLOCK_TYPE.LIST) {
    position = FEATURE_BLOCK_POSITION.RIGHT;
  }
  const featureBlockClassNames = `feature-block ${FEATURE_TYPE_VALUES[type]}`;

  const isLeft = position === FEATURE_BLOCK_POSITION.LEFT;
  const imageContainerClassName = isLeft
    ? 'off-lg-1 off-md-1 col-md-6 col-xs-12 order-tab-1'
    : 'col-md-6 col-xs-12 order-tab-1';
  const textContainerClassName = isLeft
    ? 'col-md-5 col-xs-12'
    : 'off-lg-1 off-md-1 col-md-5 col-xs-12';

  const hasImage = imageUrl ? true : false;

  let imageEl = (
    <div className={imageContainerClassName}>
      {isInEditMode && (
        <div className="image-container">
          <img src={imageUrl} data-epi-property-name="ImageLink" />
        </div>
      )}
      {!isInEditMode && (
        <a href={linkUrl}>
          <Image
            imageSmall={imageUrl}
            imageLarge={imageUrl}
            imageX={imageX}
            imageY={imageY}
            imageAlt={imageAlt}
            setImage={setImage}
            useImageSize={useImageSize}
            epiPropertyName="ImageLink"
          />
        </a>
      )}
    </div>
  );

  if (!hasImage && blockType === FEATURE_BLOCK_TYPE.LIST) {
    // Not really an image element, but by reusing this variable if there is no image
    // we save ourselves some logic.
    imageEl = (
      <div className={imageContainerClassName}>
        <p className="contextual-text" data-epi-property-name="">
          {date}
        </p>
        <a href={linkUrl}>
          <h2 className="feature-block-subtitle">{title}</h2>
        </a>
      </div>
    );
  }

  const textEls = (
    <div className={textContainerClassName}>
      {(isInEditMode ||
        (blockType === FEATURE_BLOCK_TYPE.SECONDARY && context)) && (
        <p className="contextual-text" data-epi-property-name="SecondHeading">
          {context}
        </p>
      )}
      {blockType === FEATURE_BLOCK_TYPE.LIST && date && hasImage && (
        <p className="contextual-text" data-epi-property-name="">
          {date}
        </p>
      )}
      {(blockType == FEATURE_BLOCK_TYPE.SECONDARY ||
        (blockType === FEATURE_BLOCK_TYPE.LIST &&
          hasImage &&
          !isHighlighted)) && (
        <a href={linkUrl}>
          <h2
            className="feature-block-subtitle"
            data-epi-property-name="Heading"
          >
            {title}
          </h2>
        </a>
      )}
      <div
        className={
          blockType === FEATURE_BLOCK_TYPE.PRIMARY
            ? 'ingress'
            : 'feature-text editorial-text'
        }
        dangerouslySetInnerHTML={{ __html: text }}
        data-epi-property-name="TextArea"
      />
      {linkText && (
        <a
          className="feature-block-link cta"
          href={linkUrl}
          data-epi-property-name="Link"
        >
          {linkText}
        </a>
      )}
    </div>
  );

  return (
    <div className={featureBlockClassNames}>
      {(blockType === FEATURE_BLOCK_TYPE.PRIMARY ||
        (blockType === FEATURE_BLOCK_TYPE.LIST && isHighlighted)) && (
        <div className="feature-block-title row centered-horizontal">
          <a className="col-xs-12" href={linkUrl}>
            <h1 className="" data-epi-property-name="Heading">
              {title}
            </h1>
          </a>
        </div>
      )}

      {isLeft && (
        <div className="row">
          {textEls}
          {imageEl}
        </div>
      )}
      {!isLeft && (
        <div className="row">
          {imageEl}
          {textEls}
        </div>
      )}
    </div>
  );
};

FeatureBlock.propTypes = {
  type: PropTypes.oneOf([
    FEATURE_TYPE.WHITE,
    FEATURE_TYPE.DARK_GREY,
    FEATURE_TYPE.LIGHT_GREY,
    FEATURE_TYPE.BROWN
  ]),
  blockType: PropTypes.oneOf([
    FEATURE_BLOCK_TYPE.PRIMARY,
    FEATURE_BLOCK_TYPE.SECONDARY,
    FEATURE_BLOCK_TYPE.LIST
  ]),
  position: PropTypes.oneOf([
    FEATURE_BLOCK_POSITION.LEFT,
    FEATURE_BLOCK_POSITION.RIGHT
  ]),
  title: PropTypes.string,
  context: PropTypes.string, // Currently only used by FEATURE_BLOCK_TYPE.SECONDARY
  date: PropTypes.string, // Currently only used by FEATURE_BLOCK_TYPE.LIST
  text: PropTypes.string,
  linkUrl: PropTypes.string,
  linkText: PropTypes.string,
  setImage: PropTypes.bool,
  useImageSize: PropTypes.oneOf([IMAGE_SIZE.SMALL, IMAGE_SIZE.LARGE]),
  imageUrl: PropTypes.string,
  imageAlt: PropTypes.string,
  imageX: PropTypes.number,
  imageY: PropTypes.number,
  isInEditMode: PropTypes.bool,
  isHighlighted: PropTypes.bool // Currently only used by FEATURE_BLOCK_TYPE.LIST
};

FeatureBlock.propTypesMeta = 'exclude';

FeatureBlock.defaultProps = {
  type: FEATURE_TYPE.LIGHT_GREY,
  position: FEATURE_BLOCK_POSITION.LEFT,
  blockType: FEATURE_BLOCK_TYPE.PRIMARY,
  setImage: false,
  useImageSize: IMAGE_SIZE.SMALL,
  isInEditMode: false,
  isHighlighted: false
};

export default FeatureBlock;
