import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Animate from 'rc-animate';
import Cookie from 'js-cookie';
import IconClose from 'assets/icons/close-icon.svg';
import IconCollapse from 'assets/icons/collapse-icon.svg';

// Need to check if window is defined because window
// does not exist when we server-side render this component.
if (typeof window !== 'undefined') {
  var velocity = require('velocity-animate');
}

const EMERGENCY_TYPE = {
  YELLOW: 0,
  RED: 1,
  GRAY: 2
};

const EMERGENCY_TYPE_VALUES = ['is-yellow', 'is-red', 'is-gray'];

class EmergencyBlock extends Component {
  static propTypes = {
    id: PropTypes.any,
    shortMessage: PropTypes.string,
    longMessage: PropTypes.string,
    removeMessageText: PropTypes.string,
    readLessText: PropTypes.string,
    readMoreText: PropTypes.string,
    emergencyType: PropTypes.oneOf([
      EMERGENCY_TYPE.WARNING,
      EMERGENCY_TYPE.ERROR
    ]).isRequired,
    isInEditMode: PropTypes.bool
  };

  static defaultProps = {
    isInEditMode: false
  };

  static propTypesMeta = 'exclude';

  constructor(props) {
    super(props);

    this.state = {
      isExpanded: false,
      hasAnimated: false,
      isVisible: false
    };

    this.toggleExpand = this.toggleExpand.bind(this);
    this.animationEnter = this.animationEnter.bind(this);
    this.animationLeave = this.animationLeave.bind(this);
    this.removeEmergencyBlock = this.removeEmergencyBlock.bind(this);
  }

  componentDidMount() {
    // Check if there is cookie saying that the user has chosen to hide this notification.
    let visible = false;
    let existingCookie = Cookie.get('emergency-notification');
    existingCookie =
      existingCookie !== undefined ? JSON.parse(existingCookie) : undefined;
    visible =
      existingCookie !== undefined && existingCookie.id === this.props.id
        ? existingCookie.isVisible
        : true;
    this.setState({ isVisible: visible });
  }

  toggleExpand() {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  }

  handleAnimationEnd(node, complete) {
    return {
      stop() {
        velocity(node, 'finish');
        complete();
      }
    };
  }

  animationEnter(node, done) {
    if (typeof window === 'undefined') {
      return;
    }

    let ok = false;
    let self = this;
    var complete = function() {
      self.setState({
        hasAnimated: true
      });
      if (!ok) {
        ok = 1;
        done();
      }
    };

    node.style.display = 'none';

    velocity(node, 'slideDown', {
      duration: 500,
      easing: 'easeOutSine',
      complete
    });
    return this.handleAnimationEnd(node, complete);
  }

  animationLeave(node, done) {
    if (typeof window === 'undefined') {
      return;
    }
    let ok = false;

    var complete = function() {
      if (!ok) {
        ok = 1;
        done();
      }
    };

    node.style.display = 'block';

    this.setState(
      {
        hasAnimated: false
      },
      () => {
        velocity(node, 'slideUp', {
          duration: 500,
          easing: 'easeOutSine',
          delay: 300,
          complete
        });
      }
    );
    return this.handleAnimationEnd(node, complete);
  }

  removeEmergencyBlock() {
    if (this.props.isInEditMode) {
      return;
    }
    Cookie.set('emergency-notification', {
      id: this.props.id,
      isVisible: false
    });
    this.setState({ isVisible: false });
  }

  render() {
    const anim = {
      enter: this.animationEnter,
      leave: this.animationLeave
    };
    const {
      removeMessageText,
      readLessText,
      readMoreText,
      shortMessage,
      longMessage,
      emergencyType
    } = this.props;
    const { isVisible, isExpanded } = this.state;

    if (!isVisible) {
      return <div className="emergency-block no-emergency" />;
    }

    const emergencyClass = this.state.hasAnimated
      ? 'emergency-container is-expanded'
      : 'emergency-container';
    const toggleClass = this.state.isExpanded
      ? 'emergency-toggle-button is-expanded'
      : 'emergency-toggle-button';
    const toggleText = this.state.isExpanded ? readLessText : readMoreText;

    return (
      <div
        className={`emergency-block ${EMERGENCY_TYPE_VALUES[emergencyType]}`}
        role="region"
        aria-labelledby="emergency-short-header"
      >
        <div
          className={`emergency-banner-container ${EMERGENCY_TYPE_VALUES[emergencyType]}`}
        >
          <div className="emergency-button-container">
            <button
              className="emergency-close-button"
              data-epi-property-name=""
              onClick={this.removeEmergencyBlock}
              title={removeMessageText}
            >
              <IconClose className="icon icon-close" />
            </button>
          </div>

          <h5
            id="emergency-short-header"
            data-epi-property-name="ShortMessage"
            className="emergency-heading"
            onClick={this.toggleExpand}
          >
            {shortMessage}
          </h5>
          <div className="emergency-button-container">
            <button
              onClick={this.toggleExpand}
              className={toggleClass}
              aria-expanded={isExpanded}
              aria-controls=""
              aria-label={toggleText}
            >
              {toggleText && (
                <span className="emergency-toggle-button-text">
                  {toggleText}
                </span>
              )}
              <IconCollapse className="icon icon-collapse" />
            </button>
          </div>
        </div>

        <div className={emergencyClass}>
          <Animate component="div" animation={anim}>
            {this.state.isExpanded ? (
              <div
                className={`emergency-content ${EMERGENCY_TYPE_VALUES[emergencyType]}`}
                key={1}
              >
                <div
                  className="editorial-text emergency-block-text"
                  data-epi-property-name="LongMessage"
                  dangerouslySetInnerHTML={{ __html: longMessage }}
                />
              </div>
            ) : null}
          </Animate>
        </div>
      </div>
    );
  }
}

export default EmergencyBlock;
