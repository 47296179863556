/* eslint-disable @creuna/prop-types-csharp/all */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

import { Direction, View } from 'components/travel-planner/constants';
import { Image } from 'components/travel-planner/models';

const TravelPlannerImages = ({
  airportImage,
  trainImage,
  direction,
  viewMode
}) => (
  <div className="travel-planner-images-container">
    <div
      className={cn('offset-images', {
        list: viewMode,
        switch: direction === Direction.FROM
      })}
    >
      {trainImage && (
        <div className="from">
          <img
            src={trainImage.url}
            alt={trainImage.altText}
            className="train"
          />
        </div>
      )}
      {airportImage && (
        <div className="to">
          <img
            src={airportImage.url}
            alt={airportImage.altText}
            className="airport"
          />
        </div>
      )}
    </div>
  </div>
);

TravelPlannerImages.propTypes = {
  airportImage: PropTypes.exact(Image.propTypes),
  trainImage: PropTypes.exact(Image.propTypes),
  direction: PropTypes.oneOf(Object.values(Direction)),
  viewMode: PropTypes.oneOf(Object.values(View))
};

TravelPlannerImages.propTypesMeta = 'exclude';

export default TravelPlannerImages;
