import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const CartTotalAmount = ({
  waitingForApiResponse,
  estimatedTotalPriceText,
  amount,
  discount,
  currencyCode,
  openModal,
  translations,
  showMoneySaved
}) => {
  return (
    <div className={cn('amount-wrapper')}>
      {showMoneySaved && discount > 0 && (
        <div className="discount">
          <span>{translations.youSave}</span>
          <span
            className={cn('price', {
              'is-loading': waitingForApiResponse
            })}
          >
            {discount} {currencyCode}
          </span>
        </div>
      )}
      <div className="amount">
        <span>{translations.amountToPay}</span>
        <span className={cn('price', { 'is-loading': waitingForApiResponse })}>
          {amount} {currencyCode}
        </span>
      </div>
      {estimatedTotalPriceText ? <div>{estimatedTotalPriceText}</div> : null}
      <button className="currency" onClick={openModal}>
        {translations.changeCurrency}
      </button>
    </div>
  );
};

CartTotalAmount.propTypes = {
  waitingForApiResponse: PropTypes.bool,
  estimatedTotalPriceText: PropTypes.string,
  amount: PropTypes.string,
  discount: PropTypes.number,
  currencyCode: PropTypes.string,
  openModal: PropTypes.func,
  translations: PropTypes.shape({
    amountToPay: PropTypes.string.isRequired,
    changeCurrency: PropTypes.string.isRequired,
    youSave: PropTypes.string.isRequired
  }),
  showMoneySaved: PropTypes.bool
};

CartTotalAmount.propTypesMeta = 'exclude';

export default CartTotalAmount;
