import React from 'react';
import PropTypes from 'prop-types';

const translationDefaults = {
  bookingConfirmation: 'Booking confirmation',
  ticketsSentTo: 'The tickets have been sent to',
  from: 'From',
  to: 'To',
  type: 'Type',
  tickets: 'Tickets',
  ticketText: 'og kan lagres som bokmerke ved å klikke her:',
  ticketLinkText: 'Nettside for billett(er)',
  validFrom: 'Valid from',
  validTo: 'Valid to',
  orderNumber: 'Order number',
  anyProblems: 'Any problems we can help with?',
  contactUs:
    "Please contact us on chat on <a href='http://flytoget.no'>flytoget.no</a> or email at <a href='mailto:flytoget@flytoget.no'>flytoget@flytoget.no</a>"
};

const ShoppingCartResponse = ({
  email,
  from,
  to,
  type,
  tickets,
  ticketUrl,
  orderNumber,
  translations = translationDefaults
}) => {
  return (
    <div>
      <div className="shopping-cart-response-container">
        <div className="row shopping-cart-response">
          <div className="shopping-cart-response-inner">
            <div className="response-content">
              <h2>{translations.bookingConfirmation}</h2>
              <p>{translations.ticketsSentTo}</p>
              <p>
                <u>{email}</u>
              </p>
              <p>{translations.ticketText}</p>
              <a target="_blank" rel="noopener noreferrer" href={ticketUrl}>
                {translations.ticketLinkText}
              </a>
              <table className="details-wrapper">
                <tbody>
                  <tr>
                    <td>{translations.from}</td>
                    <td>{from}</td>
                  </tr>
                  <tr>
                    <td>{translations.to}</td>
                    <td>{to}</td>
                  </tr>
                  <tr>
                    <td>{translations.type}</td>
                    <td>{type}</td>
                  </tr>
                  <tr>
                    <td>{translations.tickets}</td>
                    <td>{tickets}</td>
                  </tr>
                  <tr>
                    <td>{translations.orderNumber}</td>
                    <td>{orderNumber}</td>
                  </tr>
                </tbody>
              </table>
              <div className="help-text">
                <p>
                  <b>{translations.anyProblems}</b>
                </p>
                <div
                  dangerouslySetInnerHTML={{ __html: translations.contactUs }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ShoppingCartResponse.propTypes = {
  email: PropTypes.string,
  from: PropTypes.string,
  to: PropTypes.string,
  type: PropTypes.string,
  tickets: PropTypes.string,
  ticketUrl: PropTypes.string,
  orderNumber: PropTypes.string,
  translations: PropTypes.shape({
    anyProblems: PropTypes.string,
    bookingConfirmation: PropTypes.string,
    contactUs: PropTypes.string,
    from: PropTypes.string,
    orderNumber: PropTypes.string,
    tickets: PropTypes.string,
    ticketsSentTo: PropTypes.string,
    ticketLinkText: PropTypes.string,
    ticketText: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.string
  })
};

ShoppingCartResponse.defaultProps = {
  email: '',
  from: '',
  to: '',
  type: '',
  tickets: '',
  validFrom: '',
  validTo: '',
  ticketUrl: '',
  translations: translationDefaults
};

ShoppingCartResponse.propTypesMeta = 'exclude';

export default ShoppingCartResponse;
