import moment from 'moment-timezone';

import { fullDate, timeZone } from 'components/travel-planner/constants';

/**
 * Parses date/time from strings to the 'moment' object.
 * @param {string} date
 * @param {string} time
 * @returns {moment}
 */
function parseMoment(date, time = null) {
  let dateTime = moment(
    time ? date.substring(0, 10) + time.substring(0, 5) : date,
    fullDate,
    timeZone
  );
  return dateTime;
}

export default {
  parseMoment
};
