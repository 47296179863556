'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

var _week = require('./week');

var _week2 = _interopRequireDefault(_week);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var FIXED_HEIGHT_STANDARD_WEEK_COUNT = 6;

class Month extends React.Component {
  static propTypes = {
    day: PropTypes.object.isRequired,
    endDate: PropTypes.object,
    excludeDates: PropTypes.array,
    filterDate: PropTypes.func,
    fixedHeight: PropTypes.bool,
    highlightDates: PropTypes.array,
    includeDates: PropTypes.array,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    onDayClick: PropTypes.func,
    onDayMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    peekNextMonth: PropTypes.bool,
    selected: PropTypes.object,
    selectingDate: PropTypes.object,
    selectsEnd: PropTypes.bool,
    selectsStart: PropTypes.bool,
    showWeekNumbers: PropTypes.bool,
    startDate: PropTypes.object,
    utcOffset: PropTypes.number
  };

  handleDayClick = (day, event) => {
    if (this.props.onDayClick) {
      this.props.onDayClick(day, event);
    }
  };

  handleDayMouseEnter = day => {
    if (this.props.onDayMouseEnter) {
      this.props.onDayMouseEnter(day);
    }
  };

  handleMouseLeave = () => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave();
    }
  };

  isWeekInMonth = startOfWeek => {
    var day = this.props.day;
    var endOfWeek = startOfWeek.clone().add(6, 'days');
    return startOfWeek.isSame(day, 'month') || endOfWeek.isSame(day, 'month');
  };

  renderWeeks = () => {
    var weeks = [];
    var isFixedHeight = this.props.fixedHeight;
    var currentWeekStart = this.props.day
      .clone()
      .startOf('month')
      .startOf('week');
    var i = 0;
    var breakAfterNextPush = false;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      weeks.push(
        _react2.default.createElement(_week2.default, {
          key: i,
          day: currentWeekStart,
          month: this.props.day.month(),
          onDayClick: this.handleDayClick,
          onDayMouseEnter: this.handleDayMouseEnter,
          minDate: this.props.minDate,
          maxDate: this.props.maxDate,
          excludeDates: this.props.excludeDates,
          includeDates: this.props.includeDates,
          highlightDates: this.props.highlightDates,
          selectingDate: this.props.selectingDate,
          filterDate: this.props.filterDate,
          selected: this.props.selected,
          selectsStart: this.props.selectsStart,
          selectsEnd: this.props.selectsEnd,
          showWeekNumber: this.props.showWeekNumbers,
          startDate: this.props.startDate,
          endDate: this.props.endDate,
          utcOffset: this.props.utcOffset
        })
      );

      if (breakAfterNextPush) break;

      i++;
      currentWeekStart = currentWeekStart.clone().add(1, 'weeks');

      // If one of these conditions is true, we will either break on this week
      // or break on the next week
      var isFixedAndFinalWeek =
        isFixedHeight && i >= FIXED_HEIGHT_STANDARD_WEEK_COUNT;
      var isNonFixedAndOutOfMonth =
        !isFixedHeight && !this.isWeekInMonth(currentWeekStart);

      if (isFixedAndFinalWeek || isNonFixedAndOutOfMonth) {
        if (this.props.peekNextMonth) {
          breakAfterNextPush = true;
        } else {
          break;
        }
      }
    }

    return weeks;
  };

  getClassNames = () => {
    var _props = this.props,
      selectingDate = _props.selectingDate,
      selectsStart = _props.selectsStart,
      selectsEnd = _props.selectsEnd;

    return (0, _classnames2.default)('react-datepicker__month', {
      'react-datepicker__month--selecting-range':
        selectingDate && (selectsStart || selectsEnd)
    });
  };

  render() {
    return _react2.default.createElement(
      'div',
      {
        className: this.getClassNames(),
        onMouseLeave: this.handleMouseLeave,
        role: 'listbox'
      },
      this.renderWeeks()
    );
  }
}

export default Month;
