'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

class WeekNumber extends React.Component {
  static propTypes = {
    weekNumber: PropTypes.number.isRequired
  };

  render() {
    return _react2.default.createElement(
      'div',
      {
        className: 'react-datepicker__week-number',
        'aria-label': 'week-' + this.props.weekNumber
      },
      this.props.weekNumber
    );
  }
}

export default WeekNumber;
