/* eslint-disable @creuna/prop-types-csharp/all */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/nb';

import DatePicker from 'components/react-datepicker';
import PrevMonth from 'assets/icons/FT_arrow_change_month_left.svg';
import NextMonth from 'assets/icons/FT_arrow_change_month_right.svg';

class DatePickerWrapper extends React.Component {
  static propTypes = {
    date: PropTypes.object, // Moment object
    datePickerOpen: PropTypes.bool,
    hideDatePicker: PropTypes.func,
    lang: PropTypes.shape({
      nextMonth: PropTypes.string.isRequired,
      noMoreRoutes: PropTypes.string.isRequired,
      prevMonth: PropTypes.string.isRequired
    }),
    locale: PropTypes.string,
    minDate: PropTypes.object,
    maxDate: PropTypes.object,
    onDatePickerUpdate: PropTypes.func
  };

  static propTypesMeta = 'exclude';

  datepicker = null;

  state = {
    datePickerIsShowingLastMonth: false
  };

  componentDidUpdate(prevProps) {
    // is opening datepicker
    if (!prevProps.datePickerOpen && this.props.datePickerOpen) {
      // set focus on the selected date
      setTimeout(() => {
        if (this.datepicker) {
          let selected = this.datepicker.querySelector(
            '.react-datepicker__day--selected'
          );
          if (selected) {
            selected.focus();
          }
        }
      }, 100);
      // is closing datepicker
    } else if (prevProps.datePickerOpen && !this.props.datePickerOpen) {
      // focus on date label
      setTimeout(() => {
        if (this.dom) {
          let selected = this.dom.querySelector('.date-label');
          if (selected) {
            selected.focus();
          }
        }
      }, 90);
    }
  }

  handleMonthChange = (currentDate, datePickerIsShowingLastMonth) => {
    this.setState({ datePickerIsShowingLastMonth }, () =>
      this.props.onDatePickerUpdate({ datePickerIsShowingLastMonth })
    );
  };

  handleChange = date => {
    if (typeof date === 'string') {
      date = moment(date);
    }
    this.setState({ date });
    this.props.hideDatePicker();
    this.props.onDatePickerUpdate({ date });
  };

  handleDayClick = day => {
    if (
      day.format('YYYY-MM-DD') === this.props.date.format('YYYY-MM-DD') &&
      this.props.datePickerOpen
    ) {
      this.props.hideDatePicker();
    }
  };

  onKeyDownDatepicker = e => {
    const code = e.which;
    if (code === 27 || code === 13 || code === 32) {
      this.props.hideDatePicker();
    }
  };

  render() {
    const { date, datePickerOpen, lang, locale, minDate, maxDate } = this.props;

    return (
      <div
        className="row travel-results-datepicker-container"
        style={{ zIndex: datePickerOpen ? 999 : 10 }}
      >
        {datePickerOpen && (
          <div
            className="lightbox-overlay"
            onClick={this.props.hideDatePicker}
          />
        )}
        <div
          className="row travel-results-container travel-results-datepicker"
          onKeyDown={this.onKeyDownDatepicker}
        >
          {datePickerOpen && (
            <DatePicker
              ref={i => {
                let dom = ReactDOM.findDOMNode(i);
                if (dom !== null) {
                  this.datepicker = dom;
                }
              }}
              inline={true}
              forceShowMonthNavigation={true}
              dateFormatCalendar="MMMM"
              onMonthChange={this.handleMonthChange}
              minDate={minDate || moment()}
              maxDate={maxDate || moment().add(90, 'days')}
              locale={locale}
              selected={date}
              onChange={this.handleChange}
              onSelect={this.handleDayClick}
              navigationPrevMonthContent={
                <PrevMonth
                  focusable="false"
                  role="img"
                  aria-label={lang.prevMonth}
                />
              }
              navigationNextMonthContent={
                <NextMonth
                  focusable="false"
                  role="img"
                  aria-label={lang.nextMonth}
                />
              }
            />
          )}
          {datePickerOpen && this.state.datePickerIsShowingLastMonth && (
            <h5>{lang.noMoreRoutes}</h5>
          )}
        </div>
      </div>
    );
  }
}

export default DatePickerWrapper;
