import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { IMAGE_SIZE, IMAGE_QUERY } from 'constants/image-type';
import imageLoader from 'utils/image-loader';

import Icon from 'assets/icons/FT_arrows_carousel_left.svg';

class ImageGalleryBlock extends Component {
  static propTypes = {
    blocks: PropTypes.arrayOf(
      PropTypes.exact({
        altText: PropTypes.string,
        image: PropTypes.string,
        imagePhotographer: PropTypes.string,
        imageX: PropTypes.number,
        imageY: PropTypes.number,
        text: PropTypes.string
      })
    ).isRequired,
    previousAriaLabel: PropTypes.string,
    nextAriaLabel: PropTypes.string,
    photographerLabel: PropTypes.string
  };

  static defaultProps = {
    previousAriaLabel: 'Previous',
    nextAriaLabel: 'Next'
  };

  constructor(props) {
    super(props);
    this.state = {
      intervalId: null,
      activeItem: 0,
      imageSize: IMAGE_SIZE.SMALL
    };
    this.changeImage = this.changeImage.bind(this);
    this.prev = this.prev.bind(this);
    this.next = this.next.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    window.setTimeout(() => {
      imageLoader.load(this.imageContainerEl);
    }, 100);
    window.addEventListener('keydown', this.handleKeyDown);
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
    window.removeEventListener('keydown', this.handleKeyDown);
  }

  handleKeyDown(e) {
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    const key = parseInt(e.keyCode || e.which || 0);

    switch (key) {
      case LEFT_ARROW:
        this.prev();
        break;
      case RIGHT_ARROW:
        this.next();
        break;
    }
  }

  changeImage() {
    let activeItem = this.state.activeItem;
    activeItem++;
    if (activeItem > 2) {
      activeItem = 0;
    }

    this.setState(
      {
        activeItem: activeItem
      },
      () => {
        window.setTimeout(imageLoader.load, 100);
      }
    );
  }

  prev() {
    let activeItem = this.state.activeItem;
    if (activeItem - 1 < 0) {
      return;
    }
    activeItem--;
    this.setState(
      {
        activeItem: activeItem
      },
      () => {
        window.setTimeout(imageLoader.load, 100);
      }
    );
  }

  next() {
    let activeItem = this.state.activeItem;
    if (activeItem + 1 >= this.props.blocks.length) {
      return;
    }
    activeItem++;
    this.setState(
      {
        activeItem: activeItem
      },
      () => {
        window.setTimeout(imageLoader.load, 100);
      }
    );
  }

  render() {
    const {
      blocks,
      previousAriaLabel,
      nextAriaLabel,
      photographerLabel
    } = this.props;
    const { activeItem } = this.state;
    const blocksLength = blocks.length;

    let textEls = [];
    let imageEls = blocks.map((block, i) => {
      textEls.push(
        <div
          key={`text-${i}`}
          className={
            activeItem === i ? 'image-text-item is-active' : 'image-text-item'
          }
        >
          <div>{block.text}</div>
          {block.imagePhotographer && (
            <p>
              {photographerLabel}: {block.imagePhotographer}
            </p>
          )}
        </div>
      );
      let fluidStyling = {
        backgroundPosition: `${block.imageX}% ${block.imageY}%`
        //backgroundImage: `url(${block.image}?${IMAGE_QUERY[IMAGE_SIZE.SMALL]}&${IMAGE_QUERY.transform})`
      };
      let imageSize = `${block.image}?${IMAGE_QUERY[this.state.imageSize]}&${
        IMAGE_QUERY.transform
      }`;
      return (
        <div
          key={`image-${i}`}
          className={
            activeItem === i
              ? 'image-gallery-item is-active'
              : 'image-gallery-item'
          }
          aria-hidden={activeItem === i ? 'false' : 'true'}
        >
          <div className="fluid-image" style={fluidStyling}>
            <img
              src={imageSize}
              data-image-width=""
              data-small={`${block.image}`}
              data-large={`${block.image}?${IMAGE_QUERY[IMAGE_SIZE.LARGE]}&${
                IMAGE_QUERY.transform
              }`}
              alt={block.altText}
            />
          </div>
        </div>
      );
    });

    return (
      <div
        className="image-gallery-block"
        ref={imageContainer => {
          this.imageContainerEl = imageContainer;
        }}
      >
        <div className="image-gallery-container">
          <div className="controls">
            <a
              onClick={this.prev}
              className="btn btn-stripped btn-previous"
              aria-label={previousAriaLabel}
              role="button"
            >
              <div>
                <Icon className="active-image" />
              </div>
              <span className="btn-text">{`${activeItem +
                1}/${blocksLength}`}</span>
            </a>

            <a
              onClick={this.next}
              className="btn btn-stripped btn-next"
              aria-label={nextAriaLabel}
              role="button"
            >
              <span className="btn-text">{`${activeItem +
                1}/${blocksLength}`}</span>
              <div>
                <Icon className="active-image rotated-image" />
              </div>
            </a>
          </div>
          <div className="image-gallery-content">{imageEls}</div>
        </div>
        <div className="image-text-container">{textEls}</div>
      </div>
    );
  }
}

ImageGalleryBlock.propTypesMeta = 'exclude';

export default ImageGalleryBlock;
