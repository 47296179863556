'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _classnames = require('classnames');

var _classnames2 = _interopRequireDefault(_classnames);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

function generateYears(year, noOfYear) {
  var list = [];
  for (var i = 0; i < noOfYear; i++) {
    list.push(year - i);
  }
  return list;
}

class YearDropdownOptions extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    scrollableYearDropdown: PropTypes.bool,
    year: PropTypes.number.isRequired
  };

  state = {
    yearsList: this.props.scrollableYearDropdown
      ? generateYears(this.props.year, 50)
      : generateYears(this.props.year, 5)
  };

  renderOptions() {
    var _this = this;

    var selectedYear = this.props.year;
    var options = this.state.yearsList.map(function(year) {
      return _react2.default.createElement(
        'div',
        {
          className: 'react-datepicker__year-option',
          key: year,
          ref: year,
          onClick: _this.onChange.bind(_this, year)
        },
        selectedYear === year
          ? _react2.default.createElement(
              'span',
              { className: 'react-datepicker__year-option--selected' },
              '\u2713'
            )
          : '',
        year
      );
    });

    options.unshift(
      _react2.default.createElement(
        'div',
        {
          className: 'react-datepicker__year-option',
          ref: 'upcoming',
          key: 'upcoming',
          onClick: this.incrementYears
        },
        _react2.default.createElement('a', {
          className:
            'react-datepicker__navigation react-datepicker__navigation--years react-datepicker__navigation--years-upcoming'
        })
      )
    );
    options.push(
      _react2.default.createElement(
        'div',
        {
          className: 'react-datepicker__year-option',
          ref: 'previous',
          key: 'previous',
          onClick: this.decrementYears
        },
        _react2.default.createElement('a', {
          className:
            'react-datepicker__navigation react-datepicker__navigation--years react-datepicker__navigation--years-previous'
        })
      )
    );
    return options;
  }

  onChange(year) {
    this.props.onChange(year);
  }

  handleClickOutside() {
    this.props.onCancel();
  }

  shiftYears(amount) {
    var years = this.state.yearsList.map(function(year) {
      return year + amount;
    });

    this.setState({
      yearsList: years
    });
  }

  incrementYears() {
    return this.shiftYears(1);
  }

  decrementYears() {
    return this.shiftYears(-1);
  }

  render() {
    var dropdownClass = (0, _classnames2.default)({
      'react-datepicker__year-dropdown': true,
      'react-datepicker__year-dropdown--scrollable': this.props
        .scrollableYearDropdown
    });

    return _react2.default.createElement(
      'div',
      { className: dropdownClass },
      this.renderOptions()
    );
  }
}

export default YearDropdownOptions;
