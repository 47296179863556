'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _moment = require('moment');

var _moment2 = _interopRequireDefault(_moment);

var _find = require('lodash/find');

var _find2 = _interopRequireDefault(_find);

var _year_dropdown = require('./year_dropdown');

var _year_dropdown2 = _interopRequireDefault(_year_dropdown);

var _month_dropdown = require('./month_dropdown');

var _month_dropdown2 = _interopRequireDefault(_month_dropdown);

var _month = require('./month');

var _month2 = _interopRequireDefault(_month);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _date_utils = require('./date_utils');

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

var DROPDOWN_FOCUS_CLASSNAMES = [
  'react-datepicker__year-select',
  'react-datepicker__month-select'
];

var isDropdownSelect = function isDropdownSelect() {
  var element =
    arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  var classNames = (element.className || '').split(/\s+/);
  return !!(0, _find2.default)(DROPDOWN_FOCUS_CLASSNAMES, function(
    testClassname
  ) {
    return classNames.indexOf(testClassname) >= 0;
  });
};

class Calendar extends React.Component {
  static propTypes = {
    dateFormat: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
      .isRequired,
    dropdownMode: PropTypes.oneOf(['scroll', 'select']).isRequired,
    endDate: PropTypes.object,
    excludeDates: PropTypes.array,
    filterDate: PropTypes.func,
    fixedHeight: PropTypes.bool,
    highlightDates: PropTypes.array,
    includeDates: PropTypes.array,
    locale: PropTypes.string,
    maxDate: PropTypes.object,
    minDate: PropTypes.object,
    monthsShown: PropTypes.number,
    onClickOutside: PropTypes.func.isRequired,
    onMonthChange: PropTypes.func,
    forceShowMonthNavigation: PropTypes.bool,
    onDropdownFocus: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    openToDate: PropTypes.object,
    peekNextMonth: PropTypes.bool,
    scrollableYearDropdown: PropTypes.bool,
    selected: PropTypes.object,
    selectsEnd: PropTypes.bool,
    selectsStart: PropTypes.bool,
    showMonthDropdown: PropTypes.bool,
    showWeekNumbers: PropTypes.bool,
    showYearDropdown: PropTypes.bool,
    startDate: PropTypes.object,
    todayButton: PropTypes.string,
    utcOffset: PropTypes.number,
    navigationNextMonthContent: PropTypes.element,
    navigationPrevMonthContent: PropTypes.element
  };

  static defaultProps = {
    onDropdownFocus: function onDropdownFocus() {},
    utcOffset: _moment2.default.utc().utcOffset(),
    monthsShown: 1,
    forceShowMonthNavigation: false
  };

  getDateInView = () => {
    var _props = this.props,
      selected = _props.selected,
      openToDate = _props.openToDate,
      utcOffset = _props.utcOffset;

    var minDate = (0, _date_utils.getEffectiveMinDate)(this.props);
    var maxDate = (0, _date_utils.getEffectiveMaxDate)(this.props);
    var current = _moment2.default.utc().utcOffset(utcOffset);
    if (selected) {
      return selected;
    } else if (
      minDate &&
      maxDate &&
      openToDate &&
      openToDate.isBetween(minDate, maxDate)
    ) {
      return openToDate;
    } else if (minDate && openToDate && openToDate.isAfter(minDate)) {
      return openToDate;
    } else if (minDate && minDate.isAfter(current)) {
      return minDate;
    } else if (maxDate && openToDate && openToDate.isBefore(maxDate)) {
      return openToDate;
    } else if (maxDate && maxDate.isBefore(current)) {
      return maxDate;
    } else if (openToDate) {
      return openToDate;
    } else {
      return current;
    }
  };

  state = {
    date: this.localizeMoment(this.getDateInView()),
    selectingDate: null
  };

  componentDidMount() {
    this.handleMonthChange(this.props.selected);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.selected &&
      !(0, _date_utils.isSameDay)(nextProps.selected, this.props.selected)
    ) {
      let date = this.localizeMoment(nextProps.selected);
      this.setState({
        date: date
      });
    } else if (
      nextProps.openToDate &&
      !(0, _date_utils.isSameDay)(nextProps.openToDate, this.props.openToDate)
    ) {
      let date = this.localizeMoment(nextProps.openToDate);
      this.setState({
        date: date
      });
    }
  }

  handleClickOutside(event) {
    this.props.onClickOutside(event);
  }

  handleDropdownFocus(event) {
    if (isDropdownSelect(event.target)) {
      this.props.onDropdownFocus();
    }
  }

  localizeMoment(date) {
    return date.clone().locale(this.props.locale || _moment2.default.locale());
  }

  increaseMonth = () => {
    var _this = this;

    this.setState(
      {
        date: this.state.date.clone().add(1, 'month')
      },
      () => {
        return _this.handleMonthChange(_this.state.date);
      }
    );
  };

  decreaseMonth = () => {
    var _this2 = this;

    this.setState(
      {
        date: this.state.date.clone().subtract(1, 'month')
      },
      () => {
        return _this2.handleMonthChange(_this2.state.date);
      }
    );
  };

  handleDayClick = (day, event) => {
    this.props.onSelect(day, event);
  };

  handleDayMouseEnter = day => {
    this.setState({ selectingDate: day });
  };

  handleMonthMouseLeave = () => {
    this.setState({ selectingDate: null });
  };

  handleMonthChange = date => {
    if (this.props.onMonthChange) {
      var dateCheck = (0, _date_utils.allDaysDisabledAfter)(
        date,
        'month',
        this.props
      );
      this.props.onMonthChange(date, dateCheck);
    }
  };

  changeYear = year => {
    this.setState({
      date: this.state.date.clone().set('year', year)
    });
  };

  changeMonth = month => {
    var _this3 = this;

    this.setState(
      {
        date: this.state.date.clone().set('month', month)
      },
      () => {
        return _this3.handleMonthChange(_this3.state.date);
      }
    );
  };

  header = function() {
    var date =
      arguments.length > 0 && arguments[0] !== undefined
        ? arguments[0]
        : this.state.date;

    var startOfWeek = date.clone().startOf('week');
    var dayNames = [];
    if (this.props.showWeekNumbers) {
      dayNames.push(
        _react2.default.createElement(
          'div',
          { key: 'W', className: 'react-datepicker__day-name' },
          '#'
        )
      );
    }
    return dayNames.concat(
      [0, 1, 2, 3, 4, 5, 6].map(function(offset) {
        var day = startOfWeek.clone().add(offset, 'days');
        return _react2.default.createElement(
          'div',
          { key: offset, className: 'react-datepicker__day-name' },
          day.localeData().weekdaysMin(day)
        );
      })
    );
  };

  renderPreviousMonthButton = () => {
    var dateCheck = (0, _date_utils.allDaysDisabledBefore)(
      this.state.date,
      'month',
      this.props
    );
    if (!this.props.forceShowMonthNavigation && dateCheck) {
      return;
    }
    var extraClass =
      this.props.forceShowMonthNavigation && dateCheck
        ? ' react-datepicker__navigation--disabled'
        : '';
    return _react2.default.createElement(
      'a',
      {
        className:
          'react-datepicker__navigation react-datepicker__navigation--previous' +
          extraClass,
        onKeyDown: e => {
          var code = e.which;
          // 13 = Return, 32 = Space
          if (code === 13 || code === 32) {
            if (this.props.forceShowMonthNavigation && !dateCheck)
              this.decreaseMonth();
          }
        },
        tabIndex: 0,
        onClick:
          this.props.forceShowMonthNavigation && !dateCheck
            ? this.decreaseMonth
            : () => {}
      },
      this.props.navigationPrevMonthContent
    );
  };

  renderNextMonthButton = () => {
    var dateCheck = (0, _date_utils.allDaysDisabledAfter)(
      this.state.date,
      'month',
      this.props
    );
    if (!this.props.forceShowMonthNavigation && dateCheck) {
      return;
    }
    var extraClass =
      this.props.forceShowMonthNavigation && dateCheck
        ? ' react-datepicker__navigation--disabled'
        : '';
    return _react2.default.createElement(
      'a',
      {
        className:
          'react-datepicker__navigation react-datepicker__navigation--next' +
          extraClass,
        onKeyDown: e => {
          var code = e.which;
          // 13 = Return, 32 = Space
          if (code === 13 || code === 32) {
            if (this.props.forceShowMonthNavigation && !dateCheck)
              this.increaseMonth();
          }
        },
        tabIndex: 0,

        onClick:
          this.props.forceShowMonthNavigation && !dateCheck
            ? this.increaseMonth
            : () => {}
      },
      this.props.navigationNextMonthContent
    );
  };

  renderCurrentMonth = function() {
    var date =
      arguments.length > 0 && arguments[0] !== undefined
        ? arguments[0]
        : this.state.date;

    var classes = ['react-datepicker__current-month'];
    if (this.props.showYearDropdown) {
      classes.push('react-datepicker__current-month--hasYearDropdown');
    }
    if (this.props.showMonthDropdown) {
      classes.push('react-datepicker__current-month--hasMonthDropdown');
    }
    return _react2.default.createElement(
      'div',
      { className: classes.join(' ') },
      this.renderPreviousMonthButton(),
      _react2.default.createElement(
        'div',
        { className: 'react-datepicker__current-month--value' },
        date.format(this.props.dateFormat)
      ),
      this.renderNextMonthButton()
    );
  };

  renderYearDropdown = function() {
    var overrideHide =
      arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

    if (!this.props.showYearDropdown || overrideHide) {
      return;
    }
    return _react2.default.createElement(_year_dropdown2.default, {
      dropdownMode: this.props.dropdownMode,
      onChange: this.changeYear,
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
      year: this.state.date.year(),
      scrollableYearDropdown: this.props.scrollableYearDropdown
    });
  };

  renderMonthDropdown = function() {
    if (!this.props.showMonthDropdown) {
      return;
    }
    return _react2.default.createElement(_month_dropdown2.default, {
      dropdownMode: this.props.dropdownMode,
      locale: this.props.locale,
      onChange: this.changeMonth,
      month: this.state.date.month()
    });
  };

  renderTodayButton = () => {
    var _this4 = this;

    if (!this.props.todayButton) {
      return;
    }
    return _react2.default.createElement(
      'div',
      {
        className: 'react-datepicker__today-button',
        onClick: function onClick(event) {
          return _this4.props.onSelect(
            _moment2.default
              .utc()
              .utcOffset(_this4.props.utcOffset)
              .startOf('date'),
            event
          );
        }
      },
      this.props.todayButton
    );
  };

  renderMonths = () => {
    var monthList = [];
    for (var i = 0; i < this.props.monthsShown; ++i) {
      var monthDate = this.state.date.clone().add(i, 'M');
      var monthKey = 'month-' + i;
      monthList.push(
        _react2.default.createElement(
          'div',
          { key: monthKey, className: 'react-datepicker__month-container' },
          _react2.default.createElement(
            'div',
            { className: 'react-datepicker__header' },
            this.renderCurrentMonth(monthDate),
            _react2.default.createElement(
              'div',
              {
                className:
                  'react-datepicker__header__dropdown react-datepicker__header__dropdown--' +
                  this.props.dropdownMode,
                onFocus: this.handleDropdownFocus
              },
              this.renderMonthDropdown(i !== 0),
              this.renderYearDropdown(i !== 0)
            ),
            _react2.default.createElement(
              'div',
              { className: 'react-datepicker__day-names' },
              this.header(monthDate)
            )
          ),
          _react2.default.createElement(_month2.default, {
            day: monthDate,
            onDayClick: this.handleDayClick,
            onDayMouseEnter: this.handleDayMouseEnter,
            onMouseLeave: this.handleMonthMouseLeave,
            minDate: this.props.minDate,
            maxDate: this.props.maxDate,
            excludeDates: this.props.excludeDates,
            highlightDates: this.props.highlightDates,
            selectingDate: this.state.selectingDate,
            includeDates: this.props.includeDates,
            fixedHeight: this.props.fixedHeight,
            filterDate: this.props.filterDate,
            selected: this.props.selected,
            selectsStart: this.props.selectsStart,
            selectsEnd: this.props.selectsEnd,
            showWeekNumbers: this.props.showWeekNumbers,
            startDate: this.props.startDate,
            endDate: this.props.endDate,
            peekNextMonth: this.props.peekNextMonth,
            utcOffset: this.props.utcOffset
          })
        )
      );
    }
    return monthList;
  };

  render() {
    return _react2.default.createElement(
      'div',
      { className: 'react-datepicker' },
      _react2.default.createElement('div', {
        className: 'react-datepicker__triangle'
      }),
      this.renderMonths(),
      this.renderTodayButton()
    );
  }
}

export default Calendar;
