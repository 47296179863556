import React from 'react';
import PropTypes from 'prop-types';
import IconMenu from 'assets/icons/FT_hamburger_icon.svg';
import IconClose from 'assets/icons/close-icon.svg';
import cn from 'classnames';

const ToggleMenuButton = ({ text, isActive = false, onClick = () => {} }) => {
  return (
    <button
      onClick={onClick}
      className={cn('toggle-menu-button', {
        'toggle-menu-button_active': isActive
      })}
      aria-label={text}
    >
      {text && <span className="toggle-menu-button__text">{text}</span>}
      {isActive ? (
        <IconClose className="toggle-menu-button__close-icon" />
      ) : (
        <IconMenu className="toggle-menu-button__open-icon" />
      )}
    </button>
  );
};

ToggleMenuButton.propTypes = {
  text: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func
};

ToggleMenuButton.propTypesMeta = {
  isActive: 'exclude'
};

export default ToggleMenuButton;
