/* eslint-disable @creuna/prop-types-csharp/all */
import React from 'react';
import PropTypes from 'prop-types';

import HighlightBlock from 'components/highlight-block';
import { BLOCK_TYPE, BLOCK_TYPE_VALUES } from 'constants/block-type';

const HighlightBlocks = ({ type, blocks }) => {
  const highlightBlocksClassName = `highlight-blocks ${BLOCK_TYPE_VALUES[type]}`;

  const blocksEl = blocks.map((block, i) => {
    let blockClassName =
      'highlight-block col-sm-4 col-md-3 col-xs-12 wow fadeInDown';
    blockClassName += i !== 0 ? ' off-md-1 off-lg-1' : '';
    let animateDelay = `0.${i * 2}s`;
    return (
      <HighlightBlock
        key={i}
        title={block.title}
        imageUrl={block.imageUrl}
        imageAlt={block.imageAlt}
        imageX={block.imageX}
        imageY={block.imageY}
        text={block.text}
        blockClassName={blockClassName}
        animateDelay={animateDelay}
        isInEditMode={false}
      />
    );
  });

  return (
    <div className={highlightBlocksClassName}>
      <div className="row" data-epi-property-name="HighlightArea">
        {blocksEl}
      </div>
    </div>
  );
};

HighlightBlocks.defaultProps = {
  type: BLOCK_TYPE.LIGHT_GREY,
  isInEditMode: false
};

HighlightBlocks.propTypes = {
  type: PropTypes.oneOf([
    BLOCK_TYPE.WHITE,
    BLOCK_TYPE.DARK_GREY,
    BLOCK_TYPE.LIGHT_GREY,
    BLOCK_TYPE.BROWN
  ]),
  blocks: PropTypes.array.isRequired
};

HighlightBlocks.propTypesMeta = 'exclude';

export default HighlightBlocks;
