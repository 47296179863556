import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

const themes = {
  light: 'light',
  dark: 'dark'
};

const Spinner = ({ theme = themes.dark, className }) => (
  <div
    className={cn('spinner', className, {
      [`spinner--${theme}`]: theme
    })}
  >
    <div className="spinner__item" />
    <div className="spinner__item" />
    <div className="spinner__item" />
    <div className="spinner__item" />
    <div className="spinner__item" />
    <div className="spinner__item" />
    <div className="spinner__item" />
    <div className="spinner__item" />
  </div>
);

Spinner.propTypes = {
  theme: PropTypes.oneOf(Object.values(themes)),
  className: PropTypes.string
};

Spinner.propTypesMeta = 'exclude';

export default Spinner;
