import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import timeHelper from 'components/travel-planner/timeHelper';
import cn from 'classnames';

import IconCheck from 'assets/icons/FT_Checkmark.svg';
import Expand from 'assets/icons/FT_arrow_small_up.svg';

import {
  fullDate,
  timeFormat,
  View
} from 'components/travel-planner/constants';
import { Lang } from 'components/travel-planner/models';

class TravelResult extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    lang: PropTypes.exact(Lang.propTypes).isRequired,
    children: PropTypes.node,
    diffTime: PropTypes.number.isRequired, // server/client time difference
    dateTime: PropTypes.string.isRequired,
    arrival: PropTypes.string.isRequired,
    viewMode: PropTypes.number.isRequired,
    current: PropTypes.bool,
    selected: PropTypes.bool,
    track: PropTypes.number,
    trackArrival: PropTypes.number,
    maxCountdown: PropTypes.number,
    valid: PropTypes.bool,
    alternative: PropTypes.bool
  };

  static defaultProps = {
    current: false,
    selected: false,
    details: false
  };

  constructor(props) {
    super(props);

    let timeDeparture = timeHelper.parseMoment(this.props.dateTime);
    let timeArrival = timeHelper.parseMoment(
      this.props.dateTime,
      this.props.arrival
    );

    // reference to real time
    let timeState = {
      timeDeparture: timeDeparture,
      timeArrival: timeArrival
    };

    this.state = {
      ...timeState
    };
  }

  // returns calculated time left, taking server/client time difference into account
  getDepartureTime = () => {
    const { timeDeparture } = this.state;

    if (this.props.valid && this.props.maxCountdown > 0) {
      // time until departure
      let secondsLeft = Math.ceil(
        timeDeparture.diff(moment(), 'seconds') - this.props.diffTime
      );
      let minutesLeft = Math.ceil(secondsLeft / 60);

      // output in correct format
      if (secondsLeft > 60 && secondsLeft < this.props.maxCountdown * 60) {
        return minutesLeft + ' ' + this.props.lang.minutes;
      } else if (secondsLeft >= 0 && secondsLeft <= 60) {
        return '< 1 ' + this.props.lang.minutes;
      }
    }
    return timeDeparture.format(timeFormat);
  };

  // returns arrival time
  getArrivalTime = () => {
    return this.state.timeArrival.format(timeFormat);
  };

  onClick = () => {
    return this.props[
      'onClick' + (this.props.viewMode === View.Single ? 'Single' : 'List')
    ](this.props.id, null, true);
  };

  onKeyDown = e => {
    var code = e.which;
    // 13 = Return, 32 = Space
    if (code === 13 || code === 32) {
      e.preventDefault();
      this.onClick();
    }
  };

  render() {
    const {
      lang,
      track,
      trackArrival,
      selected,
      current,
      viewMode,
      valid,
      alternative
    } = this.props;

    const todayDate = moment().format(fullDate);

    return (
      <li
        tabIndex="0"
        role="listitem"
        className={cn('travel-result', {
          ['travel-result-current']: current,
          ['travel-result-selected']: viewMode === View.List && selected,
          ['travel-result-single']: viewMode === View.Single,
          ['travel-result-details']: viewMode === View.Details, // SINGLE????
          ['deviation']: !valid || alternative,
          ['deviation-cancelled']: !valid,
          ['deviation-alternative']: alternative,
          ['travel-result-past']:
            this.state.timeDeparture.format(fullDate) < todayDate
        })}
        onKeyDown={this.onKeyDown}
        onClick={this.onClick}
      >
        <div className="row primary-row">
          <div
            className="col-xs-4 departure"
            title={this.state.timeDeparture.format(timeFormat)}
            date-fulldate={this.state.timeDeparture.format(fullDate)}
          >
            {this.getDepartureTime()}
          </div>
          <div className="col-xs-4 center-col">
            {!valid ? (
              <span>{lang.cancelled}</span>
            ) : (
              <IconCheck focusable="false" role="img" />
            )}
          </div>
          <div className="col-xs-4 arrival">
            {this.getArrivalTime()}
            <Expand />
          </div>
        </div>

        <div className="row secondary-row">
          <div className="col-xs-4 departure">
            <div className="track">
              {track !== 0 ? lang.track + ' ' + track : ''}
            </div>
          </div>
          <div className="col-xs-4 center-col" />
          <div className="col-xs-4 arrival">
            <div className="track">
              {trackArrival !== 0 ? lang.track + ' ' + trackArrival : ''}
            </div>
          </div>
        </div>
        {this.props.children}
      </li>
    );
  }
}

TravelResult.propTypesMeta = 'exclude';

export default TravelResult;
