/* eslint-disable @creuna/prop-types-csharp/all */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import querystring from 'querystring';
import PubSub from 'pubsub-js';
import moment from 'moment';
import 'moment/locale/nb';

import DatePickerWrapper from 'components/date-picker-wrapper/date-picker-wrapper';
import TravelInput from 'components/travel-input/travel-input';
import TravelPlannerImages from 'components/travel-planner-images/travel-planner-images';
import TravelResults from 'components/travel-results/travel-results';

import { splitLocationObject, setStopLocations } from './stop-location';
import travelPlannerStore from 'components/travel-planner/travel-planner-store';

import {
  Direction,
  windowIsDefined,
  documentIsDefined,
  isLocalHost,
  View
} from 'components/travel-planner/constants';
import { Image, Lang } from 'components/travel-planner/models';
class TravelPlanner extends Component {
  static propTypes = {
    locale: PropTypes.string, // locale (used for displaing dates)
    lang: PropTypes.exact(Lang.propTypes).isRequired, // translation texts

    shopLink: PropTypes.string, // link to shop

    date: PropTypes.string, // optional date. defaults to today
    pastTripsAllowed: PropTypes.bool, // allows loading tips in the past
    location: PropTypes.string, // extId
    to: PropTypes.bool, // to OSL or from OSL

    stops: PropTypes.object, // prefetched api call GetStopLocations

    exception: PropTypes.string, // if HAFAS fails
    errorMessage: PropTypes.string, // if HAFAS fails

    maxCountdown: PropTypes.number, // max minutes into future to apply countdown to

    airportImage: PropTypes.exact(Image.propTypes),
    trainImage: PropTypes.exact(Image.propTypes)
  };

  static propTypesMeta = 'exclude';

  constructor(props) {
    super(props);

    this.list = null;
    this.dom = null;

    if (props.locale === 'no-nb') {
      moment.locale('nb');
    } else {
      moment.locale('en');
    }

    this.state = {
      /* main state */
      alert: false, // toggles background on destination input row
      viewMode: View.Single,
      errorMessage: props.errorMessage,
      fetchDataError: false,
      warning: null,
      isActive: false,

      /* date picker state */
      datePickerOpen: false, // toggles datepicker inline view
      date: props.date ? moment(props.date) : moment(), // the current date,
      maxDate: moment().add(90, 'days'),
      datePickerIsShowingLastMonth: false,

      /* input state */
      OSL: props.stops.to,
      stops: [],
      location:
        props.stops.from.find(stop => stop.extId === this.props.location) || {},
      direction: this.props.to === false ? Direction.FROM : Direction.TO, // defaulting to TO
      isLoadingStops: true
    };
    this.setBodyClass();
  }

  componentDidMount() {
    this.fetchWarning();
    this.fetchMaxDate();
    var storedData = travelPlannerStore.getData({
      defaultLocation: this.props.location,
      defaultDirection: this.props.to
    });

    if (this.props.stops) {
      this.setState({
        ...setStopLocations(
          splitLocationObject(this.props.stops),
          storedData.location
        )
      });
    }

    const mode = this.getModeFromHash();
    this.setState(
      {
        viewMode: mode,
        isActive: mode != View.Single,
        direction: storedData.direction ? Direction.TO : Direction.FROM
      },
      () => this.setBodyClass()
    );

    window.addEventListener('hashchange', this.hashChange, false);
    // force rerender as long as we are in single view
    this.interval = window.setInterval(() => {
      if (this.state.viewMode === View.Single) this.setState(this.state);
    }, 10000);

    PubSub.subscribe('buyTicketsButtonAdded', () => {
      this.publishShopLink(this.getShopLink());
    });
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', this.hashChange, false);
    clearInterval(this.interval);
  }

  getLanguage() {
    if (typeof window === 'undefined')
      return this.props.locale.startsWith('en') ? 'en' : 'no';
    var path = window.location.pathname;
    return path === '/en' || path.startsWith('/en/') ? 'en' : 'no';
  }

  fetchWarning() {
    const url = isLocalHost
      ? 'http://localhost:3000/planner-warning'
      : '/api/travelplanner/warning?lang=' + this.getLanguage();

    this.hideFetchDataError();
    fetch(url)
      .then(responce => {
        if (responce.ok) return responce.json();
        throw Error(responce.statusText);
      })
      .then(text => {
        this.setState({ warning: text });
      })
      .catch(() => this.showFetchDataError());
  }

  fetchMaxDate() {
    const url = isLocalHost
      ? 'http://localhost:3000/planner-max-date'
      : '/api/travelplanner/max-date';

    this.hideFetchDataError();
    fetch(url)
      .then(responce => {
        if (responce.ok) return responce.json();
        throw Error(responce.statusText);
      })
      .then(date => this.setState({ maxDate: moment(date) }))
      .catch(() => {
        this.setState({ maxDate: moment() });
        this.showFetchDataError();
      });
  }

  hideFetchDataError() {
    this.setState({ fetchDataError: false });
  }

  showFetchDataError() {
    this.setState({ fetchDataError: true }, () =>
      setTimeout(() => this.setState({ fetchDataError: false }), 10 * 1000)
    );
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.isActive !== nextState.isActive ||
      this.state.location !== nextState.location ||
      this.state.direction != nextState.direction
    ) {
      travelPlannerStore.setData({
        location: nextState.location ? nextState.location.extId : undefined,
        direction: nextState.direction == Direction.TO,
        skipQueryUpdate: !nextState.isActive
      });
    }

    return true;
  }

  hashChange = () => {
    this.setState({ viewMode: this.getModeFromHash(), isActive: true });
    this.setBodyClass();
  };

  setBodyClass = () => {
    const { viewMode } = this.state;
    const key = viewMode === View.Single ? 'single' : 'list';

    if (documentIsDefined) {
      document.body.classList.remove('travel-planner-list-view');
      document.body.classList.remove('travel-planner-single-view');
      document.body.classList.remove('travel-planner-details-view');
      document.body.classList.add('travel-planner-' + key + '-view');
    }
  };

  onSomeClickOrKeyUp = () => {
    if (!this.state.isActive) {
      this.setState({ isActive: true });
    }
  };

  onInputChange = e => {
    let newState = {
      location: e.station,
      datePickerOpen: false,
      direction: e.direction ? Direction.TO : Direction.FROM
    };

    let viewMode =
      this.state.viewMode === View.Details ? View.List : this.state.viewMode;

    this.setViewingMode(viewMode);
    this.setState(newState);
  };

  getFromId = () => {
    const { direction, location, OSL } = this.state;
    if (location === null || OSL === null) {
      return null;
    }
    return direction === Direction.TO ? location.extId : OSL.extId;
  };

  getToId = () => {
    const { direction, location, OSL } = this.state;
    if (location === null || OSL === null) {
      return null;
    }
    return direction === Direction.TO ? OSL.extId : location.extId;
  };

  setViewingMode = mode => {
    if (!windowIsDefined) {
      return;
    }
    window.location.hash =
      mode === 0 ? '' : Object.keys(View)[mode].toLowerCase();
  };

  getModeFromHash = () => {
    if (!windowIsDefined) {
      return View.Single; // default
    }
    switch (window.location.hash.substring(1)) {
      case '':
      case 'single':
        return View.Single;
      case 'list':
        return View.List;
      case 'details':
        return View.Details;
    }
  };

  getShopLink = () => {
    const { location, direction, OSL } = this.state;
    const { shopLink } = this.props;

    if (!shopLink || (typeof shopLink === 'string' && shopLink.length === 0)) {
      return false;
    }

    let query = {
      from: location.extId,
      to: OSL.extId
    };
    if (direction === Direction.FROM) {
      query = {
        from: OSL.extId,
        to: location.extId
      };
    }

    const link = shopLink + '?' + querystring.stringify(query);
    return link;
  };

  onDatePickerUpdate = ({ date, datePickerIsShowingLastMonth }) =>
    this.setState(state => ({
      date: date || state.date,
      datePickerIsShowingLastMonth
    }));

  hideDatePicker = () => this.setState({ datePickerOpen: false });

  showDatePicker = () => {
    this.setState({ datePickerOpen: true });
    this.setViewingMode(View.List);
  };

  publishShopLink = link => {
    // We need this to enable sharing between component with different roots
    if (windowIsDefined) {
      PubSub.publish('buyTicketsUrlUpdate', link);
    }
  };

  render() {
    const {
      locale,
      lang,
      exception,
      maxCountdown,
      airportImage,
      trainImage,
      pastTripsAllowed
    } = this.props;
    const {
      warning,
      fetchDataError,
      stops,
      date,
      maxDate,
      location,
      direction,
      OSL,
      isLoadingStops,
      errorMessage,
      datePickerOpen,
      datePickerIsShowingLastMonth,
      alert,
      viewMode
    } = this.state;

    let from = this.getFromId();
    let to = this.getToId();

    const shopLink = this.getShopLink();
    this.publishShopLink(shopLink);

    return (
      <div
        ref={node => (this.dom = node)}
        onClick={this.outsideClick}
        onKeyDown={this.onKeyDownDatepicker}
        className={cn('travel-planner', {
          'list-view': viewMode === View.List,
          'single-view': viewMode === View.Single,
          'details-view': viewMode === View.Details,
          'date-picker': datePickerOpen
        })}
      >
        <TravelPlannerImages
          airportImage={airportImage}
          trainImage={trainImage}
          direction={direction}
          viewMode={viewMode}
        />
        <div className="row travel-planner-block-container">
          <div
            className={cn('travel-planner-results-container', {
              alert: alert,
              warning: warning
            })}
            onClick={this.onSomeClickOrKeyUp}
            onKeyUp={this.onSomeClickOrKeyUp}
          >
            {warning && <div className="travel-warning">{warning}</div>}
            <TravelInput
              stops={stops}
              towardsAirport={direction === Direction.TO}
              isLoading={isLoadingStops}
              station={location ? location.extId : null}
              airportStation={OSL ? OSL.extId : null}
              onChange={this.onInputChange}
              lang={lang}
            />
            <DatePickerWrapper
              onDatePickerUpdate={this.onDatePickerUpdate}
              minDate={moment().add(pastTripsAllowed ? -1 : 0, 'days')}
              maxDate={maxDate}
              locale={locale}
              date={date}
              lang={lang}
              handleChange={this.handleChange}
              handleDayClick={this.handleDayClick}
              hideDatePicker={this.hideDatePicker}
              viewMode={viewMode}
              datePickerIsShowingLastMonth={datePickerIsShowingLastMonth}
              datePickerOpen={datePickerOpen}
            />
            <div className="row travel-results-container travel-results-list">
              <TravelResults
                ref={i => {
                  this.list = i;
                }}
                setViewingMode={this.setViewingMode}
                showDatePicker={this.showDatePicker}
                pastTripsAllowed={pastTripsAllowed}
                datePickerOpen={datePickerOpen}
                locale={locale}
                viewMode={viewMode}
                direction={direction}
                exception={exception || fetchDataError}
                errorMessage={errorMessage}
                handleError={e => {
                  this.setState({ errorMessage: e });
                }}
                setDate={this.handleChange}
                maxCountdown={maxCountdown}
                date={date}
                from={from}
                to={to}
                lang={lang}
              />
            </div>
          </div>
          {viewMode === View.Single && shopLink !== false && (
            <div className="shop-btn-container" data-hj-ignore-attributes>
              <a href={shopLink} className="btn btn-squared">
                {lang.buyTicket}
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default TravelPlanner;
