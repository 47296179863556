/* eslint-disable @creuna/prop-types-csharp/all */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const Step = ({ children, disabled, onClick, tabIndex }) => (
  <button
    tabIndex={tabIndex}
    className={'step'}
    disabled={disabled}
    onClick={onClick}
  >
    {children}
  </button>
);

Step.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  tabIndex: PropTypes.number
};

const StepperRow = ({
  waitingForApiResponse,
  isSelectable,
  notSelectableText,
  productName,
  productPrice,
  currencyCode,
  quantity,
  actualPrice,
  onUpdateAmount,
  maxTickets,
  sumTickets,
  tabIndex,
  translations,
  discountActive
}) => {
  /* this */
  return (
    <div className="stepper-row">
      <div className="stepper-row-upper">
        <div className="product-name">
          {productName}
          <div className={cn('product-price', { discounted: discountActive })}>
            {translations.perItemPriceIndicator} {productPrice} {currencyCode}
          </div>
        </div>

        <div className="stepper">
          <Step
            tabIndex={tabIndex}
            disabled={!isSelectable || quantity === 0}
            onClick={() => onUpdateAmount(-1)}
          >
            -
          </Step>
          <span className="amount">{!isSelectable ? '-' : quantity}</span>
          <Step
            tabIndex={tabIndex}
            disabled={!isSelectable || sumTickets >= maxTickets}
            onClick={() => onUpdateAmount(1)}
          >
            +
          </Step>
        </div>

        <div
          className={cn('actual-price', {
            'is-loading': waitingForApiResponse
          })}
        >
          {actualPrice} {currencyCode}
        </div>
      </div>
      {!isSelectable ? (
        <div className="product-message">{notSelectableText}</div>
      ) : null}
    </div>
  );
};

StepperRow.propTypes = {
  waitingForApiResponse: PropTypes.bool,
  isSelectable: PropTypes.bool,
  notSelectableText: PropTypes.string,
  productName: PropTypes.string,
  productPrice: PropTypes.string,
  currencyCode: PropTypes.string,
  quantity: PropTypes.number,
  actualPrice: PropTypes.string,
  onUpdateAmount: PropTypes.func,
  maxTickets: PropTypes.number,
  sumTickets: PropTypes.number,
  tabIndex: PropTypes.number,
  translations: PropTypes.object,
  discountActive: PropTypes.bool
};

StepperRow.propTypesMeta = 'exclude';

export default StepperRow;
