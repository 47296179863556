import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const PaymentSpinnerAnimation = ({ className }) => {
  const circleCount = 7;
  const circles = Array.from({ length: circleCount }, (_, index) => index + 1);

  return (
    <div
      className={cn('payment-spinner-animation', className)}
      aria-hidden="true"
    >
      {circles.map(circleNumber => (
        <div
          key={circleNumber}
          className={`payment-spinner-animation__circle payment-spinner-animation__circle${circleNumber}`}
        ></div>
      ))}
    </div>
  );
};

PaymentSpinnerAnimation.propTypes = {
  className: PropTypes.string
};

PaymentSpinnerAnimation.propTypesMeta = 'exclude';

export default PaymentSpinnerAnimation;
