import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from 'components/menu-item';
import cn from 'classnames';

const MenuSection = ({
  items,
  className,
  itemClassName,
  forceUpdateIndex,
  isSecondary = false
}) => {
  return (
    items && (
      <ul className={cn('menu-section', className)}>
        {items.map((item, i) => (
          <li className="menu-section__item" key={i}>
            <MenuItem
              {...item}
              key={i}
              className={itemClassName}
              forceUpdateIndex={forceUpdateIndex}
              isSecondary={isSecondary}
            />
          </li>
        ))}
      </ul>
    )
  );
};

MenuSection.propTypes = {
  items: PropTypes.arrayOf(PropTypes.exact(MenuItem.propTypes)),
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  forceUpdateIndex: PropTypes.number,
  isSecondary: PropTypes.bool
};

MenuSection.propTypesMeta = {
  className: 'exclude',
  itemClassName: 'exclude',
  forceUpdateIndex: 'exclude',
  isSecondary: 'exclude'
};

export default MenuSection;
