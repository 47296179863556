import React from 'react';
import PropTypes from 'prop-types';

import cn from 'classnames';

const ErrorBlock = ({ type, title, message }) => (
  <div className="error-block">
    <div className={cn('header', 'type-' + type)}>
      <div className="icon" />
      <div className="title">{title}</div>
    </div>
    <div className="description">{message}</div>
  </div>
);

ErrorBlock.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string
};

ErrorBlock.propTypesMeta = 'exclude';

export default ErrorBlock;
