import { debounce, isRunningOnClient } from 'utils/misc';

function loadAppropriateImage(imageNode) {
  if (!imageNode) {
    return;
  }

  const preWidth = imageNode.getAttribute('data-image-width');
  const parentNode = imageNode.parentNode;
  const parentWidth = parentNode.offsetWidth;

  // Only set new image if we need a bigger image.
  if (
    preWidth.length === 0 ||
    (preWidth.length > 0 && parseInt(preWidth, 10) < parentWidth)
  ) {
    const actualWidth = parseInt(
      parentWidth * (window.devicePixelRatio || 1),
      10
    );
    const actualWidthRounded = Math.ceil(actualWidth / 100) * 100;
    const imageUrl = `${imageNode.getAttribute(
      'data-small'
    )}?width=${actualWidthRounded}&transform=DownFit`;

    imageNode.setAttribute('data-image-width', parentWidth);
    imageNode.setAttribute('src', imageUrl);
    if (parentNode.className.indexOf('fluid-image') !== -1) {
      parentNode.style.backgroundImage = `url(${imageUrl})`;
    }
  }
}

function load(container = document) {
  Array.from(
    container.querySelectorAll('[data-image-width][data-small]')
  ).forEach(imageNode => loadAppropriateImage(imageNode));
}

function activate() {
  if (isRunningOnClient) {
    setTimeout(load, 100);
    window.addEventListener('resize', debounce(load.bind(null, document), 250));
  }
}

export default {
  activate,
  loadAppropriateImage,
  load
};
