/*
	List block is basically identical with feature (secondary) block. We want to reuse
	the component, but since the component names don't indicate a relation between the
	two (FeatureBlock component and ListBlock component) we created this file. Hopefully,
	this will make it easier for people new to the project to navigate.
*/

import FeatureBlock from 'components/feature-block';
FeatureBlock.propTypesMeta = 'exclude';
export default FeatureBlock;
