/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuSection from 'components/menu-section';
import ToggleMenuButton from 'components/toggle-menu-button';
import QuickSearch from 'components/quick-search';
import MenuLink from 'components/menu-link';
import BuyTicketsLink from 'components/buy-tickets-link';
import LanguageSelector from 'components/language-selector';
import Logo from 'components/logo';
import useEscape from '../../hooks/use-escape';
import MenuItem from 'components/menu-item';
import MenuItemActive from './menu-item-active';

const PageHeader = ({
  logo,
  toggleMenuButton,
  languageSelector,
  quickSearch,
  frontLink,
  departuresLink,
  buyTicketsLink,
  mainMenu,
  secondaryMenu
}) => {
  const [forceUpdateIndex, setForceUpdateIndex] = useState(0);

  function forceUpdate() {
    setForceUpdateIndex(forceUpdateIndex => forceUpdateIndex + 1);
  }
  const [panelIsShown, setPanelIsShown] = React.useState(false);

  const getSectionLinks = menuSection => {
    let menuLinks = new Array();

    for (let i = 0; i < menuSection.items.length; i++) {
      menuLinks.push(menuSection.items[i]);
      if (menuSection.items[i].childItems != undefined) {
        for (let r = 0; r < menuSection.items[i].childItems.length; r++) {
          menuLinks.push(menuSection.items[i].childItems[r]);
        }
      }
    }

    return menuLinks;
  };

  let menuLinks = new Array(frontLink, departuresLink, buyTicketsLink);

  menuLinks = menuLinks
    .concat(getSectionLinks(mainMenu))
    .concat(getSectionLinks(secondaryMenu));

  menuLinks = menuLinks.filter(x => !!x);

  const togglePanel = () => {
    setPanelIsShown(!panelIsShown);
  };

  useEscape(() => {
    setPanelIsShown(false);
  });

  return (
    <header className="page-header">
      <MenuItemActive
        onActiveItemChanged={() => forceUpdate()}
        links={menuLinks}
      />
      <Logo
        {...logo}
        className="page-header__logo"
        imageClass="responsive-image_size_header-logo"
      />
      <div>
        <ToggleMenuButton
          {...toggleMenuButton}
          onClick={togglePanel}
          isActive={panelIsShown}
        />

        {panelIsShown && (
          <nav
            className="page-header__navigation-panel"
            aria-expanded={panelIsShown}
          >
            <LanguageSelector {...languageSelector} />
            <QuickSearch {...quickSearch} />
            <div className="page-header__menu">
              {frontLink && <MenuItem {...frontLink} />}
              {buyTicketsLink && <BuyTicketsLink {...buyTicketsLink} />}
              {departuresLink && <MenuItem {...departuresLink} />}
              {mainMenu && (
                <MenuSection
                  {...mainMenu}
                  forceUpdateIndex={forceUpdateIndex}
                />
              )}
              {secondaryMenu && (
                <MenuSection
                  {...secondaryMenu}
                  itemClassName="menu-item_secondary"
                  forceUpdateIndex={forceUpdateIndex}
                  isSecondary={true}
                />
              )}
            </div>
          </nav>
        )}
      </div>
    </header>
  );
};

PageHeader.propTypes = {
  logo: PropTypes.exact(Logo.propTypes),
  toggleMenuButton: PropTypes.exact(ToggleMenuButton.propTypes),
  languageSelector: PropTypes.exact(LanguageSelector.propTypes),
  quickSearch: PropTypes.exact(QuickSearch.propTypes),
  frontLink: PropTypes.exact(MenuLink.propTypes),
  departuresLink: PropTypes.exact(MenuLink.propTypes),
  buyTicketsLink: PropTypes.exact(MenuLink.propTypes),
  mainMenu: PropTypes.exact(MenuSection.propTypes),
  secondaryMenu: PropTypes.exact(MenuSection.propTypes)
};

export default PageHeader;
