import React from 'react';
import MenuLink from 'components/menu-link';
import PropTypes from 'prop-types';

const LanguageSelector = ({ languages }) => {
  return (
    <ul className="language-selector">
      {languages &&
        languages.map((language, i) => (
          <li className="language-selector__item" key={i}>
            <MenuLink
              {...language}
              className="menu-link_in-language-selector"
            />
          </li>
        ))}
    </ul>
  );
};

LanguageSelector.propTypes = {
  languages: PropTypes.arrayOf(PropTypes.exact(MenuLink.propTypes))
};

export default LanguageSelector;
