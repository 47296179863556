import React from 'react';
import PropTypes from 'prop-types';

import Image from 'components/image';

const GriddedBlock = ({ title, blocks }) => {
  const blocksEl = blocks.map((block, i) => {
    let animateDelay = `0.${i * 2}s`;
    return (
      <div key={i} className="col-sm-4 col-xs-12">
        <div
          className="block-in-grid wow fadeInDown"
          data-wow-delay={animateDelay}
        >
          <a href={block.linkUrl}>
            {block.imageSmall && (
              <Image
                epiPropertyName=""
                imageSmall={block.imageSmall}
                imageX={block.imageX}
                imageY={block.imageY}
                imageAlt={block.altText}
              />
            )}
            <div className="block-in-grid-content">
              <p className="contextual-text inverted">{block.context}</p>
              <h4 className="inverted">{block.title}</h4>
            </div>
          </a>
        </div>
      </div>
    );
  });

  return (
    <div className="gridded-block">
      {title && (
        <div className="row">
          <div className="col-xs-12 text-centered">
            <h2
              className="block-title inverted"
              data-epi-property-name="Heading"
            >
              {title}
            </h2>
          </div>
        </div>
      )}
      <div className="row blocks-container" data-epi-property-name="Content">
        {blocksEl}
      </div>
    </div>
  );
};

GriddedBlock.defaultProps = {
  type: 'type-grey'
};

GriddedBlock.propTypes = {
  title: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.exact({
      altText: PropTypes.string,
      context: PropTypes.string,
      imageSmall: PropTypes.string,
      imageX: PropTypes.number,
      imageY: PropTypes.number,
      linkUrl: PropTypes.string,
      title: PropTypes.string
    })
  ).isRequired
};

GriddedBlock.propTypesMeta = 'exclude';

export default GriddedBlock;
