import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { generateUniqueId } from 'utils/misc';

// Need to check if window is defined because window
// does not exist when we server-side render this component.
if (typeof window !== 'undefined') {
  var velocity = require('velocity-animate');
}

class ServiceDisruptionsList extends Component {
  static propTypes = {
    disruptions: PropTypes.string,
    disruptionHeaderTitle: PropTypes.string,
    disruptionHeaderFromTime: PropTypes.string,
    disruptionHeaderToTime: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      isExpandedId: -1
    };
    this.expandListItem = this.expandListItem.bind(this);
    this.animationEnter = this.animationEnter.bind(this);
    this.animationLeave = this.animationLeave.bind(this);
    this.uniqueId = generateUniqueId();
  }

  expandListItem(id) {
    if (id === this.state.isExpandedId) {
      this.setState({ isExpandedId: -1 });
    } else {
      this.setState({ isExpandedId: id });
    }
  }

  handleAnimationEnd(node, complete) {
    return {
      stop() {
        velocity(node, 'finish');
        complete();
      }
    };
  }

  animationEnter(node, done) {
    if (typeof window === 'undefined') {
      return;
    }

    let ok = false;
    let self = this;
    var complete = function() {
      self.setState({
        hasAnimated: true
      });
      if (!ok) {
        ok = 1;
        done();
      }
    };

    node.style.display = 'none';

    velocity(node, 'slideDown', {
      duration: 500,
      easing: 'easeOutSine',
      complete
    });
    return this.handleAnimationEnd(node, complete);
  }

  animationLeave(node, done) {
    if (typeof window === 'undefined') {
      return;
    }
    let ok = false;

    var complete = function() {
      if (!ok) {
        ok = 1;
        done();
      }
    };

    node.style.display = 'block';

    this.setState(
      {
        hasAnimated: false
      },
      () => {
        velocity(node, 'slideUp', {
          duration: 500,
          easing: 'easeOutSine',
          delay: 300,
          complete
        });
      }
    );
    return this.handleAnimationEnd(node, complete);
  }

  render() {
    const {
      disruptionHeaderTitle,
      disruptionHeaderFromTime,
      disruptionHeaderToTime
    } = this.props;
    let { disruptions } = this.props;

    let disruptionsEls = [];

    disruptions = JSON.parse(disruptions);
    let noDeviationsText = disruptions.noDeviationsText;
    disruptions = disruptions.disruptions;

    if (disruptions) {
      disruptionsEls = disruptions.map(disruption => {
        return (
          <li
            className="ft-list-item is-clickable"
            role="listitem"
            key={disruption.id}
          >
            <div
              className={
                this.state.isExpandedId === disruption.id
                  ? 'ft-list-item-simple is-expanded'
                  : 'ft-list-item-simple'
              }
            >
              <div className="row">
                <div className="col-sm-7 col-xs-12">
                  <div className="service-disruptions-title">
                    {disruption.header}
                  </div>
                </div>
                <div className="service-disruptions-time col-sm-5 col-xs-12">
                  <div className="service-disruptions-fromtime">
                    <time dateTime={disruption.affectedStartTimeUTC}>
                      <span>{disruption.affectedStartTime}</span>
                    </time>
                  </div>
                  <div className="service-disruptions-totime">
                    <time dateTime={disruption.affectedEndTimeUTC}>
                      <span>{disruption.affectedEndTime}</span>
                    </time>
                  </div>
                </div>
              </div>
            </div>
          </li>
        );
      });
    }

    disruptionsEls.unshift(
      <li
        className="ft-list-item service-disruptions-item-header"
        role="listitem"
        key="-1"
      >
        <div className="ft-list-item-simple">
          <div className="row">
            <div
              className="col-sm-7 col-xs-12"
              data-epi-property-name="DisruptionHeaderTitle"
            >
              <div className="service-disruptions-title">
                {disruptionHeaderTitle}
              </div>
            </div>
            <div className="service-disruptions-time col-sm-5 col-xs-12">
              <div
                className="service-disruptions-fromtime"
                data-epi-property-name="DisruptionHeaderFromDateText"
              >
                {disruptionHeaderFromTime}
              </div>
              <div
                className="service-disruptions-totime"
                data-epi-property-name="DisruptionHeaderToDateText"
              >
                {disruptionHeaderToTime}
              </div>
            </div>
          </div>
        </div>
      </li>
    );

    return (
      <div>
        <ul role="list" className="ft-list service-disruptions-list">
          {disruptionsEls}
        </ul>
        {disruptionsEls.length <= 1 && (
          <div className="empty-list-text">{noDeviationsText}</div>
        )}
      </div>
    );
  }
}

ServiceDisruptionsList.propTypesMeta = 'exclude';

export default ServiceDisruptionsList;
