import React from 'react';
import PropTypes from 'prop-types';

const PromoBlock = ({
  imageUrl,
  promoTitle,
  imageAriaLabel,
  gradientAriaLabel
}) => {
  let promoImageStyle = { backgroundImage: `url("${imageUrl}")` };

  return (
    <div className="promo-block">
      <div
        className="promo-image"
        style={promoImageStyle}
        role="img"
        aria-label={imageAriaLabel}
      />
      <div
        className="promo-gradient"
        role="img"
        aria-label={gradientAriaLabel}
      />
      <div className="promo-text row">
        <div className="col-xs-10">
          <h1>{promoTitle}</h1>
        </div>
      </div>
    </div>
  );
};

PromoBlock.defaultProps = {
  gradientAriaLabel: 'Farge gradient over hovedbilde',
  triangleAriaLabel: 'Duse trekanter som animeres over hovedbilde'
};

PromoBlock.propTypes = {
  gradientAriaLabel: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  imageAriaLabel: PropTypes.string.isRequired,
  promoTitle: PropTypes.string.isRequired
};

PromoBlock.propTypesMeta = 'exclude';

export default PromoBlock;
