'use strict';

import PropTypes from 'prop-types';
import React from 'react';

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}

class MonthDropdownOptions extends React.Component {
  static propTypes = {
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    month: PropTypes.number.isRequired,
    monthNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  };

  renderOptions() {
    var _this = this;

    var selectedMonth = this.props.month;
    var options = this.props.monthNames.map(function(month, i) {
      return _react2.default.createElement(
        'div',
        {
          className: 'react-datepicker__month-option',
          key: month,
          ref: month,
          onClick: _this.onChange.bind(_this, i)
        },
        selectedMonth === i
          ? _react2.default.createElement(
              'span',
              { className: 'react-datepicker__month-option--selected' },
              '\u2713'
            )
          : '',
        month
      );
    });

    return options;
  }

  onChange(month) {
    this.props.onChange(month);
  }

  handleClickOutside() {
    this.props.onCancel();
  }

  render() {
    return _react2.default.createElement(
      'div',
      { className: 'react-datepicker__month-dropdown' },
      this.renderOptions()
    );
  }
}

export default MonthDropdownOptions;
