/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PubSub from 'pubsub-js';
import MenuLink from 'components/menu-link';

// Hacky loading because stickyfilljs directly references 'window', which crashes server side rendering.
import { isRunningOnClient } from 'utils/misc';

import { windowIsDefined } from 'components/travel-planner/constants';

const stickyPolyfill = isRunningOnClient ? require('stickyfilljs') : {};

class BuyTicketsLink extends Component {
  static propTypes = Object.assign(MenuLink.propTypes);

  static propTypesMeta = 'exclude';

  state = {
    url: this.props.url
  };

  componentDidMount() {
    this.loadStateFromLocalStorage();
    // We need this to get the updated url build by components with different roots
    this.token = PubSub.subscribe('buyTicketsUrlUpdate', (msg, data) => {
      this.setState({
        url: data
      });

      this.saveStateToLocalStorage();
    });
    stickyPolyfill.addOne(this.element);
    PubSub.publish('buyTicketsButtonAdded');
  }

  componentWillUnmount() {
    PubSub.unsubscribe(this.token);
  }

  saveStateToLocalStorage() {
    windowIsDefined && localStorage.setItem('stickyButtonUrl', this.state.url);
  }

  loadStateFromLocalStorage() {
    const url = windowIsDefined && localStorage.getItem('stickyButtonUrl');
    if (url && url.startsWith(this.props.url)) {
      this.setState({
        url: url
      });
    }
  }

  render() {
    return (
      <div className="buy-tickets-link-wrapper">
        <div ref={el => (this.element = el)}>
          <MenuLink
            url={this.state.url}
            openInNewTab={this.props.openInNewTab}
            text={this.props.text}
            isActive={this.props.isActive}
            className={this.props.className}
          />
        </div>
      </div>
    );
  }
}

export default BuyTicketsLink;
